import React, {Fragment, useState, useRef } from 'react';
import { useCallback } from 'react';
import {
    Link, SimpleForm, required, Edit, Create, Toolbar, SaveButton,
    TextInput, NumberInput, useNotify, useRedirect,
    ArrayInput, SimpleFormIterator, Button, useSimpleFormIteratorItem,
    FormDataConsumer, ReferenceInput, SelectInput, BooleanInput
} from 'react-admin';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/AddBoxRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";

const EditWindowingSequenceBreadCrumb = ({ record }) => {
  return  <div className="breadcrumbs"><Link to={"/windowSequence"}>Window Sequences</Link> &nbsp;&gt;{record && record.id > 0 ? ` Edit Windowing Sequence: ${record.windowSequenceName} (${record.id})` : ' Create'}</div>;
};

export const durationUnits = [
    {id : '', value: '', name: ''},
    {id : 'Days', value: 'Days', name: 'Days'},
    {id : 'Weeks', value: 'Weeks', name: 'Weeks'},
    {id : 'Months', value: 'Months', name: 'Months'},
    {id : 'Years', value: 'Years', name: 'Years'},
];

export const usePricingTierFromOptions = [
    {id : 'Super_Premium_EHVL_Start_Date', value: 'Super_Premium_EHVL_Start_Date', name: 'Super Premium EHVL Start Date'},
    {id : 'Premium_EHVL_Start_Date', value: 'Premium_EHVL_Start_Date', name: 'Premium EHVL Start Date'},
    {id : 'New_Release_EHVL_Start_Date', value: 'New_Release_EHVL_Start_Date', name: 'New Release EHVL Start Date'},
    {id : 'New_to_Catalog_EHVL_Start_Date', value: 'New_to_Catalog_EHVL_Start_Date', name: 'New to Catalog EHVL Start Date'},
    {id : 'Catalog_EHVL_Start_Date', value: 'Catalog_EHVL_Start_Date', name: 'Catalog EHVL Start Date'},
    {id : 'TVOD_Super_Premium_Start_Date', value: 'TVOD_Super_Premium_Start_Date', name: 'TVOD Super Premium Start Date'},
    {id : 'TVOD_Premium_Start_Date', value: 'TVOD_Premium_Start_Date', name: 'TVOD Premium Start Date'},
    {id : 'TVOD_Current_Start_Date', value: 'TVOD_Current_Start_Date', name: 'TVOD Current Start Date'},
    {id : 'TVOD_Library_Start_Date', value: 'TVOD_Library_Start_Date', name: 'TVOD Library Start Date'}
];

export const windowStartDates =  [

    {id : '0', value: 'Super_Premium_EHVL_Start_Date', name: 'Super Premium EHVL Start Date'},
    {id : '1', value: 'Premium_EHVL_Start_Date', name: 'Premium EHVL Start Date'},
    {id : '2', value: 'New_Release_EHVL_Start_Date', name: 'New Release EHVL Start Date'},
    {id : '3', value: 'New_to_Catalog_EHVL_Start_Date', name: 'New to Catalog EHVL Start Date'},
    {id : '4', value: 'Catalog_EHVL_Start_Date', name: 'Catalog EHVL Start Date'},
    {id : '5', value: 'TVOD_Super_Premium_Start_Date', name: 'TVOD Super Premium Start Date'},
    {id : '6', value: 'TVOD_Premium_Start_Date', name: 'TVOD Premium Start Date'},
    {id : '7', value: 'TVOD_Current_Start_Date', name: 'TVOD Current Start Date'},
    {id : '8', value: 'TVOD_Library_Start_Date', name: 'TVOD Library Start Date'}

];

const getWindowArrayIndex = (getSource) => {
    const regex = /windows\[(\d+)]\./;
    const match = getSource.match(regex);
    return parseInt(match[1]);
}

class WindowingSequenceForm extends React.Component {
  render() {
    const validateSpecialCharacters = (name) => {
        const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/
        if (specialChars.test(name)) {
            return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
        }
        return undefined;
    }

    return (
        <Fragment>
          <Box display="flex" style={{ width: '98%', paddingLeft: '1%' }}>
            <EditWindowingSequenceBreadCrumb {...this.props} />
          </Box>
          <Box display="flex" style={{ width: '98%', paddingLeft: '1%', float: 'left' }} id="WindowsArray">
            <Box display="flex" justifyContent="center" style={{ width: '28%', paddingLeft: '1%' }}>
              <TextInput options={{ fullWidth: true }} label="Windowing Sequence Name" source="windowSequenceName" validate={[required("Windowing Sequence Name cannot be blank"), validateSpecialCharacters]}  />
            </Box>
            <Box display="flex" justifyContent="center" style={{ width: '28%', paddingLeft: '1%' }}>
                <SelectInput source="status" choices={[
                    { id: 'ACTIVE', name: 'ACTIVE' },
                    { id: 'INACTIVE', name: 'INACTIVE' }
                ]} validate={required("Status is required")} />
            </Box>
          </Box>
        </Fragment>
    )
  }
}

const EditWindowingSequenceTitle = ({ record }) => {
 return <span>{record && record.id > 0 ? `Edit Window Sequence ${record.windowSequenceName} (${record.id})` : 'Create Windowing Sequence'}</span>;
};

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton disabled={props.disabled} />
    </Toolbar>
);

export const WindowingSequenceEdit = props => {

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const disabledUsePricingTierFromRef = useRef({});
  const notify = useNotify();
  const redirect = useRedirect(); 
  const { trigger } = useForm();



    const requiredIfNonPreviousWindowPlusOne = (scopedFormData, formData, index) => {
    return (value, allValues) => {
        const record = allValues.windows[index];
        if(record === undefined) {
            return undefined
        } else if(record.previousWindowPlusOneStart === undefined) {
            return undefined
        } else if((record.previousWindowPlusOneStart === false ) && (value === undefined || value === null || value === '')) {
            return "Required"
        } else {
            return undefined
        }
    };
  };

    const nextWindowIfNextWindowSelectedEdit = (scopedFormData, formData, index) => {
    return (value, allValues) => {
        if(value === null || value === undefined)
          return undefined
        if(allValues === null || allValues.windows === null || allValues.windows === undefined) 
          return undefined

        const message = "Please create a subsequent window in the Window Sequence before selecting this value.";
        const validWindows = allValues.windows.filter(win => win !== null && win !== undefined);

        for (let i = 0; i < validWindows.length; i++) {
          const window = validWindows[i];
          if (window.usePricingTierFrom === 'NextWindow' && i === index) {
            const nextWindow = validWindows[window.windowOrder + 1];
            if (!nextWindow) {
              setIsButtonDisabled(false);
              return message; 
            }
            if (nextWindow && (nextWindow.usePricingTierFrom === undefined && nextWindow.windowStartDate === undefined)) {
              setIsButtonDisabled(true);
              return message; 
            }
          }
        }
  
        setIsButtonDisabled(false);
        return undefined;
    };
  }

  const requiredIfPreviousWindowPlusOneOnAndNoWindowStartDateSelected = (scopedFormData, formData, index) => {
    return (value, allValues) => {
      if(allValues === undefined)
        return undefined;
      const message = "Required";
      const record = allValues.windows[index];
      if(record === undefined)
          return record
        if(scopedFormData === undefined){
          disabledUsePricingTierFromRef.current[index] = false;
          return undefined;
        }
        if (!record.previousWindowPlusOneStart) {
          disabledUsePricingTierFromRef.current[index] = true;
          record.usePricingTierFrom = undefined;
          return undefined;
        }
        const isWindowStartDateValid = record.windowStartDate !== undefined && record.windowStartDate !== null;

        if (!isWindowStartDateValid && (!value || value === '')) {
          disabledUsePricingTierFromRef.current[index] = false;
          return message;
        }
        if (isWindowStartDateValid) {
          record.usePricingTierFrom = undefined;
          disabledUsePricingTierFromRef.current[index] = true;
        } else {
          disabledUsePricingTierFromRef.current[index] = false;
        }
        return undefined;
   };
  };

    const sameWindowStartDateOnDiffWindow = (scopedFormData, formData, index) => {

        return (value, allValues) => {
            const record = allValues.windows[index];
            if(record === undefined) {
                setIsButtonDisabled(false)
                return undefined
            }
            if(value === null || value === undefined) {
                setIsButtonDisabled(false)
                return undefined
            }
            const message =
                "Multiple Windows in a Window Sequence cannot have the same Window Start Date";
            const isWinStartDateAlreadySelectedDiffWin =
                allValues.windows
                    .filter((win) => win !== null && win !== undefined)
                    .filter((win) => win.windowStartDate !== null && win.windowStartDate !== undefined)
                    .filter((win) => parseInt(win.windowStartDate) === parseInt(value))
            if(isWinStartDateAlreadySelectedDiffWin === undefined || isWinStartDateAlreadySelectedDiffWin.length <= 1 ) {
                setIsButtonDisabled(false)
                return undefined
            } else {
                setIsButtonDisabled(true)
                return message
            }
        };
    }

  const save = useCallback(
       async (values) => {
           if(values.windows !== null) {
               values.windows.forEach((window, index) => {
                   window.windowOrder = index;
               });
           }
           try {
              document.getElementById("WindowsError").innerHTML = ""               
              const token = localStorage.getItem('tap-token');
              const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', Authorization: token},
                body: JSON.stringify(values)
              };
              fetch(process.env.REACT_APP_API_URL + '/windowSequence/' + values.id, requestOptions).then(response => response.json())
              .then(body => {
                console.log("body ", body)
                var errorMessage = ""
                if (body.errors && Object.keys(body.errors).length > 0) {                  
                  if (body.errors['windowSequenceName'])
                    errorMessage += body.errors['windowSequenceName']                  
                  if (body.errors['windowsError'])
                    errorMessage += errorMessage.length > 0 ? ", " + body.errors['windowsError'] : body.errors['windowsError']                    
                  notify(errorMessage, {
                      type: "error",
                      undoable: false,
                      messageArgs: { resource: 'put' }
                  })
                } else if (body.error) {
                    toast.dismiss()
                    errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body && body.error && body.error.message ? body.error.message : "Internal Server Error"}</div></div>
                    toast.error(errorMessage, {
                      position: toast.POSITION.BOTTOM_CENTER,
                      closeButton: true,
                      autoClose: 5000
                    });
                } else {                   
                  redirect('/windowSequence');
                  notify("Windowing Sequence Saved", {
                      undoable: false,
                      messageArgs: { resource: 'put' }
                  })
                  return true
                }               
              }).catch(({ status, statusText, headers, body }) => {
                  console.log("Caught status status ", status);
                  console.log("Caught status statusText ", statusText);
                  console.log("Caught status headers ", headers);
                  console.log("Caught status body ", body);
              })
           } catch (error) {
               console.log("Caught error ", error.body.errors)
               if (error.body.errors) {
                  if (error.body.errors.windowsError)
                    document.getElementById("WindowsError").innerHTML = error.body.errors.windowsError
               }
               return error.body.errors;
           } finally {
             console.log("inside finally ")
             return
           }
       },
       [notify, redirect],
  );

    return (
      <Edit undoable={false} title={<EditWindowingSequenceTitle />}  {...props} >
        <SimpleForm {...props} save={save} toolbar={<CustomToolbar disabled={isButtonDisabled} />}>
          <WindowingSequenceForm {...props} />
          <div id="WindowsError" style={{ color: '#f44336',  width: '98%', paddingLeft: '1%', fontSize: '0.75rem;' }}></div>
          <Box display="flex" justifyContent="space-between" style={{ width: '98%', paddingLeft: '1%', float: 'left' }} id="WindowsArray">
            <ArrayInput source="windows" label="Windows" validate={required('At least one window must be defined.')}>
              <SimpleFormIterator disableReordering
                                  addButton={<AddButton disabled={isButtonDisabled}/>}
                                  removeButton={<RemoveButton />}>
                  <FormDataConsumer>
                      {( {
                            formData, // The whole form data
                            scopedFormData, // The data for this item of the ArrayInput
                            getSource, // A function to get the valid source inside an ArrayInput
                            ...rest
                        }  ) => {
                          const index = getWindowArrayIndex(getSource(''));
                          if(index === 0) {
                              return (
                              <>
                                  <NumberInput min="0" label="Window Order" source={getSource('windowOrder')}
                                              initialValue={index} style={{display:'none'}} />
                                  <TextInput style={{display: "none"}} label="id" source={getSource('id')}/>
                                  <TextInput style={{display: "none"}} label="windowSequenceId"
                                            source={getSource('windowSequenceId')}/>
                                  <ReferenceInput perPage={100} label="Name" reference="lists/transactionalWindows"
                                                  source={getSource('windowId')} validate={required()}>
                                      <SelectInput optionText="label"/>
                                  </ReferenceInput>
                                  <div style={{ height: '75px'}} > </div>
                                  <SelectInput id={'windowStartDate_enabled' + index}
                                              resettable
                                              perPage={100}
                                              label="Window Start Date"
                                               onFocus={async () => {
                                                   await trigger();
                                               }}
                                               onChange={async () => {
                                                   await trigger();
                                               }}
                                              choices ={windowStartDates}
                                              source={getSource('windowStartDate')}
                                              validate={[required(), sameWindowStartDateOnDiffWindow(scopedFormData, formData, index)]}>
                                  <SelectInput optionText="label"/>
                                  </SelectInput>
                                  <NumberInput min="0" label="Duration" source={getSource('windowDuration')}
                                              validate={[required()]}/>
                                  <SelectInput label="Duration Unit" source={getSource('windowDurationUnit')}
                                              choices={durationUnits} validate={required()} resettable/>
                              </>
                          );
                      } else {
                          return (
                              <>
                                  <NumberInput min="0" label="Window Order" source={getSource('windowOrder')}
                                              initialValue={index} style={{display:'none'}} />
                                  <TextInput style={{display: "none"}} label="id" source={getSource('id')}/>
                                  <TextInput style={{display: "none"}} label="windowSequenceId"
                                            source={getSource('windowSequenceId')}/>
                                  <ReferenceInput
                                          perPage={100}
                                          label="Name"
                                          reference="lists/transactionalWindows"
                                          source={getSource('windowId')}
                                          validate={required()}>
                                      <SelectInput optionText="label"/>
                                  </ReferenceInput>
                                  <BooleanInput
                                      id={'previousWindowPlusOneStart'+index}
                                      source={getSource('previousWindowPlusOneStart')}
                                      label="Previous Window +1 Start"
                                      onClick={async () => {
                                          await trigger();
                                      }}
                                      onChange={async () => {
                                          await trigger();
                                      }}
                                      initialValue={true}
                                      defaultValue={true}/>
                                  <SelectInput id={'windowStartDate_enabled' + index}
                                      perPage={100} label="Window Start Date"
                                      resettable
                                      choices ={windowStartDates}

                                      source={getSource('windowStartDate')}
                                      onFocus={async () => {
                                      await trigger();
                                      }}
                                      onChange={async () => {
                                      await trigger();
                                      }}
                                      validate={[requiredIfNonPreviousWindowPlusOne(scopedFormData, formData, index), sameWindowStartDateOnDiffWindow(scopedFormData, formData, index)]}>
                                      <SelectInput optionText="label" />
                                  </SelectInput>
                                  <NumberInput min="0" label="Duration" source={getSource('windowDuration')}
                                              validate={[required()]}/>
                                  <SelectInput label="Duration Unit" source={getSource('windowDurationUnit')}
                                              choices={durationUnits} validate={required()} resettable/>
                                  <SelectInput id={'usePricingTierFrom' + index} label="Use Pricing Tier From" 
                                        source={getSource('usePricingTierFrom')}
                                        choices={usePricingTierFromOptions} 
                                        onChange={async (value) => {
                                          await trigger(["previousWindowPlusOneStart" + index, "windowStartDate_enabled" + index, "usePricingTierFrom" + index]);
                                         }}
                                         disabled={disabledUsePricingTierFromRef.current[index]}
                                         validate={[requiredIfPreviousWindowPlusOneOnAndNoWindowStartDateSelected(scopedFormData, formData, index), nextWindowIfNextWindowSelectedEdit(scopedFormData, formData, index)]}
                                        resettable/>
                              </>
                          );
                      }
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
          <Box display="flex" justifyContent="center" style={{ width: '100%', clear: 'both' }}>
          </Box>
        </SimpleForm>
      </Edit>
    );
};

export const AddButton = props => (
  <Button disabled={props.disabled} {...props} style={{ cursor: 'pointer', color: 'green'}} label="Window" margin="normal">
    <AddIcon label="Window" style={{ cursor: 'pointer', color: 'green'}}  />
  </Button>
);

export const RemoveButton = ({ onClick, ...props }) => {
  const { index } = useSimpleFormIteratorItem();
  return (
    <Button onClick={onClick} disabled={index === 0} // Disable if index is 0
      style={{ cursor: index === 0 ? 'not-allowed' : 'pointer', color: index === 0 ? 'gray' : 'red', borderColor: 'red', }}
      margin="normal" {...props} >
      <RemoveCircleRoundedIcon style={{ cursor: index === 0 ? 'not-allowed' : 'pointer', color: index === 0 ? 'gray' : 'red', }} />
    </Button>
  );
};

export const WindowingSequenceCreate = props => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const disabledUsePricingTierFromRef = useRef({});
    const {trigger} = useForm();
    const notify = useNotify();
    const redirect = useRedirect();

    const save = useCallback(
       async (values) => {
           try {
              document.getElementById("WindowsError").innerHTML = ""              
              const token = localStorage.getItem('tap-token');
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: token},
                body: JSON.stringify(values)
              };
              fetch(process.env.REACT_APP_API_URL + '/windowSequence', requestOptions).then(response => response.json())
              .then(body => {
                console.log("body ", body)
                var errorMessage = ""
                if (body.errors && Object.keys(body.errors).length > 0) {                  
                  if (body.errors['windowSequenceName'])
                    errorMessage += body.errors['windowSequenceName']                  
                  if (body.errors['windowsError'])
                    errorMessage += errorMessage.length > 0 ? ", " + body.errors['windowsError'] : body.errors['windowsError']                    
                  notify(errorMessage, {
                       type: "error",
                       undoable: false,
                       messageArgs: { resource: 'post' }
                  })
                } else if (body.error) {
                  toast.dismiss()
                  errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{body && body.error && body.error.message ? body.error.message : "Internal Server Error"}</div></div>
                  toast.error(errorMessage, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    closeButton: true,
                    autoClose: 5000
                  });
                } else {                  
                  redirect('/windowSequence');
                  notify("Windowing Sequence Created", {
                       undoable: false,
                       messageArgs: { resource: 'post' }
                  })
                  return true
                }               
               }).catch(({ status, statusText, headers, body }) => {
                  console.log("Caught status status ", status);
                  console.log("Caught status statusText ", statusText);
                  console.log("Caught status headers ", headers);
                  console.log("Caught status body ", body);
              })
             } catch (error) {
                 if (error.body.errors) {
                    if (error.body.errors.windowsError)
                      document.getElementById("WindowsError").innerHTML = error.body.errors.windowsError
                 }
                 return error.body.errors;
             } finally {
               return
             }
       },
       [notify, redirect],
  );

    const requiredIfNonPreviousWindowPlusOne = (scopedFormData, formData, index) => {
        return (value, allValues) => {
            const record = allValues.windows[index];
            if(record === undefined) {
                return undefined
            } else if(record.previousWindowPlusOneStart === undefined) {
                return undefined
            } else if((record.previousWindowPlusOneStart === false ) && (value === undefined || value === null || value === '')) {
                return "Required"
            } else {
                return undefined
            }
        };
    };

  const nextWindowIfNextWindowSelected = (scopedFormData, formData, index) => {
    return (value, allValues) => {
        const record = allValues.windows[index];
        if(record === undefined) {
            return undefined
        }
        if(record.previousWindowPlusOneStart) {
            return undefined
        }
        if(value === null || value === undefined)
          return undefined
        if(allValues === null || allValues.windows === null || allValues.windows === undefined) 
          return undefined

        const message = "Please create a subsequent window in the Window Sequence before selecting this value.";
        const validWindows = allValues.windows.filter(win => win !== null && win !== undefined);

        for (let i = 0; i < validWindows.length; i++) {
          const window = validWindows[i];
          if (window.usePricingTierFrom === 'NextWindow' && i === index) {
            const nextWindow = validWindows[window.windowOrder + 1];
            if (!nextWindow) {
              setIsButtonDisabled(false);
              return message; 
            }
            if (nextWindow && (nextWindow.usePricingTierFrom === undefined && nextWindow.windowStartDate === undefined)) {
              setIsButtonDisabled(true);
              return message; 
            }
          }
        }
  
        setIsButtonDisabled(false);
        return undefined;
    };
}

  const requiredIfPreviousWindowPlusOneOnAndNoWindowStartDateSelected = (scopedFormData, formData, index) => {
    return (value, allValues) => {
      if(allValues === undefined)
        return undefined;
      const message = "Required";
      const record = allValues.windows[index];
        if(record === undefined)
            return record
        if(scopedFormData === undefined){
          disabledUsePricingTierFromRef.current[index] = false;
          return undefined;
        }
        if (!record.previousWindowPlusOneStart) {
          disabledUsePricingTierFromRef.current[index] = true;
          record.usePricingTierFrom = undefined;
          return undefined;
        }
        const isWindowStartDateValid = record.windowStartDate !== undefined && record.windowStartDate !== null;

        if (!isWindowStartDateValid && (!value || value === '')) {
          disabledUsePricingTierFromRef.current[index] = false;
          return message;
        }
        if (isWindowStartDateValid) {
          record.usePricingTierFrom = undefined;
          disabledUsePricingTierFromRef.current[index] = true;
        } else {
          disabledUsePricingTierFromRef.current[index] = false;
        }
        return undefined;
   };
  };

    const sameWindowStartDateOnDiffWindowCreate = (scopedFormData, formData, index) => {
        return (value, allValues) => {
            const record = allValues.windows[index];
            if(record === undefined) {
                setIsButtonDisabled(false)
                return undefined
            }

            if(value === null || value === undefined) {
                setIsButtonDisabled(false)
                return undefined
            }
            const message =
                "Multiple Windows in a Window Sequence cannot have the same Window Start Date";
            const isWinStartDateAlreadySelectedDiffWin =
                allValues.windows
                    .filter((win) => win !== null && win !== undefined)
                    .filter((win) => win.windowStartDate !== null && win.windowStartDate !== undefined)
                    .filter((win) => parseInt(win.windowStartDate) === parseInt(value))
            if(isWinStartDateAlreadySelectedDiffWin === undefined || isWinStartDateAlreadySelectedDiffWin.length <= 1 ) {
                setIsButtonDisabled(false)
                return undefined
            } else {
                setIsButtonDisabled(true)
                return message
            }
        };

    }

   return (
        <Create undoable={false} title={<EditWindowingSequenceTitle />} {...props} >
          <SimpleForm {...props} save={save} defaultValue={{ status: "ACTIVE"}} toolbar={<CustomToolbar disabled={isButtonDisabled} />}>
            <WindowingSequenceForm {...props} />
            <div id="WindowsError" style={{ color: '#f44336',  width: '98%', paddingLeft: '1%', fontSize: '0.75rem;' }}></div>
            <Box display="flex" justifyContent="space-between"
                style={{width: '98%', paddingLeft: '1%', float: 'left'}} id="WindowsArray">
                <ArrayInput source="windows" label="Windows" defaultValue={[{ windowName: ""}]} validate={required('At least one window must be defined.')}>
                    <SimpleFormIterator disableReordering addButton={<AddButton disabled={isButtonDisabled} />} 
                    removeButton={<RemoveButton/>}
                    >
                        <FormDataConsumer>
                            {( {
                                formData, // The whole form data
                                scopedFormData, // The data for this item of the ArrayInput
                                getSource, // A function to get the valid source inside an ArrayInput
                                ...rest
                            }  ) => {
                                const index = getWindowArrayIndex(getSource(''));
                                if(index === 0) {
                                    return (
                                        <>
                                            <NumberInput min="0" label="Window Order" source={getSource('windowOrder')}
                                                        initialValue={index} style={{display:'none'}} />
                                            <ReferenceInput perPage={100} label="Name"
                                                            reference="lists/transactionalWindows"
                                                            source={getSource('windowId')} validate={required()}>
                                                <SelectInput optionText="label"/>
                                            </ReferenceInput>
                                            <div style={{ height: '68px'}} > </div>
                                            <SelectInput id={'windowStartDate_enabled' + index}
                                                perPage={100}
                                                label="Window Start Date"
                                                resettable
                                                onChange={async () => {await trigger();}}
                                                choices ={windowStartDates}
                                                source={getSource('windowStartDate')}
                                                validate={[required(), sameWindowStartDateOnDiffWindowCreate(scopedFormData, formData, index)]}>
                                                  <SelectInput optionText="label"/>
                                            </SelectInput>
                                            <NumberInput min="0" label="Duration" source={getSource('windowDuration')}
                                                        validate={[required()]}/>
                                            <SelectInput label="Duration Unit" source={getSource('windowDurationUnit')}
                                                        choices={durationUnits} validate={required()} resettable/>
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            <NumberInput min="0" label="Window Order" source={getSource('windowOrder')}
                                                        initialValue={index} style={{display:'none'}}/>
                                            <ReferenceInput perPage={100} label="Name"
                                                            reference="lists/transactionalWindows"
                                                            source={getSource('windowId')} validate={required()}>
                                                <SelectInput optionText="label"/>
                                            </ReferenceInput>
                                            <BooleanInput
                                                id={'previousWindowPlusOneStart'+index}
                                                source={getSource('previousWindowPlusOneStart')}
                                                label="Previous Window +1 Start"
                                                onChange={async () => {

                                                    await trigger();
                                                }}
                                                onClick={async () => {

                                                    await trigger();
                                                }}
                                                defaultChecked
                                                initialValue={true}/>
                                            <SelectInput id={'windowStartDate_enabled' + index}
                                                perPage={100} label="Window Start Date"
                                                choices ={windowStartDates}
                                                source={getSource('windowStartDate')}
                                                onChange={async () => {
                                                    await trigger();
                                                }}

                                                resettable
                                                validate={[requiredIfNonPreviousWindowPlusOne(scopedFormData, formData, index), sameWindowStartDateOnDiffWindowCreate(scopedFormData, formData, index)]}>
                                            <SelectInput optionText="label" />

                                            </SelectInput>

                                            <NumberInput min="0" label="Duration" source={getSource('windowDuration')}
                                                        validate={[required()]}/>
                                            <SelectInput label="Duration Unit" source={getSource('windowDurationUnit')}
                                                        choices={durationUnits} validate={required()} resettable/>
                                            <SelectInput id={'usePricingTierFrom' + index} label="Use Pricing Tier From" source={getSource('usePricingTierFrom')}
                                                        choices={usePricingTierFromOptions} 
                                                        onChange={async (value) => {
                                                          await trigger(["previousWindowPlusOneStart" + index, "windowStartDate_enabled" + index, "usePricingTierFrom" + index]);
                                                        }}
                                                        disabled={disabledUsePricingTierFromRef.current[index]}
                                                        validate={[requiredIfPreviousWindowPlusOneOnAndNoWindowStartDateSelected(scopedFormData, formData, index),
                                                          nextWindowIfNextWindowSelected(scopedFormData, formData, index)]} 
                                                        resettable/>
                                        </>
                                    );
                                }
                            }}
                          </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>

            </Box>
            <Box display="flex" justifyContent="center" style={{ width: '100%', clear: 'both' }}>
            </Box>

          </SimpleForm>
        </Create>
    );

};
