import { FC, ReactNode } from 'react';

import Tippy, { TippyProps } from '@tippyjs/react';
import classNames from 'classnames';

import './Popover.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';

import { TestMetadata } from '../types';

export interface PopoverProps {
  /** Wrapped element that will toggle the popover */
  children: ReactNode;
  /** Class name */
  className?: string;
  /** Content Class name */
  contentClassName?: string;
  /** The actual content of the popover */
  popoverContent: TippyProps['content'];
  /** Props to be passed to the instanced Tippy component */
  tippyProps?: TippyProps;
  /** Prop to control hide/show behaviour */
  visible?: boolean;
  /** Test IDs for component sections */
  testMetadata?: {
    popover?: TestMetadata;
    popoverContent?: TestMetadata;
  };
}

const defaulTippyProps: TippyProps = {
  arrow: false,
};

const Popover: FC<PopoverProps> = ({
  className,
  contentClassName,
  children,
  popoverContent,
  tippyProps,
  testMetadata,
  visible,
}) => {
  return (
    <Tippy
      {...defaulTippyProps}
      {...tippyProps}
      className={classNames('sb-popover', className)}
      visible={visible}
      content={popoverContent}
      {...testMetadata?.popover}
    >
      <div
        className={classNames('sb-popover__content', contentClassName)}
        {...testMetadata?.popoverContent}
      >
        {children}
      </div>
    </Tippy>
  );
};

export default Popover;
