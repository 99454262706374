/*
 * This is an internal implementation for AG Grid 'getServerSideSelectionState' method that is available in higher version of the library.
 * Once we upgrade to the next major version of AG Grid this logic won't be needed anymore and 'gridApi.getServerSideSelectionState()'
 * should be used instead.
 * This implementation is based on the interfaces defined by ag grid  IServerSideSelectionState and IServerSideGroupSelectionState to facilitate
 * the migration process in the future.
 * More info: https://www.ag-grid.com/react-data-grid/grid-api/#reference-serverSideRowModel-getServerSideSelectionState
 * */

import { ColumnApi, GridApi, IRowNode } from 'ag-grid-community';

import { getDataGridContext, isGrouping } from './utils';

export interface IServerSideSelectionState {
  // Whether the majority of rows are selected or not
  selectAll: boolean;
  // All rows that have the opposite selection state to `selectAll`
  toggledNodes: string[];
}

export interface IServerSideGroupSelectionState {
  toggledNodes?: IServerSideGroupSelectionState[];
  nodeId?: string;
  selectAllChildren?: boolean;
}

const getRowNodeId = (rowNode: IRowNode): string => {
  if (!rowNode.id) {
    // eslint-disable-next-line no-console
    console.warn(
      `Missing "id" value for node ${rowNode}. Use "getRowId" grid option to the "id" value with`,
    );
  }

  return rowNode?.id ?? '';
};

export const getServerSideSelectionState = (
  api: GridApi,
  columnApi: ColumnApi,
): IServerSideSelectionState | IServerSideGroupSelectionState => {
  const dataGridContext = getDataGridContext(api);

  if (dataGridContext.shouldSelectAll) {
    return { selectAll: true, toggledNodes: [] };
  }

  const selectedNodes = api.getSelectedNodes();

  if (selectedNodes.length === 0) {
    return { selectAll: false, toggledNodes: [] };
  }

  if (!isGrouping(columnApi)) {
    return {
      selectAll: false,
      toggledNodes: selectedNodes.map(getRowNodeId),
    };
  }

  const serverSideGroupSelectionState: IServerSideGroupSelectionState = {
    selectAllChildren: false,
    toggledNodes: [],
  };

  /* Grouping logic from this point */

  // Sort nodes by hierarchy, higher nodes will go first to simplify building the final structure.
  selectedNodes.sort((nodeA, nodeB) => nodeA.level - nodeB.level);

  selectedNodes.forEach((node) => {
    const isGroupNode = node.group;
    const isTopLevelGroupNode = isGroupNode && node.level === 0;

    if (isTopLevelGroupNode) {
      serverSideGroupSelectionState?.toggledNodes?.push({
        nodeId: node.id,
        selectAllChildren: true,
      });

      return;
    }

    // If a parent node doesn't exit, or it is selected move to the next node.
    if (node.parent === null || node.parent.isSelected()) {
      return;
    }

    /* Subgroups logic */
    if (isGroupNode) {
      appendServerSideGroupSelectionStateNode(
        node,
        {
          nodeId: node.id,
          selectAllChildren: true,
        },
        serverSideGroupSelectionState,
      );

      return;
    }

    /* Leaf nodes logic */
    appendServerSideGroupSelectionStateNode(
      node,
      { nodeId: node.id },
      serverSideGroupSelectionState,
    );
  });

  return serverSideGroupSelectionState;
};

export const appendServerSideGroupSelectionStateNode = (
  rowNode: IRowNode,
  serverSideGroupSelectionStateNodeData: IServerSideGroupSelectionState = {
    selectAllChildren: false,
    nodeId: rowNode.id,
    toggledNodes: [],
  },
  serverSideGroupSelectionState: IServerSideGroupSelectionState,
): void => {
  if (!rowNode?.parent) {
    return;
  }

  if (rowNode.parent.level === -1) {
    serverSideGroupSelectionState.toggledNodes?.push(
      serverSideGroupSelectionStateNodeData,
    );

    return;
  }

  const parentNode = findServerSideGroupSelectionStateNodeById(
    serverSideGroupSelectionState,
    rowNode.parent.id,
  );

  if (parentNode) {
    parentNode.toggledNodes?.push(serverSideGroupSelectionStateNodeData);

    return;
  }

  appendServerSideGroupSelectionStateNode(
    rowNode.parent,
    {
      selectAllChildren: false,
      nodeId: rowNode.parent.id,
      toggledNodes: [serverSideGroupSelectionStateNodeData],
    },
    serverSideGroupSelectionState,
  );
};

export const findServerSideGroupSelectionStateNodeById = (
  serverSideGroupSelectionStateNode: IServerSideGroupSelectionState,
  nodeId?: string,
): IServerSideGroupSelectionState | null => {
  let result = null;

  if (!nodeId) {
    return null;
  }

  if (serverSideGroupSelectionStateNode.nodeId === nodeId) {
    result = serverSideGroupSelectionStateNode;

    return result;
  }

  if (!serverSideGroupSelectionStateNode.toggledNodes) {
    return null;
  }

  for (const node of serverSideGroupSelectionStateNode.toggledNodes) {
    if (node.nodeId === nodeId) {
      result = node;

      break;
    }

    if (node.toggledNodes) {
      result = findServerSideGroupSelectionStateNodeById(node, nodeId);
    }
  }

  return result;
};

export const isServerSideSelectionState = (
  serverSideSelectionState:
    | IServerSideSelectionState
    | IServerSideGroupSelectionState,
): serverSideSelectionState is IServerSideSelectionState =>
  'selectAll' in serverSideSelectionState;

export const isServerSideGroupSelectionState = (
  serverSideSelectionState:
    | IServerSideSelectionState
    | IServerSideGroupSelectionState,
): serverSideSelectionState is IServerSideGroupSelectionState =>
  'selectAllChildren' in serverSideSelectionState;
