import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import {
  deselectAllRows,
  getDataGridContext,
  hasSelectedAllRows,
  selectAllRows,
  setContextValue,
} from './../../utils';

import Checkbox from '../../../Checkbox';

import './DataGridSelectAllHeader.scss';

import { GridApi, RowSelectedEvent } from 'ag-grid-community';
import _uniqueId from 'lodash/uniqueId';

export interface DataGridSelectAllHeaderProps {
  api: GridApi;
  value: string;
  instanceId?: string;
}

const DataGridSelectAllHeader: React.FC<DataGridSelectAllHeaderProps> = memo(
  ({ api, value, instanceId }) => {
    const [selectAll, setSelectAll] = useState(() => hasSelectedAllRows(api));
    const genId = useRef(_uniqueId('DataGridSelectAllCheckbox-'));

    useEffect(() => {
      const onRowSelectedChanged = (e: RowSelectedEvent): void => {
        const context = getDataGridContext(api);
        const rowSelectionCheckboxClicked = e.source === 'checkboxSelected';

        // When 'shouldSelectAll' is true and the row selected event is not coming from a user action, rows are automatically
        // selected in 'onModelUpdated' event, so the side effects that run in this event handler to update select all state need to be skipped
        // to avoid accidentally resetting select all to false.
        if (!rowSelectionCheckboxClicked && context.shouldSelectAll) {
          return;
        }

        const hasSelectedAll = hasSelectedAllRows(api);

        setContextValue(api, {
          shouldSelectAll: hasSelectedAll,
        });

        setSelectAll(hasSelectedAll);
      };

      api.addEventListener('rowSelected', onRowSelectedChanged);

      return () => {
        api.removeEventListener('rowSelected', onRowSelectedChanged);
      };
    }, [api]);

    const handleSelectAllChanged = useCallback((): void => {
      const selectAllValue = !selectAll;

      setContextValue(api, {
        shouldSelectAll: selectAllValue,
      });

      if (selectAllValue) {
        selectAllRows(api);
      } else {
        deselectAllRows(api);
      }

      setSelectAll(selectAllValue);
    }, [selectAll, api]);

    return (
      <div
        className="sb-checkbox-header__container"
        data-testid="DataGrid_SelectAllHeader_Cell"
      >
        <span
          className="sb-datagrid_group-row_label"
          data-testid="DataGrid_Group_Label"
        >
          {value}
        </span>

        <Checkbox
          className="sb-checkbox_no-label"
          data-testid="DataGrid_SelectAllHeader"
          onChange={handleSelectAllChanged}
          checked={selectAll}
          option={{
            value: 0,
            label: '',
            id: instanceId || genId.current,
          }}
        />
      </div>
    );
  },
);

export default DataGridSelectAllHeader;
