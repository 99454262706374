import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { Button, Loading, useRefresh } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import ViewIcon from '@material-ui/icons/RemoveRedEye';
import StartIcon from '@mui/icons-material/Start';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import parse from 'html-react-parser';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ViewJobDrawer extends Component {
    state = {
      showPanel: false,
      job : {},
      message: undefined,
      messageList: [],
      errors: [],
      isLoading: true,
      isLoadingTasks: false,
      data: {},
      isComplete: false,
      progress: 0,
      showMessages: '',
      page:1,
      totalPages:1,
      limit:10,
    };
    buttons = [
      <ToggleButton value="" >Show All</ToggleButton>,
      <ToggleButton value="COMPLETE" >Show Successes</ToggleButton>,
      <ToggleButton value="INVALID" >Show Failures</ToggleButton>,
    ];
    handleMessagesToggle = (event, newMessageToggle) => {      
      this.setState({showMessages: newMessageToggle, page: 1});
      var id = this.props.id      
      setTimeout(() => {this.loadJobAndTasks(id)}, 50);      
    };
    fetchJob = async (jobId) => {
        const token = localStorage.getItem('tap-token');
        const isLicensingJob = this.props.isLicensingJob ? true : false
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        return await fetch(process.env.REACT_APP_API_URL + '/jobs/' + jobId + '?isLicensingJob=' + isLicensingJob, requestOptions)
        .then(response => response.json())
        .then(responsData => {
          return responsData
        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          return []
        });
    };
    fetchJobTasks = async (jobId, page=1, limit=10, showMessages='') => {
        const token = localStorage.getItem('tap-token');
        const isLicensingJob = this.props.isLicensingJob ? true : false
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        return await fetch(process.env.REACT_APP_API_URL + '/jobs/tasks/' + jobId + '?isLicensingJob=' + isLicensingJob+ '&page=' + page + '&limit=' + limit + '&taskStatus=' + showMessages, requestOptions)
        .then(response => response.json())
        .then(responsData => {
            return{
              jobTasks: responsData.tasks.sort((a, b) => parseInt(a.taskDetailsId) - parseInt(b.taskDetailsId)),
              totalPages: responsData.totalPages || 1
            };
        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          return { jobTasks:[], totalPages:0 }
        });
    };
 
    handlePrevPage = () => {
      this.setState(prevState => {
          if (prevState.page > 1) {
              return { page: prevState.page - 1 };
          }
          return null;
      }, () => this.loadJobAndTasks(this.props.id));
    };

    handleNextPage = () => {
      this.setState(prevState => {
          if (prevState.page < this.state.totalPages) {
              return { page: prevState.page + 1 };
          }
          return null;
      }, () => this.loadJobAndTasks(this.props.id));
    };

    handlePageInput = (e) => {
      let newPage = Number(e.target.value);
      if (newPage >= 1 && newPage <= this.state.totalPages) {
        this.setState({ page: newPage }, () => {
          this.loadJobAndTasks(this.props.id);
        });
      }
    };

    handlePageChange = (selectedPage) => {
      this.setState({ page: selectedPage }, () => {
        this.loadJobAndTasks(this.props.id);
      });
    };

    loadJobAndTasks = async (jobId) => {
      this.setState({ isLoadingTasks: true });
      const {page, limit, showMessages } = this.state;
      let jobTasks = [];
      let totalPages = 1;
      let jobTasksGrouped = new Map();
      try {
          const [job, response] = await Promise.all([
              this.fetchJob(jobId),
              this.fetchJobTasks(jobId, page, limit, showMessages)
          ]);
          jobTasks = response.jobTasks || [];
          totalPages = response.totalPages || 1;

          const processingRegex = /(Processing|Found)/;          
          const successRegex = /Created/;

          const alphaMap = Object.assign({}, ...'abcdefghijklmnopqrstuvwxyz'.split('').map((c, i) => ({ [c]: i + 1 })));
          
          jobTasks.sort((a, b) => a.taskDetailsId - b.taskDetailsId);
          jobTasks.forEach(jobTask => {
              let taskGroupKey = [...jobTask.taskDetails.toLowerCase()].reduce((sum, char) => sum + (alphaMap[char] || 27), 0);
  
              if (!jobTasksGrouped.has(taskGroupKey)) {
                  jobTasksGrouped.set(taskGroupKey, {
                      messages: [],
                      taskDetails: jobTask.taskDetails,
                      taskStatus: jobTask.taskStatus,
                      taskCreated: jobTask.createdAt
                  });
              }
  
              const group = jobTasksGrouped.get(taskGroupKey);
              const indivMessages = jobTask.statusMessage ? jobTask.statusMessage.split('\n') : [];
  
              indivMessages.forEach(message => {
                  if (!message) return;
  
                  let item = `<span class='job-success-${successRegex.test(message) || processingRegex.test(message)}'>${message}</span>`; 
                      group.messages.push(item);
                      group.taskCreated = jobTask.createdAt;
              });
          });
          let sortedGroupedTasks = Array.from(jobTasksGrouped.values()).sort((a, b) =>
              new Date(a.taskCreated) - new Date(b.taskCreated)
          );
          let messageList = sortedGroupedTasks
              .filter(group => group.messages.length > 0)
              .map((group, index) => (
                  <ListItem className="groupedTasks-Header" key={`groupedTasks-${index}`}>
                      <ListItemText primary={<span>{group.taskDetails}</span>} 
                                    secondary={<span className="job-message">{parse(group.messages.join("<br/>"))}</span>} />
                  </ListItem>
              ));

          this.setState({
              job,
              totalPages: totalPages,
              data: jobTasks,
              jobTasksGrouped: sortedGroupedTasks,
              messageList,
              showPanel: true,
              isLoading: false,
              isLoadingTasks: false,
              isComplete: !['RUNNING', 'CREATED', 'STARTING', 'RETRYING', 'QUEUED', 'QUEUED_ADMINISTRATOR', 'QUEUED_UPDATED', 'QUEUED_UPDATED_USER_INITIATED', 'QUEUED_ADMINISTRATOR', 'QUEUED_UPDATED_ADMINISTRATOR'].includes(job.jobStatus),
              progress: job.progressCount > 0 && job.targetCount > 0 ? (job.progressCount / job.targetCount) * 100 : 0
          });
  
          if (!['RUNNING', 'CREATED', 'STARTING', 'RETRYING', 'QUEUED', 'QUEUED_UPDATED', 'QUEUED_UPDATED_USER_INITIATED', 'QUEUED_ADMINISTRATOR', 'QUEUED_UPDATED_ADMINISTRATOR'].includes(job.jobStatus)) {
              clearInterval(this.state.jobInterval);
          }
      } catch (error) {
          console.error("Error loading job and tasks:", error);
          this.setState({ jobTasks: [], totalPages: 1, isLoadingTasks: false });
      }
   };

    startUpdateEngine = async () => {
        const token = localStorage.getItem('tap-token');
        const requestOptions = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json', Authorization: token},
        };
        return await fetch(process.env.REACT_APP_API_URL + '/jobs/update-engine/start', requestOptions)
        .then(response => response.json())
        .then(responsData => {
          return responsData
        }).catch(({ status, statusText}) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          return 0
        });
    };
    handleClickStartUpdateEngine = async () => {
      this.setState({
        showPanel: true,
        job : {},
        message: undefined,
        messageList: [],
        errors: [],
        isLoading: true,
        data: {},
        isComplete: false,
        progress: 0
      });
      const jobId = await this.startUpdateEngine();
      console.log("startUpdateEngine jobId ", jobId)
      if (isNaN(jobId)) {        
        var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{jobId.error.message}</div></div>
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: true,
          autoClose: false
        });
        this.setState({           
          showPanel: false,
          isLoading: false
        });
      } else {
        var id = jobId
        var self = this
        var jobInterval = setInterval(function() {self.loadJobAndTasks(id)}, 2000);
        this.setState({ jobInterval: jobInterval });
      }      
    };
    handleClick = async () => {
      this.setState({
        showPanel: true,
        job : {},
        message: undefined,
        messageList: [],
        errors: [],
        isLoading: true,
        data: {},
        isComplete: false,
        progress: 0
      });
      var id = this.props.id
      var self = this
      var jobInterval = setInterval(function() {self.loadJobAndTasks(id)}, 2000);
      this.setState({ jobInterval: jobInterval });
      document.getElementById('JobRefresh').className = 'NoRefresh'      
    };
    handleCloseClick = (event: object, reason: string) => {      
      if (reason == null || reason !== 'backdropClick') {
        clearInterval(this.state.jobInterval);
        document.getElementById('REFRESH').click()
        document.getElementById('JobRefresh').className = 'Refresh'
        this.setState({ showPanel: false });
      }
    };
    render() {
        function Refresh() {
          const refresh = useRefresh();
          return (
            <>
              <Button id='REFRESH' style={{ display: "none" }} onClick={() => refresh()} />
            </>
          )
        }
        function LinearProgressWithLabel(props) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} color={ props.status === 'FAILED' ? "error" : props.status === 'COMPLETE' ? 'success' : 'primary' } />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" style={{ color: props.status === 'FAILED' ? "red" : props.status === 'COMPLETE' ? 'green' : 'blue' }} color="text.secondary">{`${Math.round(
                  props.value,
                )}%`}</Typography>
              </Box>
            </Box>
          );
        }
        const { showPanel, job, isLoading, isLoadingTasks, messageList, progress, page, totalPages} = this.state;
        return (
            <Fragment>
                <div id="JobRefresh" className="Refresh" style={{ display: "none" }}>                  
                </div>
                <div id="ViewIcon" className="ViewIcon">
                  { this.props.status !== "READY" && this.props.id && <Button label={this.props.title} onClick={this.handleClick}><ViewIcon/></Button> }
                  { this.props.status !== "READY" && !this.props.id && this.props.title === 'Start Update Engine' && <Button label={this.props.title} onClick={this.handleClickStartUpdateEngine}><StartIcon/></Button> }
                </div>                
                <Drawer                    
                    disableEscapeKeyDown
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    {isLoading ? <Loading/> : <div style={{ marginLeft: '30px', marginRight: '40px' }}>
                      <Fragment>                        
                            <Typography style={{ fontSize: "28px "}} variant="subtitle1" color="inherit" >Job View</Typography>
                            <hr />                            
                            {!this.props.hideProgress && <Typography style={{ fontSize: "17px ", padding: "25px"}} color="inherit" >Job {job.id} : Status {job.jobStatus === "QUEUED_UPDATED" ? 'QUEUED FOR UPDATES' : job.jobStatus === "QUEUED_UPDATED_USER_INITIATED" ? 'QUEUED FOR UPDATES USER INITIATED' : job.jobStatus} : Progress : {job.progressCount} : Target : {job.targetCount} </Typography>}
                            {this.props.hideProgress && <Typography style={{ fontSize: "17px ", padding: "25px"}} color="inherit" >Job {job.id} : Status {job.jobStatus} : Deals Processed : {job.progressCount} </Typography>}
                            {!this.props.hideProgress && <LinearProgressWithLabel value={progress} status={job.jobStatus} /> }
                            <div style={{ marginBottom: '10px' }}/> <hr />
                            {this.props.title !== 'Start Update Engine' && <ToggleButtonGroup value={this.state.showMessages} exclusive={true} onChange={this.handleMessagesToggle} size="medium" aria-label="vertical outlined button group"
                              >
                                {this.buttons}
                            </ToggleButtonGroup>}
                            {messageList && messageList.map(message => message)}
                            <div style={{ zIndex: 1, textAlign: 'center', position: 'fixed', bottom: '10px', left: '50%',transform: 'translateX(-50%)'}}>
                              {isLoadingTasks && <div> <span className="loader" style={{ marginRight: '10px' }}>🔄</span></div> } 
                              <button disabled={page === 1 || isLoadingTasks} onClick={this.handlePrevPage}> Prev  </button>
                              <input type="text" size="3" value={page} onChange={(e) => this.handlePageInput(e)} disabled={isLoadingTasks} min="1" max={totalPages}/>
                              <span>   Page {page} of {totalPages}  </span>
                              <button disabled={page === totalPages || isLoadingTasks} onClick={this.handleNextPage}> Next </button>
                          </div>
                            <div style={{ zIndex: 1, textAlign: 'center', position: 'fixed', bottom: '0', float: 'left', right: '0' }}>
                              <Button                                
                                style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px'}}
                                label="Close"
                                onClick={this.handleCloseClick}>
                                  <IconCancel />
                             </Button>
                          </div>
                      </Fragment>
                    </div>}
                    <Refresh/>
                </Drawer>
            </Fragment>
        );
    }
}
const JobView = connect()(ViewJobDrawer);
export default JobView
