export const ASSET_URL = 'https://dtci-spellbook.s3-us-west-2.amazonaws.com';

export const IMAGE_URL = `${ASSET_URL}/img`;

export const ICON_URL = `${IMAGE_URL}/icons`;

export const MEDIA_URL = `${ASSET_URL}/media`;

export const STORIES_URL =
  'https://gitlab.disney.com/dtci-bp-software-solutions/ui/spellbook/-/blob/develop/src';
