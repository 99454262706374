import { FC, HTMLAttributes, useMemo } from 'react';

import classNames from 'classnames';

import Tooltip from '../Tooltip';

import './Typography.scss';

import { TippyProps } from '@tippyjs/react';

import { TestMetadata, TypographyVariants } from '../types';

const variantsMapping = {
  T100: 'h1',
  T90: 'h2',
  T80: 'h3',
  T70: 'h4',
  T60: 'h5',
  T50: 'h6',
  T40: 'p',
  T30: 'p',
  T20: 'p',
  T15: 'p',
  T10: 'p',
  T05: 'p',
} as const;

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  /** Applies the typography style */
  variant: TypographyVariants;
  /** Add vertical spacing below the typography element **/
  bottomGutter?: boolean;
  /** Changes color and cursor to pointer */
  clickable?: boolean;
  /** Increases font weight of text */
  bold?: boolean;
  /** Makes text italic */
  italic?: boolean;
  /** Test IDs for component sections */
  testMetadata?: TestMetadata;
  /** Flag to control whether the variants should change size based on screen size */
  responsive?: boolean;
  /** Trim text to container and add ellipsis */
  truncate?: boolean;
  /** Wrap text */
  wrap?: boolean;
  /** Title in tag */
  name?: string;
  /** Underlines the text */
  underline?: boolean;
  //** Tooltip props */
  tooltipProps?: TippyProps;
}

const Typography: FC<TypographyProps> = ({
  variant,
  className,
  bottomGutter = false,
  clickable = false,
  bold = false,
  italic = false,
  children,
  testMetadata,
  responsive = false,
  truncate = false,
  wrap = false,
  name,
  underline = false,
  tooltipProps,
  ...props
}) => {
  const CustomTag = variantsMapping[variant];

  const typographyClassNames = useMemo(
    () =>
      classNames(
        `sb-typography sb-typography_${variant}`,
        {
          'sb-typography_with-bottom-gutter': bottomGutter,
          'sb-typography_clickable': clickable,
          'sb-typography_bold': bold,
          'sb-typography_italic': italic,
          'sb-typography_responsive': responsive,
          'sb-typography_truncate': truncate,
          'sb-typography_wrap': wrap,
          'sb-typography_underline': underline,
        },
        className,
      ),
    [
      variant,
      bottomGutter,
      clickable,
      bold,
      italic,
      responsive,
      truncate,
      className,
      wrap,
      underline,
    ],
  );

  const text = (
    <CustomTag
      title={name}
      className={typographyClassNames}
      {...props}
      {...testMetadata}
    >
      {children}
    </CustomTag>
  );

  return truncate ? (
    <Tooltip tippyProps={tooltipProps} tooltipContent={<>{children}</>}>
      {text}
    </Tooltip>
  ) : (
    text
  );
};

export default Typography;
