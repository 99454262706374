import { ICellRendererParams } from 'ag-grid-community';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

import { ColumnSecondaryField, ColumnTypes } from '../types';

/**
 * Calculate the cell value based on the current value and valueFormatter if provided.
 * @param params
 */
export const getCellValue = <T>(
  params: ICellRendererParams<T, T> & ColumnTypes,
): T | ICellRendererParams['valueFormatted'] => {
  if (!params.colDef?.valueFormatter) {
    return params.value;
  }

  return params.valueFormatted;
};

export const getSecondaryValue = (
  params: ICellRendererParams & {
    secondaryField?: ColumnSecondaryField;
  },
): string | undefined => {
  if (isString(params.secondaryField)) {
    return get(params.data, params.secondaryField);
  } else if (isFunction(params.secondaryField)) {
    return params.secondaryField(params.data);
  } else {
    return undefined;
  }
};

export const getMetadataValue = (
  params: ICellRendererParams & {
    metadataField?: ColumnSecondaryField;
  },
): string | undefined => {
  if (isString(params.metadataField)) {
    return get(params.data, params.metadataField);
  } else if (isFunction(params.metadataField)) {
    return params.metadataField(params.data);
  }
  return undefined;
};
