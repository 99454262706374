import { ICellRendererParams } from 'ag-grid-community';
import classNames from 'classnames';

import { getCellValue } from './utils';

import { PrimaryColumn } from '../types';
import CellDemotedText from './CellDemotedText/CellDemotedText';
import CellMetadataText from './CellMetadataText/CellMetadataText';

import './RenderedCellWithMetadataAndDemotedValue.scss';

const RenderedCellWithMetadataAndDemotedValue: React.FC<{
  params: ICellRendererParams & PrimaryColumn;
  testId?: string;
}> = ({ params, testId }) => {
  const cellValue = getCellValue(params) ?? params.value;
  const isPrimaryGroupCell =
    params.colDef?.cellRenderer === 'agGroupCellRenderer' || !params.colDef;

  if (!cellValue && cellValue !== 0) {
    return params?.noValueFallback ? (
      <div data-testid={testId}>{params.noValueFallback}</div>
    ) : null;
  }

  const tooltipTitle = params.colDef?.tooltipField;

  return (
    <div
      title={
        params.colDef?.tooltipValueGetter
          ? undefined
          : tooltipTitle || cellValue
      }
      className="sb-standard-table_cell-primary"
      data-testid={testId}
    >
      <div
        className={classNames('sb-standard-table_cell-primary_row', {
          'sb-standard-table_cell-primary_row_with-metadata':
            !!params?.metadataField || isPrimaryGroupCell,
        })}
      >
        <div
          className={classNames('sb-standard-table_cell-primary_text', {
            'sb-standard-table_cell-not-primary_text': !isPrimaryGroupCell,
          })}
        >
          {cellValue}
        </div>
        <CellMetadataText
          params={params}
          testMetadata={
            testId
              ? { 'data-testid': `${testId}-metadata-text` }
              : { 'data-testid': 'cell-metadata-text' }
          }
        />
      </div>

      <CellDemotedText
        params={params}
        testMetadata={
          testId ? { 'data-testid': `${testId}-demoted-text` } : undefined
        }
      />
    </div>
  );
};

export default RenderedCellWithMetadataAndDemotedValue;
