// ---Dependencies
import React, {useEffect, useState} from 'react';
import {tapApi} from "./api/api";
import {AgTable} from "./components/ag-table";
import {useAgGridApi} from "./components/ag-table/hooks";
import TwoColumnsLayout from "./layouts/two-cols";

/**
 * @returns {React.ReactElement}
 */
export default function SpellbookTest() {

    const [offers, setOffers] = useState([]);

    useEffect(() => {
        void (async () => {
            const testData = await tapApi.offers(275);
            setOffers(testData);
        })();
        // eslint-disable-next-line
    }, []);

    const agGridApi = useAgGridApi();

    // -----------------------RENDER
    return (
        <TwoColumnsLayout>
            <main data-position="right">
                <AgTable
                    agGridRef={agGridApi.AgGridApiRef}
                    isRollup={false}
                    dataType={'offers'}
                    rowData={offers}
                />
            </main>
        </TwoColumnsLayout>

    );
}
