import * as React from 'react';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';
import Drawer from '@material-ui/core/Drawer';
import { Button, Loading, useRefresh } from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import parse from 'html-react-parser';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import 'react-toastify/dist/ReactToastify.css';

class DeleteBulkButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPanel: false,
      job : {},
      message: undefined,
      messageList: [],
      errors: [],
      isLoading: true,
      isLoadingTasks: false,
      data: {},
      isComplete: false,
      progress: 0,
      showMessages: '',
      page:1,
      totalPages:1,
      limit:20,
      jobInterval: 0,
    };
    this.loadJobAndTasks = this.loadJobAndTasks.bind(this);
  }

  buttons = [
    <ToggleButton value="" >Show All</ToggleButton>,
    <ToggleButton value="COMPLETE" >Show Successes</ToggleButton>,
    <ToggleButton value="INVALID" >Show Failures</ToggleButton>,
  ];
  handleMessagesToggle = (event, newMessageToggle) => {      
    this.setState({showMessages: newMessageToggle, page: 1});
    var id = this.state.job.id      
    setTimeout(() => {this.loadJobAndTasks(id)}, 50);      
  };
  fetchJob = async (jobId) => {
      const token = localStorage.getItem('tap-token');
      const isLicensingJob = false
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token},
      };
      return await fetch(process.env.REACT_APP_API_URL + '/jobs/' + jobId + '?isLicensingJob=' + isLicensingJob, requestOptions)
      .then(response => response.json())
      .then(responsData => {
        return responsData
      }).catch(({ status, statusText}) => {
        console.log("Caught status status ", status);
        console.log("Caught status statusText ", statusText);
        return []
      });
  };
  fetchJobTasks = async (jobId, page=1, limit=20, showMessages='') => {
      const token = localStorage.getItem('tap-token');
      const isLicensingJob = false
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token},
      };
      return await fetch(process.env.REACT_APP_API_URL + '/jobs/tasks/' + jobId + '?isLicensingJob=' + isLicensingJob+ '&page=' + page + '&limit=' + limit + '&taskStatus=' + showMessages, requestOptions)
      .then(response => response.json())
      .then(responsData => {
          return{
            jobTasks: responsData.tasks.sort((a, b) => parseInt(a.taskDetailsId) - parseInt(b.taskDetailsId)),
            totalPages: responsData.totalPages || 1
          };
      }).catch(({ status, statusText}) => {
        console.log("Caught status status ", status);
        console.log("Caught status statusText ", statusText);
        return { jobTasks:[], totalPages:0 }
      });
  };

  handlePrevPage = () => {
    this.setState(prevState => {
        if (prevState.page > 1) {
            return { page: prevState.page - 1 };
        }
        return null;
    }, () => this.loadJobAndTasks(this.state.job.id));
  };

  handleNextPage = () => {
    this.setState(prevState => {
        if (prevState.page < this.state.totalPages) {
            return { page: prevState.page + 1 };
        }
        return null;
    }, () => this.loadJobAndTasks(this.state.job.id));
  };

  handlePageInput = (e) => {
    let newPage = Number(e.target.value);
    if (newPage >= 1 && newPage <= this.state.totalPages) {
      this.setState({ page: newPage }, () => {
        this.loadJobAndTasks(this.state.job.id);
      });
    }
  };

  handlePageChange = (selectedPage) => {
    this.setState({ page: selectedPage }, () => {
      this.loadJobAndTasks(this.state.job.id);
    });
  };

  loadJobAndTasks = async (jobId) => {
    this.setState({ isLoadingTasks: true });
    const {page, limit, showMessages } = this.state;
    let jobTasks = [];
    let totalPages = 1;
    let jobTasksGrouped = new Map();
    try {
        const [job, response] = await Promise.all([
            this.fetchJob(jobId),
            this.fetchJobTasks(jobId, page, limit, showMessages)
        ]);
        jobTasks = response.jobTasks || [];
        totalPages = response.totalPages || 1;

        const processingRegex = /(Processing)/;          
        const successRegex = /deleted/;
    
        jobTasks.sort((a, b) => a.taskDetailsId - b.taskDetailsId);
        jobTasks.forEach(jobTask => {
            const taskStatusKey = jobTask.taskStatus;

            if (!jobTasksGrouped.has(taskStatusKey)) {
                jobTasksGrouped.set(taskStatusKey, []);
            }

            const taskGroup = jobTasksGrouped.get(taskStatusKey);

            const indivMessages = jobTask.statusMessage ? jobTask.statusMessage.split('\n') : [];

            let formattedMessages = indivMessages.map(message => {
              if (!message) return null;
              return `<span class='job-success-${successRegex.test(message) || processingRegex.test(message)}'>${message}</span>`;
            }).filter(Boolean);

            taskGroup.push({
              taskDetails: jobTask.taskDetails,
              taskCreated: jobTask.createdAt,
              messages: formattedMessages
          });
        });

        jobTasksGrouped.forEach((tasks, status) => {
          tasks.sort((a, b) => new Date(a.taskCreated) - new Date(b.taskCreated));
        });
        
        let sortedGroupedTasks = Array.from(jobTasksGrouped.entries());

        let messageList = sortedGroupedTasks.map(([status, tasks], index) => (
          <div key={`group-${index}`}>
              <h3 className="task-status-header" style={{ color: status === "COMPLETE" ? "green" : "inherit" }}>
                {status} </h3>
              {tasks.map((task, taskIndex) => (
                  <ListItem key={`task-${taskIndex}`}>
                      <ListItemText
                          primary={<span>{task.taskDetails}</span>}
                          secondary={<span>{parse(task.messages.join("<br/>"))}</span>}
                      />
                  </ListItem>
              ))}
          </div>
      ));

        this.setState({
            job,
            totalPages: totalPages,
            data: jobTasks,
            jobTasksGrouped: sortedGroupedTasks,
            messageList,
            showPanel: true,
            isLoading: false,
            isLoadingTasks: false,
            isComplete: !['RUNNING', 'CREATED', 'STARTING', 'RETRYING'].includes(job.jobStatus),
            progress: job.progressCount > 0 && job.targetCount > 0 ? (job.progressCount / job.targetCount) * 100 : 0
        });

        if (!['RUNNING', 'CREATED', 'STARTING', 'RETRYING', 'QUEUED_UPDATED', 'QUEUED_UPDATED_USER_INITIATED'].includes(job.jobStatus)) {
          clearInterval(this.state.jobInterval);
        }
    } catch (error) {
        console.error("Error loading job and tasks:", error);
        this.setState({ jobTasks: [], totalPages: 1, isLoadingTasks: false });
    }
 };

  handleClick = async () => {
      this.setState({
        showPanel: false,
        job : {},
        message: undefined,
        messageList: [],
        errors: [],
        isLoading: true,
        data: {},
        isComplete: false,
        progress: 0
      });
  
      confirmAlert({
        title: 'Delete Offer(s)',
        message: 'Are you sure you want to delete the offer(s) from TAP2 and Rightsline? This action cannot be undone.',  
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              var selectedRows = this.props.selectedrows.data
              var dataTable = this.props.dataTable
              var selectedRowIdPayload = []
              selectedRows.forEach(element => {
                selectedRowIdPayload.push(dataTable[element.dataIndex].id)
              });
              const token = localStorage.getItem('tap-token');
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: token},
                body: JSON.stringify(selectedRowIdPayload)
              };
              this.setState({showPanel: true})
              try {
              const response = await fetch(process.env.REACT_APP_API_URL +  '/licensing/deleteOfferBulk', requestOptions);
              const responsData = await response.json();
              this.props.isLoading(false)
              if (responsData && typeof responsData !== 'object') {
                var self = this
                var jobInterval = setInterval(function() {self.loadJobAndTasks(responsData)}, 2000);
                this.setState({ jobInterval: jobInterval });
                document.getElementById('JobRefresh').className = 'NoRefresh'  
              } else {
                this.props.isLoading(false)
                var errorMessage = <div><div className="toastTitle">Error</div><div className="toastMainMessage">{responsData.statusText}</div></div>
                toast.error(errorMessage, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  closeButton: true,
                  autoClose: false
                });
              }
            }catch(error) {
                console.log("Caught status status ", error);
            }
        }
      },
        {
        label: 'No',
        onClick: () => {
          this.setState({showPanel: false})
          console.log("User cancelled deleted to RL")}
      }
      ]
    });
  }; 
  handleCloseClick = (event: object, reason: string) => {      
    if (reason == null || reason !== 'backdropClick') {
      clearInterval(this.state.jobInterval);
      document.getElementById('REFRESH').click()
      document.getElementById('JobRefresh').className = 'Refresh'
      this.setState({ showPanel: false });
      window.location.reload(true)
    }
  };
  render() {
      function Refresh() {
        const refresh = useRefresh();
        return (
          <>
            <Button id='REFRESH' style={{ display: "none" }} onClick={() => refresh()} />
          </>
        )
      }
      function LinearProgressWithLabel(props) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" {...props} color={ props.status === 'FAILED' ? "error" : props.status === 'COMPLETE' ? 'success' : 'primary' } />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" style={{ color: props.status === 'FAILED' ? "red" : props.status === 'COMPLETE' ? 'green' : 'blue' }} color="text.secondary">{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }
      const { showPanel, job, isLoading, isLoadingTasks, messageList, progress, page, totalPages} = this.state;
      return (
          <Fragment>
              <div id="JobRefresh" className="Refresh" style={{ display: "none" }}></div>
              <div id="deleteToRLbulk" style={{ float: 'right', marginLeft: 'auto', marginRight: '20px' }} >
              <Button style={{ float: 'center', marginLeft: 'center', color: '#F44336', }} label="Delete Offer(s)" onClick={this.handleClick} >
                <DeleteIcon/></Button>
            </div>              
              <Drawer                    
                  disableEscapeKeyDown
                  anchor="right"
                  open={showPanel}
                  onClose={this.handleCloseClick}
              >
                  {isLoading ? <Loading/> : <div style={{ marginLeft: '30px', marginRight: '40px' }}>
                    <Fragment>                        
                          <Typography style={{ fontSize: "28px "}} variant="subtitle1" color="inherit" >Job View</Typography>
                          <hr />                            
                          {!this.props.hideProgress && <Typography style={{ fontSize: "17px ", padding: "25px"}} color="inherit" >Job {job.id} : Status {job.jobStatus === "QUEUED_UPDATED" ? 'QUEUED FOR UPDATES' : job.jobStatus === "QUEUED_UPDATED_USER_INITIATED" ? 'QUEUED FOR UPDATES USER INITIATED' : job.jobStatus} : Progress : {job.progressCount} : Target : {job.targetCount} </Typography>}
                          {this.props.hideProgress && <Typography style={{ fontSize: "17px ", padding: "25px"}} color="inherit" >Job {job.id} : Status {job.jobStatus} : Deals Processed : {job.progressCount} </Typography>}
                          {!this.props.hideProgress && <LinearProgressWithLabel value={progress} status={job.jobStatus} /> }
                          <div style={{ marginBottom: '10px' }}/> <hr />
                          {this.props.title !== 'Start Update Engine' && <ToggleButtonGroup value={this.state.showMessages} exclusive={true} onChange={this.handleMessagesToggle} size="medium" aria-label="vertical outlined button group"
                            >
                              {this.buttons}
                          </ToggleButtonGroup>}
                          {messageList && messageList.map(message => message)}
                          <div style={{ zIndex: 1, textAlign: 'center', position: 'fixed', bottom: '10px', left: '50%',transform: 'translateX(-50%)'}}>
                            {isLoadingTasks && <div> <span className="loader" style={{ marginRight: '10px' }}>🔄</span></div> } 
                            <button disabled={page === 1 || isLoadingTasks} onClick={this.handlePrevPage}> Prev  </button>
                            <input type="text" size="3" value={page} onChange={(e) => this.handlePageInput(e)} disabled={isLoadingTasks} min="1" max={totalPages}/>
                            <span>   Page {page} of {totalPages}  </span>
                            <button disabled={page === totalPages || isLoadingTasks} onClick={this.handleNextPage}> Next </button>
                        </div>
                          <div style={{ zIndex: 1, textAlign: 'center', position: 'fixed', bottom: '0', float: 'left', right: '0' }}>
                            <Button                                
                              style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '20px'}}
                              label="Close"
                              onClick={this.handleCloseClick}>
                                <IconCancel />
                           </Button>
                        </div>
                    </Fragment>
                  </div>}
                  <Refresh/>
              </Drawer>
          </Fragment>
      );
  }
}                 

const DeleteOffer = connect()(DeleteBulkButton);
export default DeleteOffer
