import { useMemo } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import { getSecondaryValue } from '../utils';

import { TestMetadata } from '../../../types';
import { LinkColumn, PrimaryColumn } from '../../types';

import './CellDemotedText.scss';

export interface CellDemotedTextProps {
  params: ICellRendererParams & (PrimaryColumn | LinkColumn);
  testMetadata?: TestMetadata;
}

const CellDemotedText: React.FC<CellDemotedTextProps> = ({
  params,
  testMetadata = {
    'data-testid': 'table-cell-demoted-text',
  },
}): JSX.Element => {
  const value: string | undefined = useMemo(
    () => getSecondaryValue(params),
    [params],
  );

  return (
    <div
      className="sb-standard-table_cell_demoted-text"
      title={value}
      data-testid={testMetadata['data-testid']}
    >
      {value}
    </div>
  );
};

export default CellDemotedText;
