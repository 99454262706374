import React, {Fragment} from "react";
import MUIDataTable from "mui-datatables";
import myDataProvider from "../../TAPDataProvider";
import {Link} from "react-router-dom";
import Moment from "moment-timezone";
import {Loading} from 'react-admin';
import NumberFormat from 'react-number-format';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import parse from 'html-react-parser';
import AuthProvider from "../../TAPAuthProvider";

const OfferTableListBreadcrumb = ({record, licensingJobId}) => {
    return <div className="breadcrumbs"><Link to={"/licensing/offerTable/" + licensingJobId}>Offers </Link> &nbsp;>
        History of Offer {record}</div>
};
const localFormat = 'MM/DD/YYYY';

class OfferTable extends React.Component {

    state = {
        medias: [],
        territories: [],
        recordId: 0,
        licensingJobId: 0,
        page: 0,
        count: 0,
        rowsPerPage: 10,
        editRow: {},
        errorMessages: [],
        isLoading: true,
        data: [],
        isEpisodeFeatureFlagEnabled: false,
        columns: [
            {
                name: "historyCreatedAt",
                label: "History Created",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value)
                            return <span>{Moment(Moment.utc(value).toDate()).format('DD-MMM-YYYY, h:mm:ss A')}</span>
                        return <span></span>
                    }
                }
            },
            {
                name: "historyCreatedBy",
                label: "History Created By",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value ? value.fullName : ""}</span>
                    }
                }
            },
            {
                name: "modifiedAt",
                label: "Modified",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{Moment(Moment.utc(value).toDate()).format('DD-MMM-YYYY, h:mm:ss A')}</span>
                    }
                }
            },
            {
                name: "id",
                label: "ID",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '25px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value}</span>
                    }
                }
            },
            {
                name: "tapOfferStatus",
                label: "TAP Offer Status",
                options: {
                    filter: true,
                    sort: false,
                    display: true,
                    filterOptions: {
                        renderValue: val => {
                            return this.formatTapOfferStatus(val)
                        }
                    },
                    customFilterListOptions: {
                        render: v => {
                            return this.formatTapOfferStatus(v)
                        }
                    },
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '50px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var display = value
                        if (value === 'PENDING')
                            display = 'Pending'
                        if (value === 'PUSHED' || value === 'OFFER_IN_PAST_SYSTEM_UPDATED')
                            display = 'Pushed'
                        if (value === 'DUPLICATE' || value === 'DUPLICATE_DIFFJOB')
                            display = 'Duplicate'
                        if (value === 'SYSTEM_UPDATED')
                            display = 'System Updated'
                        if (value === 'USER_MODIFIED')
                            display = 'User Modified'
                        if (value === 'SYSTEM_UPDATED_OFFER_ACTIVE')
                            display = 'Offer Active - System Updated'
                        if (value === 'USER_MODIFIED_OFFER_ACTIVE')
                            display = 'Offer Active - User Modified'
                        if (value === 'INACTIVE_DEAL')
                            display = 'Inactive Deal'
                        if (value === 'BLANK_DEAL_FIELDS')
                            display = 'Blank Deal Fields'
                        if (value === 'RIGHTS_ISSUE')
                            display = 'Rights Issue'
                        if (value === 'MISSING_PRICING')
                            display = 'Missing Pricing'
                        if (value === 'INFO_RESTRICTIONS')
                            display = 'Info Restrictions'
                        if (value === 'TO_DELETE')
                            display = 'To Delete'
                        if (value === 'SYSTEM_CREATED')
                            display = 'System Created'
                        var compare = this.compareChanges('tapOfferStatus', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {display && typeof display == 'object' ? display.label : display}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {display && typeof display == 'object' ? display.label : display}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{display && typeof display == 'object' ? display.label : display}</span>
                        }
                    }
                }
            },
            {
                name: "offerTableRowRlId",
                label: "RL Id",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '25px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('offerTableRowRlId', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "licensingJobId",
                label: "Licensing Job Id",
                options: {
                    filter: true,
                    sort: false,
                    display: false
                }
            },
            {
                name: "dealId",
                label: "Deal Id",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '70px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var url = ""
                        if (process.env.REACT_APP_ENV === '')
                            url = "https://app.rightsline.com/mod4/"
                        if (process.env.REACT_APP_ENV === 'QA')
                            url = "https://app-staging.rightsline.com/mod4/"
                        if (process.env.REACT_APP_ENV === 'QAT')
                            url = "https://app-pm.rightsline.com/mod4/"
                        if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'LOCAL')
                            url = "https://app-int.rightsline.com/mod4/"
                        return <span>{value != null ? <a target="_blank" rel="noopener noreferrer"
                                                         href={`${url}${value}`}>{value}</a> : ""}</span>
                    }
                }
            },
            {
                name: "dealName",
                label: "Deal Name",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '400px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && typeof value == 'object' ? value.title : value}</span>
                    }
                }
            },
            {
                name: "catalogId",
                label: "Catalog Id",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '90px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var url = ""
                        if (process.env.REACT_APP_ENV === '')
                            url = "https://app.rightsline.com/mod1/"
                        if (process.env.REACT_APP_ENV === 'QA')
                            url = "https://app-staging.rightsline.com/mod1/"
                         if (process.env.REACT_APP_ENV === 'QAT')
                            url = "https://app-pm.rightsline.com/mod1/"
                        if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'LOCAL')
                            url = "https://app-int.rightsline.com/mod1/"
                        return <span>{value != null ? <a target="_blank" rel="noopener noreferrer"
                                                         href={`${url}${value}`}>{value}</a> : ""}</span>
                    }
                }
            },
            {
                name: "catalogName",
                label: "Catalog Name",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '400px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && typeof value == 'object' ? value.title : value}</span>
                    }
                }
            },
            {
                name: "readyToPublish",
                label: "Ready to Publish",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('readyToPublish', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "offerStatus",
                label: "Offer Status",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('offerStatus', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "dateTaken",
                label: "Date Taken",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '120px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('dateTaken', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        }
                    }
                }
            },
            {
                name: "media",
                label: "Media",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('media', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "territory",
                label: "Territory",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('territory', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "languages",
                label: "Languages",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('languages', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        }
                    }
                }
            },
            {
                name: "format",
                label: "Format",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('format', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "termStart",
                label: "Term Start",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '120px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('termStart', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY')}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY')}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY')}</span>
                        }
                    }
                }
            },
            {
                name: "termEnd",
                label: "Term End",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '120px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('termEnd', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY')}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY')}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY')}</span>
                        }
                    }
                }
            },
            {
                name: "startDateStatus",
                label: "Start Date Status",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('startDateStatus', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "endDateStatus",
                label: "End Date Status",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('endDateStatus', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "rating",
                label: "Rating",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '160px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('rating', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "rateCardType",
                label: "Rate Card Type",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '180px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('rateCardType', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "rateCardId",
                label: "Rate Card Id",
                options: {
                    filter: true,
                    sort: false,
                    display: true,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '100px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && typeof value == 'object' ? value.title : value}</span>
                    }
                }
            },
            {
                name: "rateCardName",
                label: "Rate Card Name",
                options: {
                    filter: true,
                    sort: false,
                    display: true,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '180px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && typeof value == 'object' ? value.title : value}</span>
                    }
                }
            },
            {
                name: "windows",
                label: "Window",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('windows', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "windowNum",
                label: "Window Number",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('windowNum', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "tier",
                label: "Tier",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '120px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('tier', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "licenseFee",
                label: "License Fee",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '120px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('licenseFee', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      <NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'}/></span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      <NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'}/></span>
                        } else if (compare === "NO_CHANGES") {
                            return <span><NumberFormat decimalScale={2} value={value ? value : ''}
                                                       displayType={'text'}/></span>
                        }
                    }
                }
            },
            {
                name: "minDeemedPrice",
                label: "Min Deemed Price",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '120px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('minDeemedPrice', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      <NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'}/></span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      <NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'}/></span>
                        } else if (compare === "NO_CHANGES") {
                            return <span><NumberFormat decimalScale={2} value={value ? value : ''}
                                                       displayType={'text'}/></span>
                        }
                    }
                }
            },
            // {
            //   name: "minAdvGuarantee",
            //   label: "Min Adv Guarantee",
            //   options: {
            //     filter: true,
            //     sort: false,
            //     setCellProps: value => {
            //       return {
            //         style: {
            //           minWidth: '120px',
            //         },
            //       };
            //     },
            //     customBodyRender: (value, tableMeta, updateValue) => {
            //       var compare = this.compareChanges('minAdvGuarantee', value, tableMeta)
            //       if(compare === "CHANGED"){
            //         return <span style={{width: 15,  color:"blue", fontWeight:"bolder", fontStyle:"italic", backgroundColor:"yellow"}} >
            //           <NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'} /></span>
            //       }else if(compare === "REMOVED") {
            //         return <span style={{width: 15,  color:"red", fontWeight:"bolder", fontStyle:"italic", backgroundColor:"yellow"}}>
            //           {'REMOVED'}</span>
            //       } else if(compare === "ADDED") {
            //         return <span style={{width: 15,  color:"green", fontWeight:"bolder", fontStyle:"italic", backgroundColor:"yellow"}}>
            //           <NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'} /></span>
            //       } else if(compare === "NO_CHANGES") {
            //         return <span><NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'} /></span>
            //       }
            //     }
            //   }
            // },
            {
                name: "seasonPassAmt",
                label: "Season Pass Amt",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '120px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('seasonPassAmt', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      <NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'}/></span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      <NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'}/></span>
                        } else if (compare === "NO_CHANGES") {
                            return <span><NumberFormat decimalScale={2} value={value ? value : ''}
                                                       displayType={'text'}/></span>
                        }
                    }
                }
            },
            {
                name: "episodeTier",
                label: "Episode Tier",
                options: {
                    display: false,
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '120px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('episodeTier', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                },
            },
            {
                name: "pmxPlatform",
                label: "Pmx Platform",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '160px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('pmxPlatform', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "currency",
                label: "Currency",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '170px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('currency', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "percentageSplit",
                label: "Percentage split",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('percentageSplit', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      <NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'}
                                    suffix={'%'}/></span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      <NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'}
                                    suffix={'%'}/></span>
                        } else if (compare === "NO_CHANGES") {
                            return <span><NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'}
                                                       suffix={'%'}/></span>
                        }
                    }
                }
            },
            {
                name: "emaPriceType",
                label: "EMA Price Type",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('emaPriceType', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "episodeEmaPriceType",
                label: "Episode EMA Price Type",
                required: true,
                options: {
                    filter: true,
                    display: false,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('episodeEmaPriceType', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "viewingPeriod",
                label: "Viewing Period",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '160px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('viewingPeriod', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "preSell",
                label: "Pre Sell",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '120px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('preSell', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        }
                    }
                }
            },
            {
                name: "subLanguage",
                label: "Sub Language",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('subLanguage', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        }
                    }
                }
            },
            {
                name: "dubLanguage",
                label: "Dub Language",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('dubLanguage', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        }
                    }
                }
            },
            {
                name: "tprProgramName",
                label: "TPR Program Name",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '160px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('tprProgramName', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "ocad",
                label: "Consumer Announcement Date",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '180px',
                            },
                        };
                    },
                    customBodyRender: (v, tableMeta, updateValue) => {
                        let value = ""
                        let compare = "";
                        try {
                            if (v !== null) {
                                value = Moment(v).format('YYYY-MM-DD')
                                compare = this.compareChanges('ocad', value, tableMeta)
                            } else {
                                compare = this.compareChanges('ocad', v, tableMeta)
                            }
                        } catch (error) {
                            console.error("Error ocad value", error.message)
                        }

                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                            {value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value}</span>
                        }
                    }
                }
            },
            {
                name: "suppressionLiftDate",
                label: "Suppression Lift Date",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '120px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('suppressionLiftDate', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        }
                    }
                }
            },
            {
                name: "exclusivity",
                label: "Exclusivity",
                options: {
                    filter: true,
                    sort: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && typeof value == 'object' ? value.title : value}</span>
                    }
                }
            },
            {
                name: "holdbackLanguages",
                label: "Holdback Languages",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '180px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('holdbackLanguages', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                          {value ? value.replaceAll("|", " | ") : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                          {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                              {value ? value.replaceAll("|", " | ") : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value ? value.replaceAll("|", " | ") : value}</span>
                        }
                    }
                }
            },
            {
                name: "languageAvailsDisplay",
                label: "Language Avails Display",
                required: true,
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('languageAvailsDisplay', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                      {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "availsTypeOverride",
                label: "Avails Type Override",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('availsTypeOverride', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value && value ? value : ''}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value && value ? value : ''}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "informationalRestrictions",
                label: "Informational Restrictions",
                options: {
                    filter: true,
                    sort: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var compare = this.compareChanges('informationalRestrictions', value, tableMeta)
                        if (compare === "CHANGED") {
                            return <span style={{
                                width: 15,
                                color: "blue",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "REMOVED") {
                            return <span style={{
                                width: 15,
                                color: "red",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {'REMOVED'}</span>
                        } else if (compare === "ADDED") {
                            return <span style={{
                                width: 15,
                                color: "green",
                                fontWeight: "bolder",
                                fontStyle: "italic",
                                backgroundColor: "yellow"
                            }}>
                        {value && typeof value == 'object' ? value.label : value}</span>
                        } else if (compare === "NO_CHANGES") {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "message",
                label: "Message",
                options: {
                    filter: false,
                    sort: false,
                    display: false
                }
            }
        ],
    };

    componentDidMount() {
        const recordId = this.props.location.pathname.split("/")[3]
        const licensingJobId = this.props.location.pathname.split("/")[4]
        this.getData("licensing/" + recordId + "/offerTableHistory", 0, recordId, licensingJobId);
    }

    compareChanges(type, value, tableMeta) {
        if (type === 'suppressionLiftDate' || type === 'preSell' || type === 'termEnd' || type === 'termStart' || type === 'dateTaken' || type === 'ocad') {
            if (tableMeta.rowIndex < tableMeta.currentTableData.length - 1) {
                var nextDate = tableMeta.currentTableData[tableMeta.rowIndex + 1].data[this.returnIndexColumn(type)]
                var nextRecordDate = (nextDate !== null) ? Moment(Moment(nextDate).local().format(localFormat)).format('DD-MMM-YYYY') : null
                var valueDate = (value !== null) ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : null
                return (nextRecordDate === valueDate) ? "NO_CHANGES" : (nextRecordDate !== null && (valueDate === null || valueDate === "")) ? "REMOVED" :
                    ((nextRecordDate === null || nextRecordDate === "") && valueDate !== null) ? "ADDED" : "CHANGED"
            }
        } else if (tableMeta.rowIndex < tableMeta.currentTableData.length - 1) {
            var nextRecord = tableMeta.currentTableData[tableMeta.rowIndex + 1].data[this.returnIndexColumn(type)];
            if (typeof value === 'string') value = value.toLowerCase()
            if (typeof nextRecord === 'string') nextRecord = nextRecord.toLowerCase()
            return (nextRecord === value) ? "NO_CHANGES" : (nextRecord !== null && (value === null || value === "")) ? "REMOVED" :
                ((nextRecord === null || nextRecord === "") && value !== null) ? "ADDED" : "CHANGED"
        }
        return "NO_CHANGES"
    }

    returnIndexColumn(columnName) {
        var index = 0;
        var indexColumn = 0;
        this.state.columns.forEach(column => {
            if (column.name === columnName) {
                indexColumn = index;
                return
            }
            index++;
        });
        return indexColumn;
    }

    async fetchList(url, page, perPage) {
        return myDataProvider.getList(url, {
            filter: {},
            sort: {field: 'id', order: 'DESC'},
            pagination: {page: page, perPage: perPage},
        }).then(response => {
            return response
        })
            .catch(({status, statusText, headers, body}) => {
                return []
            });
    }

    getData = async (url, page, recordId, licensingJobId) => {
        const isEpisodeFeatureFlagEnabled = AuthProvider.isFlagEnabled('TAP_OFFER_EPISODE_TIER_AND_EPISODE_EMA_PRICE_TYPE');
        const mediaRes = await this.licencingMTLRequest("licensing/getMediaTerritoryList/media");
        const territoryRes = await this.licencingMTLRequest("licensing/getMediaTerritoryList/territory");
        const languageRes = await this.licencingMTLRequest("licensing/getMediaTerritoryList/language");
        const formatRes = await this.licencingMTLRequest("lists/format");
        const currencyRes = await this.licencingMTLRequest("lists/currency");
        const res = await this.xhrRequest(url, page);
        this.setState({
            data: res.data ? res.data : [],
            count: res.data ? res.data.length : [],
            recordId: recordId,
            licensingJobId: licensingJobId,
            medias: mediaRes.data ? mediaRes.data : [],
            territories: territoryRes.data ? territoryRes.data : [],
            languages: languageRes.data ? languageRes.data : [],
            formats: formatRes.data ? formatRes.data : [],
            currencies: currencyRes.data ? currencyRes.data : [],
            //sortOrder : 'historyCreatedAt',
            catalogOptions: [],
            dealOptions: [],
            isLoading: false,
            isEpisodeFeatureFlagEnabled: isEpisodeFeatureFlagEnabled ? isEpisodeFeatureFlagEnabled : false,
            publishStatusOptions: ["Yes", "No"],
            offerStatusOptions: ["Offered", "Taken", "Pass", "Put", "Cancelled"],
            rateCardTypeOptions: ["Rev Share", "Rev Share %", "Rev Share %+", "Non-Rev Share", "VOD PPV", "VOD PPV %", "Hotel", "Rev Share % B", "Rev Share +"],
            emaPriceTypeOptions: ["Category", "License Fee", "TPR-License Fee", "WSP", "Min Deemed Price", "Tier"],
            episodeEmaPriceTypeOptions: ["Category", "Min Deemed Price", "Tier", "Season Only"],
            viewingPeriodOptions: ["24 hours", "24,48 hours", "24,48,72 hours", "Per Week", "Unlimited"],
            windowsTextWithPresell: ["Pre-Sell", "Catalog (EHVL)", "New Release (EHVL)", "New to Catalog (EHVL)", "TVOD Current", "TVOD Library"],
            availsTypeOverrideOptions: ["Feature", "TV"]
        });
    };

    licencingMTLRequest = async (url) => {
        return await this.fetchList(url, 0, 10000)
    };

    sort = (page, sortOrder, recordId) => {
        this.xhrRequest("licensing/" + recordId + "/offerTable", page, sortOrder).then(res => {
            this.setState({
                data: res.data,
                page: res.page,
                sortOrder,
                count: res.total,
            });
        });
    };

    xhrRequest = async (url, page, sortOrder = {}) => {
        return await this.fetchList(url, page, 10000)
    };

    changePage = (page, sortOrder, recordId) => {
        this.xhrRequest("licensing/" + recordId + "/offerTable", page, sortOrder).then(res => {
            this.setState({
                page: res.page,
                sortOrder,
                data: res.data,
                count: res.total,
            });
        });
    };

    formatTapOfferStatus(status) {
        switch (status) {
            case 'PENDING':
                return 'Pending';
            case 'PUSHED':
                return 'Pushed';
            case 'OFFER_IN_PAST_SYSTEM_UPDATED':
                return 'Pushed';
            case 'DUPLICATE':
                return 'Duplicate';
            case 'DUPLICATE_DIFFJOB':
                return 'Duplicate';
            case 'SYSTEM_UPDATED':
                return 'System Updated';
            case 'USER_MODIFIED':
                return 'User Modified';
            case 'SYSTEM_UPDATED_OFFER_ACTIVE':
                return 'Offer Active - System Updated';
            case 'USER_MODIFIED_OFFER_ACTIVE':
                return 'Offer Active - User Modified';
            case 'INACTIVE_DEAL':
                return 'Inactive Deal';
            case 'BLANK_DEAL_FIELDS':
                return 'Blank Deal Fields';
            case 'RIGHTS_ISSUE':
                return 'Rights Issue';
            case 'MISSING_PRICING':
                return 'Missing Pricing';
            case 'TO_DELETE':
                return 'To Delete';
            case 'SYSTEM_CREATED':
                return 'System Created';
            case 'INFO_RESTRICTIONS':
                return 'Info Restrictions';
            default:
                return status;
        }
    }

    render() {
        const {data, columns, count, rowsPerPage, sortOrder, isLoading, isEpisodeFeatureFlagEnabled} = this.state;
        const validTapOfferEpisodes = isEpisodeFeatureFlagEnabled ? ["episodeTier", "episodeEmaPriceType"] : []
        const updatedColumns = featureFlagsDataColumn(data, columns, validTapOfferEpisodes,
            validTapOfferEpisodes);
        const options = {
            filter: true,
            filterType: 'dropdown',
            responsive: 'vertical',
            serverSide: false,
            count: count,
            rowsPerPage: rowsPerPage,
            rowsPerPageOptions: [],
            sort: false,
            sortOrder: sortOrder,
            selectableRows: 'none',
            expandableRows: true,
            renderExpandableRow: (rowData, rowMeta) => {
                const colSpan = rowData.length + 1;
                return (
                    <TableRow>
                        <TableCell
                            colSpan={colSpan}>{parse(rowData[rowData.length - 1].replaceAll('\n', '<br/>'))}</TableCell>
                    </TableRow>
                );
            },
            isRowExpandable: (dataIndex, expandedRows, rowData) => {
                if (data && data[dataIndex].message)
                    return true
                return false
            },
            expandableRowsHeader: false,
            expandableRowsOnClick: false,
            textLabels: {
                body: {
                    noMatch: "Sorry, no matching available offers found",
                    toolTip: "Sort",
                    columnHeaderTooltip: column => `Sort by ${column.label}`
                },
            }
        };
        return (
            <Fragment>
                <div>
                    <OfferTableListBreadcrumb licensingJobId={this.state.licensingJobId} record={this.state.recordId}/>
                    {isLoading ? <Loading/> :
                        <MUIDataTable
                            data={data}
                            columns={updatedColumns}
                            options={options}/>
                    }
                </div>
            </Fragment>
        );
    }
}

const featureFlagsDataColumn = (data, columns, columnIndexes, columnNames) => {
    const hasValidColumnNames = Array.isArray(columnNames) && columnNames.length > 0;
    const updatedColumns = hasValidColumnNames
        ? offerColumnsFeatureFlags(columns, columnNames)
        : columns;
    return updatedColumns;
};

const offerColumnsFeatureFlags = (data, columnNames) => {
    return data.map(item => {
        const shouldHideColumn = columnNames.includes(item.name);
        return shouldHideColumn
            ? {
                ...item,
                options: {
                    ...item.options,
                    display: true,
                },
            }
            : item;
    });
};

export default OfferTable;
