import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  ColumnState,
  GridApi,
  GridOptions,
  IRowNode,
  RowClassParams,
  SortModelItem,
} from 'ag-grid-community';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import values from 'lodash/values';

import { GridInitialState, GridState } from '../types';
import { DataGridProps } from './DataGrid';

const LAST_CHILD_CONST = 'last-child';

export type DataGridContext = {
  shouldSelectAll: boolean;
  groupSelectionId?: string;
  groupToExpandByDefault?: string | [];
  expandedAutoHeightRows: Array<{
    height: number;
    id: string;
  }>;
};

export const getChildCount = (data: {
  childCount: number;
  count?: number;
}): number => {
  return data.count ?? data.childCount;
};

/**
 * Deselect all rows from grid including server mode
 * @param api
 * @param resetSelectAll
 */
export const deselectAllRows = (api: GridApi, resetSelectAll = false): void => {
  api.deselectAll();
  if (resetSelectAll) {
    setContextValue(api, {
      shouldSelectAll: false,
    });
  }
};

/**
 * Determine if a row node should be included while determining selected rows or total rows
 * @param node
 * @param excludeGroups
 */
const shouldProcessNode = (node: IRowNode, excludeGroups = false): boolean =>
  node.data &&
  node.selectable &&
  ((excludeGroups && !node.group) || !excludeGroups);

/**
 * Select all the grid rows and return them.
 * @param api
 */
export const selectAllRows = <T extends Record<string, unknown>>(
  api: GridApi,
): T[] => {
  const selectedRows: T[] = [];

  api.forEachNode((node: IRowNode) => {
    if (!shouldProcessNode(node)) {
      return;
    }

    if (!node.isSelected()) {
      node.setSelected(true);
    }

    selectedRows.push(node.data);
  });

  return selectedRows;
};

/**
 * Get count of rows including the ones that are not currently visible.
 * This is an alternative to getDisplayedRowCount that returns inconsistent results.
 * @param api
 */
export const hasSelectedAllRows = (api: GridApi): boolean => {
  let rowCount = 0;
  let selectedRowCount = 0;

  api.forEachNode((node: IRowNode) => {
    if (!shouldProcessNode(node)) {
      return;
    }

    rowCount++;

    if (node.isSelected()) {
      selectedRowCount++;
    }
  });

  return selectedRowCount > 0 && rowCount === selectedRowCount;
};

export const dataGridContextInitialValue: DataGridContext = {
  groupSelectionId: undefined,
  groupToExpandByDefault: undefined,
  shouldSelectAll: false,
  expandedAutoHeightRows: [],
};

export const generateGroupId = (groupKeys: string[] = []): string =>
  groupKeys.join('-');

export const getDataGridContext = (gridApi: GridApi): DataGridContext => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getGridOptions(gridApi)?.context || dataGridContextInitialValue
  );
};

const getGridOptions = (gridApi: GridApi): GridOptions =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // NOTE: This is an instance of GridApi already, you ts...
  gridApi?.gridOptionsService?.gridOptions;

export const setContextValue = (
  gridApi: GridApi,
  value: Partial<DataGridContext>,
): void => {
  const currentContext = getDataGridContext(gridApi);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!getGridOptions(gridApi)) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  getGridOptions(gridApi).context = {
    ...currentContext,
    ...value,
  };
};

export const getNodeChildren = (node: IRowNode): IRowNode[] => {
  if (!node.group) {
    return [];
  }

  const subgroupNodes =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    node.childStore?.allRowNodes ??
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (Object.values(node?.childStore?.cache?.nodeIndexMap || {}) as IRowNode[]);

  if (subgroupNodes && Boolean(subgroupNodes.length)) {
    return subgroupNodes;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rowStoreBlocks = node?.childStore?.blocks;

  if (rowStoreBlocks) {
    const nodeChildren: IRowNode[] = [];

    Object.keys(rowStoreBlocks).forEach((blockKey: string) => {
      const rowStoreBlock = rowStoreBlocks[blockKey];
      const rowStoreBlockChildren = Object.keys(rowStoreBlock.allNodesMap).map(
        (nodeKey: string) => rowStoreBlock.allNodesMap[nodeKey],
      );

      nodeChildren.push(...rowStoreBlockChildren);
    });

    return nodeChildren;
  }

  return [];
};

export const selectAndCountParentNodes = (
  rowNode: IRowNode,
  isNodeSelected: boolean,
): number => {
  let shouldSelectParent = false;
  const parentNode = rowNode?.parent;

  let nodeCount = 0;

  if (!parentNode || parentNode?.level === -1) {
    return nodeCount;
  }

  if (isNodeSelected) {
    const groupChildren = getNodeChildren(parentNode);
    shouldSelectParent =
      Boolean(groupChildren.length) &&
      groupChildren.every((child) => child.isSelected());
  }

  if (shouldSelectParent !== parentNode.isSelected()) {
    parentNode.setSelected(shouldSelectParent);
    nodeCount += selectAndCountParentNodes(parentNode, shouldSelectParent) + 1;
  }

  return nodeCount;
};

export const getSelectedParentNodes = (
  rowNode: IRowNode,
  isNodeSelected: boolean,
): string[] => {
  let shouldSelectParent = false;
  const parentNode = rowNode?.parent;

  if (!parentNode || parentNode?.level === -1) {
    return [];
  }
  let selectedNodes = parentNode.id ? [parentNode.id] : [];

  if (isNodeSelected) {
    const groupChildren = getNodeChildren(parentNode);
    shouldSelectParent =
      Boolean(groupChildren.length) &&
      groupChildren.every((child) => child.isSelected());
  }

  if (shouldSelectParent !== parentNode.isSelected()) {
    parentNode.setSelected(shouldSelectParent);
    selectedNodes = [
      ...selectedNodes,
      ...getSelectedParentNodes(parentNode, shouldSelectParent),
    ];
  }
  return selectedNodes;
};

export const selectAndCountChildNodes = (
  rowNode: IRowNode,
  selected: boolean,
  gridApi: GridApi,
): number => {
  let nodeCount = 1;
  const nodeChildren = getNodeChildren(rowNode);

  if (nodeChildren.length > 0) {
    nodeChildren.forEach((childNode) => {
      if (childNode.isSelected() !== selected) {
        childNode.setSelected(selected);
        nodeCount += selectAndCountChildNodes(childNode, selected, gridApi);
      }
    });
  }
  return nodeCount;
};

export const getSelectedChildNodes = (
  rowNode: IRowNode,
  selected: boolean,
  gridApi: GridApi,
): string[] => {
  const nodeChildren = getNodeChildren(rowNode);
  let selectedNodes: string[] = [];

  if (nodeChildren.length > 0) {
    nodeChildren.forEach((childNode) => {
      if (childNode.isSelected() !== selected && childNode.id) {
        childNode.setSelected(selected);
        selectedNodes = [
          ...selectedNodes,
          childNode.id,
          ...getSelectedChildNodes(childNode, selected, gridApi),
        ];
      }
    });
  }
  return selectedNodes;
};

export const selectAndCountGroupNodes = (
  rowNode: IRowNode,
  selected: boolean,
  gridApi: GridApi,
): number => {
  let nodeCount = selectAndCountChildNodes(rowNode, selected, gridApi);

  nodeCount += selectAndCountParentNodes(rowNode, selected);

  return nodeCount;
};

export const getSelectedGroupedNodes = (
  rowNode: IRowNode,
  selected: boolean,
  gridApi: GridApi,
  groupSelectionId?: string,
): string[] => {
  if (groupSelectionId && rowNode.id === groupSelectionId) {
    setContextValue(gridApi, {
      groupSelectionId,
    });
  }

  return [
    ...getSelectedChildNodes(rowNode, selected, gridApi),
    ...getSelectedParentNodes(rowNode, selected),
  ];
};

/**
 * Determine if a column is a ColGroupDef
 * More Info: https://www.ag-grid.com/react-data-grid/column-groups/#column-definitions-vs-column-group-definitions
 * @param column
 */
export const isColGroupDef = (
  column: ColDef | ColGroupDef,
): column is ColGroupDef => 'children' in column;

export const isColDef = (column: ColDef | ColGroupDef): column is ColDef =>
  !isColGroupDef(column);

export const getDefaultRowClass = (
  e: RowClassParams,
  currentValue: string | string[] | undefined,
): string | string[] | undefined => {
  let nextValue = currentValue;
  if (
    (e.node.lastChild && e.node.level > 0 && e.node.parent?.lastChild) ||
    (e.node.parent?.level === 0 && e.node.lastChild)
  ) {
    if (!nextValue) {
      return LAST_CHILD_CONST;
    }

    if (Array.isArray(nextValue)) {
      nextValue.push(LAST_CHILD_CONST);
    } else {
      nextValue = [nextValue, LAST_CHILD_CONST];
    }
  }
  return nextValue;
};

/**
 * Determines if grouping is enabled for at least one grid column based on the columns state and column definition.
 * @param columnState
 * @param columnDefs
 */
export function isGrouping(
  columnState: ColumnState[],
  columnDefs: ColDef[],
): boolean;
/**
 * Determines if grouping is enabled for at least one grid column based on the columns state.
 * @param columnState
 */
export function isGrouping(columnState: ColumnState[]): boolean;
/**
 * Determines if grouping is enabled for at least one grid column.
 * @param columnApi
 */
export function isGrouping(columnApi: ColumnApi): boolean;
export function isGrouping(
  columnApiOrColumnState: ColumnApi | ColumnState[],
  columnDefs: ColDef[] = [],
): boolean {
  const isGroupingThroughColumnDef = columnDefs.some(
    (colDef) => isColDef(colDef) && colDef.rowGroup != undefined,
  );
  const columnStateList: ColumnState[] =
    columnApiOrColumnState instanceof ColumnApi
      ? columnApiOrColumnState.getColumnState()
      : columnApiOrColumnState;

  const isGroupingThroughColumnState = columnStateList?.some(
    (columnState) => columnState.rowGroup,
  );

  return isGroupingThroughColumnDef || isGroupingThroughColumnState;
}

export const getSelectedRowsCount = (
  api: GridApi,
  columnApi: ColumnApi,
  mode: DataGridProps['dataMode'] = 'client',
  totalCount?: number,
): number => {
  const clientSelectedRowsCount = api.getSelectedRows().length;
  const dataGridContext = getDataGridContext(api);
  let selectedRowCount = 0;

  if (mode === 'client' || totalCount === undefined) {
    return clientSelectedRowsCount;
  }

  if (dataGridContext.shouldSelectAll) {
    return totalCount;
  }

  if (!isGrouping(columnApi)) {
    return clientSelectedRowsCount;
  }

  api.getSelectedNodes().forEach((selectedRowNode) => {
    const isGroupNode = selectedRowNode.group;

    const isParentSelected =
      selectedRowNode.parent?.group && selectedRowNode.parent?.isSelected();

    // If a parent node is selected, we already counted its children, so we need move to the next iteration.
    if (isParentSelected) {
      return;
    }

    if (isGroupNode) {
      selectedRowCount = selectedRowCount + getChildCount(selectedRowNode.data);

      return;
    }

    selectedRowCount++;
  });

  return selectedRowCount;
};

export const gridStateToColumnState = (
  gridState: GridInitialState,
): ColumnState[] => {
  const groupColumnState: ColumnState[] =
    gridState?.rowGroup?.groupColIds.map<ColumnState>(
      (colId, rowGroupIndex) => ({
        colId,
        rowGroup: true,
        rowGroupIndex,
      }),
    ) ?? [];

  const sortColumnState: ColumnState[] =
    gridState?.sort?.sortModel.map<ColumnState>((sortModelItem, sortIndex) => ({
      colId: sortModelItem.colId,
      sort: sortModelItem.sort,
      sortIndex,
    })) ?? [];

  const visibilityColumnState: ColumnState[] =
    gridState?.columnVisibility?.hiddenColIds.map<ColumnState>((colId) => ({
      colId,
      hide: true,
    })) ?? [];

  return values(
    merge(
      keyBy(groupColumnState, 'colId'),
      keyBy(sortColumnState, 'colId'),
      keyBy(visibilityColumnState, 'colId'),
    ),
  );
};

/**
 * Get the current state of the grid. Can be used in conjunction with the `initialState` grid prop to save and restore grid state.
 * @param gridApi
 * @param columnApi
 */
export const getGridState = (
  gridApi: GridApi,
  columnApi: ColumnApi,
): GridState => {
  const gridState: GridState = {};

  // Filter State
  const filterModel = gridApi.getFilterModel();

  if (filterModel && Object.keys(filterModel).length > 0) {
    gridState.filter = {
      filterModel,
    };
  }

  const columnStateList = columnApi.getColumnState();
  const hiddenColIds: string[] = [];
  const visibleColIds: string[] = [];
  const sortModel: SortModelItem[] = [];
  const groupColIds: string[] = [];

  columnStateList?.forEach((columnState) => {
    // Column Visibility state
    if (columnState.hide) {
      hiddenColIds.push(columnState.colId);
    } else {
      visibleColIds.push(columnState.colId);
    }

    // Sort State
    if (columnState.sort) {
      // When sortIndex is provided, it determines the order the sorting should be applied, otherwise columns will be
      // added at the end of sortModel array (respecting the order defined in columnApi.getColumnState());
      const sortModelElementIndex = columnState.sortIndex ?? sortModel.length;

      sortModel[sortModelElementIndex] = {
        colId: columnState.colId,
        sort: columnState.sort,
      };
    }

    // Group State
    if (
      columnState.rowGroup ||
      (columnState.rowGroupIndex && columnState.rowGroupIndex >= 0)
    ) {
      const groupColElementIndex = columnState.rowGroupIndex ?? 0;

      groupColIds[groupColElementIndex] = columnState.colId;
    }
  });

  gridState.columnVisibility = { hiddenColIds, visibleColIds };

  if (sortModel.length > 0) {
    gridState.sort = { sortModel };
  }

  if (groupColIds.length > 0) {
    gridState.rowGroup = { groupColIds };
  }

  return gridState;
};
