import { FC, useMemo } from 'react';

import { ICellRendererParams } from 'ag-grid-community';

import Badge from '../../../Badge';
import RenderedCellWithMetadataAndDemotedValue from '../../../StandardTable/Renderers/RenderedCellWithMetadataAndDemotedValue';
import { PrimaryColumn } from '../../../StandardTable/types';

import './GroupRowInnerRenderer.scss';

const PILL_MIN_COUNT_LENGTH = 2;

const GroupRowInnerRenderer: FC<ICellRendererParams & PrimaryColumn> = (
  params,
) => {
  const { node } = params;
  const badgeValue = node?.data?.count || node?.allChildrenCount;
  const shouldShowBadge =
    (params.colDef?.cellRendererParams.showCountBadge ||
      params.showCountBadge) &&
    badgeValue;

  const badgeShape = useMemo(
    () =>
      String(badgeValue).length > PILL_MIN_COUNT_LENGTH ? 'pill' : 'circle',
    [badgeValue],
  );
  return (
    <div className="sb-datagrid_group-row" data-testid="DataGrid_Group_Row">
      <RenderedCellWithMetadataAndDemotedValue
        params={params}
        testId="StandardTable_Field_Primary"
      />

      {shouldShowBadge && (
        <Badge
          shape={badgeShape}
          size="small"
          color="secondary"
          light
          testMetadata={{
            'data-testid': 'DataGrid_Group_Count',
          }}
          {...node?.data?.badgeProps}
        >
          {node?.data?.count ?? node?.allChildrenCount}
        </Badge>
      )}
    </div>
  );
};

export default GroupRowInnerRenderer;
