import * as columns from './columns';

export const offers = () => ({
        default: [
                columns.id,
                columns.tapOfferStatus,
                columns.offerTableRowRlId,
                columns.dealId,
                columns.dealName,
                columns.catalogId,
                columns.catalogName,
                columns.publishStatus,
                columns.offerStatus,
                columns.dateTaken,
                columns.defaultPricingFlag,
                columns.media,
                columns.territory,
                columns.languages,
                columns.format,
                columns.termStart,
                columns.termEnd,
                columns.startDateStatus,
                columns.endDateStatus,
                columns.rating,
                columns.rateCardType,
                columns.rateCardId,
                columns.rateCardName,
                columns.windows,
                columns.windowNum,
                columns.tier,
                columns.licenseFee,
                columns.minDeemedPrice,
                columns.minAdvGuarantee,
                columns.seasonPassAmt,
                columns.pmxPlatform,
                columns.currency,
                columns.percentageSplit,
                columns.emaPriceType,
                columns.viewingPeriod,
                columns.preSell,
                columns.tprProgramName,
                columns.suppressionLiftDate,
                columns.exclusivity,
                columns.availsTypeOverride,
                columns.informationalRestrictions,
        ],
        rollup: [
        ],
});


