import { FC, useMemo } from 'react';

import classNames from 'classnames';

import Image from '../Image';

import './Icon.scss';

import { ICON_URL } from '../_utils/config';

import { FontAwesomePackage, TestMetadata } from '../types';

export interface IconProps {
  /**  Whether the component is from font-awesome (fa) or hosted on AWS (aws)*/
  iconType: 'fa' | 'aws';
  /** Size override for the icon: smaller (12x12), small (16x16), medium (20x20), large (32x32), larger (48x48) */
  size?: 'smaller' | 'small' | 'medium' | 'large' | 'larger' | 'auto';
  /**  The name of the font awesome icon OR an AWS hosted icon */
  iconName: string;
  /** Package used for font awesome icons */
  faPackage?: FontAwesomePackage;
  /** Class name that goes on the icon */
  className?: string;
  /** Label text to display */
  altText?: string;
  /** Label text for hover */
  title?: string;
  /** Sets test id */
  testMetadata?: {
    iconAWSImage?: TestMetadata;
    iconFASpan?: TestMetadata;
  };
  ariaLabel?: string;
  iconAriaLabel?: string;
}

const Icon: FC<IconProps> = ({
  size,
  iconType,
  faPackage = 'fab',
  iconName,
  className,
  altText,
  title,
  testMetadata = {
    iconAWSImage: { 'data-testid': 'aws-icon' },
    iconFASpan: { 'data-testid': 'fa-icon' },
  },
  ariaLabel,
}) => {
  const iconClassNames = useMemo(
    () =>
      classNames(
        'sb-icon',
        {
          'sb-icon__size-auto': size === 'auto',
          'sb-icon__size-smaller': size === 'smaller',
          'sb-icon__size-small': size === 'small',
          'sb-icon__size-medium': size === 'medium',
          'sb-icon__size-large': size === 'large',
          'sb-icon__size-larger': size === 'larger',
        },
        className,
      ),
    [size, className],
  );

  if (iconType === 'aws') {
    return (
      <Image
        className={iconClassNames}
        alt={altText}
        title={title}
        {...testMetadata?.iconAWSImage}
        src={`${ICON_URL}/${iconName}.svg`}
        aria-label={ariaLabel}
      />
    );
  } else {
    return (
      <>
        <span
          {...testMetadata?.iconFASpan}
          className={classNames([
            `${faPackage} ${iconName} ${iconClassNames}`,
            faPackage,
            iconName,
          ])}
          aria-label={ariaLabel ?? altText}
          title={title}
        />
      </>
    );
  }
};

export default Icon;
