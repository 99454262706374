import { CSSProperties, FC, SyntheticEvent, useMemo } from 'react';

import classNames from 'classnames';

import Icon from '../Icon';

import './Badge.scss';

import { BadgeTypes, TestMetadata } from '../types';

export interface BadgeProps {
  /** Class name */
  className?: string;
  /** Style */
  style?: CSSProperties;
  /** Shape type: dot, circle, pill */
  shape?: 'dot' | 'circle' | 'pill' | 'status';
  /** Size: small (height: 24px), medium (height: 32px), large (height: 40px) */
  size?: 'smaller' | 'small' | 'medium' | 'large';
  /** Color: primary, secondary, base, error, success, warning*/
  color?: BadgeTypes;
  /** Sets a light background with dark text  */
  light?: boolean;
  /** Role */
  badgeRole?: string;
  /** Determine if dismissable */
  showClose?: boolean;
  /** Close button function */
  onClose?: (e: SyntheticEvent) => void;
  // Test IDs for sections of Badge
  testMetadata?: TestMetadata;
  /** Width: large is 16ch with ellipsis*/
  width?: 'large' | 'auto';
  /** Current status  */
  title?: string;
  /** Icon */
  iconOnly?: boolean;
}

const Badge: FC<BadgeProps> = ({
  className,
  style,
  shape,
  size,
  color,
  light,
  badgeRole,
  children,
  showClose,
  testMetadata,
  width,
  title,
  iconOnly,
  ...otherProps
}) => {
  const badgeClassNames = useMemo(
    () =>
      classNames(
        'sb-badge',
        {
          'sb-badge_dot': !iconOnly && shape === 'dot',
          'sb-badge_circle': shape === 'circle' || iconOnly,
          'sb-badge_status': !iconOnly && shape === 'status',
          'sb-badge_pill': (!shape && !iconOnly) || shape === 'pill',
          'sb-badge_smaller': size === 'smaller',
          'sb-badge_small': !size || size === 'small',
          'sb-badge_medium': size === 'medium',
          'sb-badge_large': size === 'large',
          'sb-badge_primary': color === 'primary',
          'sb-badge_secondary': color === 'secondary',
          'sb-badge_base': color === 'base',
          'sb-badge_error': color === 'error',
          'sb-badge_success': color === 'success',
          'sb-badge_warning': color === 'warning',
          'sb-badge_active': color === 'active',
          'sb-badge_light': light,
          'sb-badge_closable': showClose,
          'sb-badge_width-auto': width === 'auto',
          'sb-badge_width-large': width === 'large',
          'sb-badge_icon': iconOnly,
        },
        className,
      ),
    [className, color, light, shape, showClose, size, width, iconOnly],
  );

  return (
    <div
      {...otherProps}
      className={badgeClassNames}
      style={style}
      role={badgeRole}
      title={title}
      {...testMetadata}
    >
      {shape === 'status' && (
        <Icon
          className="sb-badge_status-icon"
          iconType="fa"
          faPackage="fas"
          iconName="fa-circle"
          size="smaller"
        />
      )}
      {children}
    </div>
  );
};

export default Badge;
