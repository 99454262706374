import { ComponentProps, forwardRef } from 'react';

import classNames from 'classnames';

import './Image.scss';

import { TestMetadata } from '../types';

type Props = ComponentProps<'img'>;
export type ImageProps = ComponentProps<typeof Image> & {
  testMetadata?: TestMetadata;
};

const Image = forwardRef<HTMLImageElement, Props>(function InnerImage(
  { className, alt = '', ...otherProps },
  ref,
) {
  return (
    <img
      alt={alt}
      className={classNames('sb-image', className)}
      ref={ref}
      {...otherProps}
    />
  );
});

export default Image;
