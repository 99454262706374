import { useMemo } from 'react';

import { ICellRendererParams } from 'ag-grid-community';
import classNames from 'classnames';

import { getMetadataValue } from '../utils';

import { TestMetadata } from '../../../types';
import { PrimaryColumn } from '../../types';

import './CellMetadataText.scss';

export interface CellMetadataTextProps {
  params: ICellRendererParams & PrimaryColumn;
  testMetadata?: TestMetadata;
}

const CellMetadataText: React.FC<CellMetadataTextProps> = ({
  params,
  testMetadata = {
    'data-testid': 'table-cell-metadata-text',
  },
}): JSX.Element => {
  const value: string | undefined = useMemo(
    () => getMetadataValue(params),
    [params],
  );

  return (
    <div
      className={classNames('sb-standard-table_cell_metadata-text', {
        'sb-standard-table_cell_metadata-text_margin': value,
      })}
      title={value}
      data-testid={testMetadata['data-testid']}
    >
      {value}
    </div>
  );
};

export default CellMetadataText;
