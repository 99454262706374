import { FC, ReactNode } from 'react';

import { TippyProps } from '@tippyjs/react';
import classNames from 'classnames';

import Popover, { PopoverProps } from '../Popover/';

import './Tooltip.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';
import 'tippy.js/animations/scale.css';

import { TestMetadata } from '../types';

export interface TooltipProps {
  children: ReactNode;
  tippyProps?: TippyProps;
  className?: string;
  contentClassName?: string;
  tooltipContent: PopoverProps['popoverContent'];
  // Test IDs for component sections
  testMetadata?: TestMetadata;
}

const defaultTippyProps: TippyProps = {
  maxWidth: 'none',
  interactive: true,
  arrow: true,
};

const Tooltip: FC<TooltipProps> = ({
  children,
  className,
  contentClassName,
  tooltipContent,
  tippyProps,
  testMetadata,
}) => {
  return (
    <Popover
      className={classNames('sb-tooltip', className)}
      popoverContent={tooltipContent}
      tippyProps={{ ...defaultTippyProps, ...tippyProps }}
      testMetadata={{ popoverContent: testMetadata }}
      {...testMetadata}
      {...{ contentClassName }}
    >
      {children}
    </Popover>
  );
};

export default Tooltip;
