export const id = {
    field: 'id',
    headerName: 'ID',
    editable: false,
    cellRenderer: 'agGroupCellRenderer',
    checkboxSelection: true,
    headerCheckboxSelection: true,
};


export const tapOfferStatus = {
    field: 'tapOfferStatus',
    headerName: 'TAP Offer Status',
    editable: false
};

export const offerTableRowRlId = {
    field: 'offerTableRowRlId',
    headerName: 'RL Id',
    editable: false
};

export const dealId = {
    field: 'dealId',
    headerName: 'Deal Id',
    editable: false
};

export const dealName = {
    field: 'dealName',
    headerName: 'Deal Name',
    editable: false
};

export const catalogId = {
    field: 'catalogId',
    headerName: 'Catalog Id',
    editable: false
};

export const catalogName = {
    field: 'catalogName',
    headerName: 'Catalog Name',
    editable: false
};
export const publishStatus = {
    field: 'publishStatus',
    headerName: 'Ready To Publish',
};

export const offerStatus = {
    field: 'offerStatus',
    headerName: 'RL Offer Status',
};

export const dateTaken = {
    field: 'dateTaken',
    headerName: 'Date Taken',
};

export const defaultPricingFlag = {
    field: 'defaultPricingFlag',
    headerName: "Default Pricing Flag",
    editable: false
};

export const media = {
    field: 'media',
    headerName: "Media",
    editable: false
};

export const territory = {
    field: 'territory',
    headerName: "Territory",
    editable: false
};

export const languages = {
    field: 'languages',
    headerName: "Languages",
    editable: false
};

export const format = {
    field: 'format',
    headerName: "Format",
    editable: false
};

export const termStart = {
    field: 'termStart',
    headerName: "Term Start",
};

export const termEnd = {
    field: 'termEnd',
    headerName: "Term End",
};

export const startDateStatus = {
    field: 'startDateStatus',
    headerName: "Start Date Status",
};

export const endDateStatus = {
    field: 'endDateStatus',
    headerName: "End Date Status",
};

export const rating = {
    field: 'rating',
    headerName: "Rating",
};

export const rateCardType = {
    field: 'rateCardType',
    headerName: "Rate Card Type",
    editable: false
};

export const rateCardId = {
    field: 'rateCardId',
    headerName: "Rate Card Id",
    editable: false
};

export const rateCardName = {
    field: 'rateCardName',
    headerName: "Rate Card Name",
    editable: false
};
export const windows = {
    field: 'windows',
    headerName: "Windows",
};

export const windowNum = {
    field: 'windowNum',
    headerName: "Window Number",
    editable: false
};

export const tier = {
    field: 'tier',
    headerName: "Tier",
};

export const licenseFee = {
    field: 'licenseFee',
    headerName: "License Fee",
};

export const minDeemedPrice = {
    field: 'minDeemedPrice',
    headerName: "Min Deemed Price",
};

export const minAdvGuarantee = {
    field: 'minAdvGuarantee',
    headerName: "Min Adv Guarantee",
};

export const seasonPassAmt = {
    field: 'seasonPassAmt',
    headerName: "Season Pass Amt",
};

export const pmxPlatform = {
    field: 'pmxPlatform',
    headerName: "Pmx Platform",
    editable: false
};

export const currency = {
    field: 'currency',
    headerName: "Currency",
    editable: false
};

export const percentageSplit = {
    field: 'percentageSplit',
    headerName: "Percentage split",
};

export const emaPriceType = {
    field: 'emaPriceType',
    headerName: "EMA Price Type",
    editable: false
};

export const viewingPeriod = {
    field: 'viewingPeriod',
    headerName: "Viewing Period",
    editable: false
};

export const preSell = {
    field: 'preSell',
    headerName: "Pre Sell",
};

export const tprProgramName = {
    field: 'tprProgramName',
    headerName: "TPR Program Name",
};
export const suppressionLiftDate = {
    field: 'suppressionLiftDate',
    headerName: "Suppression Lift Date",
};

export const exclusivity = {
    field: 'exclusivity',
    headerName: "Exclusivity",
    editable: false
};

export const availsTypeOverride = {
    field: 'availsTypeOverride',
    headerName: "Avails Type Override",
};

export const informationalRestrictions = {
    field: 'informationalRestrictions',
    headerName: "Informational Restrictions",
    editable: false
};