import DataGrid from '@dtci/spellbook/DataGrid';
import React from 'react';
import styles from './ag-table.module.scss';
import * as columnDefs from './columns-def';
import {DetailsRow} from "./details-row";
import {LicenseManager} from "ag-grid-enterprise";

LicenseManager.setLicenseKey('CompanyName=Zones LLC_on_behalf_of_Disney Worldwide Shared SR,LicensedGroup=Disney-CPE,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=2,AssetReference=AG-034073,SupportServicesEnd=31_January_2024_[v2]_MTcwNjY1OTIwMDAwMA==cca4bf4e5146e359f30d98519d653f14');


export const AgTable = ({dataType, agGridRef,rowData}) => {

    return (
        <DataGrid
            className={styles.dataGrid}
            agGrid={{
                ref: agGridRef,
                rowData,
                headerHeight: 45,
                rowSelection: 'multiple',
                detailCellRenderer: DetailsRow(dataType),
                detailRowAutoHeight: true,
                masterDetail: true,
            }}
            bordered="none"
            columnDefs={
                columnDefs[dataType]()['default']
            }
            editable
            filterable
            sortable
            moveableColumns
            autoSizing={false}
        />
    );
};
