import React from 'react';

export const useAgGridApi = () => {
    /**
     * @type {React.MutableRefObject<import('ag-grid-react').AgGridReact>}
     */
    const AgGridApiRef = React.useRef();
    const [isMasterRowsExpanded, setIsExpanded] = React.useState(false);

    const getSelectedRows = React.useCallback(() => {
        if (!AgGridApiRef.current) return;
        const { api } = AgGridApiRef.current;
        return api.getSelectedRows();
    }, [AgGridApiRef]);

    const _toggleRows = (toggleTo) => () => {
        try {
            if (AgGridApiRef.current != null) {
                const { api } = AgGridApiRef.current;

                if (api) {
                    api.forEachNode((node) => {
                        node.setExpanded(toggleTo);
                        api.setRowNodeExpanded(node, toggleTo);
                    });

                    api.refreshCells({ force: true });
                }
            }
        } finally {
            setIsExpanded(toggleTo);
        }
    };

    /**
     * Toggles the expansion state of all master rows in the AgGrid table.
     * @function
     * @returns {void}
     */
    const toggleMasterRows = React.useCallback(() => {
        if (isMasterRowsExpanded === true) {
            _toggleRows(false)();
        } else {
            _toggleRows(true)();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AgGridApiRef, isMasterRowsExpanded]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const collapseMasterRows = React.useCallback(_toggleRows(false), [AgGridApiRef]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const expandMasterRows = React.useCallback(_toggleRows(true), [AgGridApiRef]);

    return {
        AgGridApiRef,
        getSelectedRows,
        isMasterRowsExpanded,
        toggleMasterRows,
        collapseMasterRows,
        expandMasterRows,
    };
};
