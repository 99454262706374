import {
  FC,
  HTMLProps,
  ReactChild,
  ReactChildren,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
} from 'react';

import classNames from 'classnames';

import './Checkbox.scss';

import { CheckOption, OptionOnChange, TestMetadata } from '../types';

export interface CheckboxProps {
  /** Custom class name(s) for component container */
  className?: string;
  /** Method to control changing the checkbox */
  onChange?: OptionOnChange;
  /** Check box options such as name and id */
  option: CheckOption;
  /** Disables the checkbox */
  disabled?: boolean;
  /** Value to set the checked state */
  checked?: boolean;
  /** Initial checked value **/
  defaultChecked?: boolean;
  /** Input reference **/
  inputRef?: Ref<HTMLInputElement>;
  /** Additional input props for Input, based on the inputComponent */
  inputProps?: HTMLProps<HTMLInputElement> & TestMetadata;
  /** Error State */
  isError?: boolean;
  // Test IDs for sections of Checkbox
  testMetadata?: TestMetadata;
  // Optional Children
  children?: ReactNode | ReactChildren | ReactChild;
}

const Checkbox: FC<CheckboxProps> = ({
  className,
  option,
  onChange,
  disabled,
  checked,
  defaultChecked,
  inputProps,
  inputRef,
  isError = false,
  testMetadata,
  children,
  ...otherProps
}) => {
  const inputClassNames = useMemo(
    () =>
      classNames(
        'sb-checkbox__input',
        {
          'sb-checkbox_error': isError,
        },
        inputProps?.className,
      ),
    [inputProps, isError],
  );
  const checkboxClassNames = useMemo(
    () =>
      classNames(
        'sb-checkbox',
        className,
        {
          'sb-checkbox_disabled': disabled,
        },
        {
          'sb-checkbox_error': isError,
        },
        {
          'sb-checkbox_has-label': option.label || children,
        },
      ),
    [children, className, disabled, isError, option.label],
  );

  const memoizedOnChange = useCallback(
    () => onChange?.(option.value),
    [onChange, option.value],
  );

  return (
    <div {...otherProps} className={checkboxClassNames}>
      <label className="sb-checkbox__label">
        <input
          type="checkbox"
          name={String(option.name || option.id)}
          id={String(option.id)}
          disabled={disabled}
          checked={checked}
          onChange={memoizedOnChange}
          defaultChecked={defaultChecked}
          role="checkbox"
          ref={inputRef}
          {...testMetadata}
          {...inputProps}
          className={inputClassNames}
        />
        <div className="sb-checkbox__label-content">
          <svg
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
              fill="var(--sbt-color-background)"
            />
          </svg>
          {children ? (
            children
          ) : (
            <>
              <div className="sb-checkbox__label-text">{option.label}</div>
              {option.helpText && (
                <div className="sb-checkbox__helper">{option.helpText}</div>
              )}
            </>
          )}
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
