import {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  IRowNode,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  RowSelectedEvent,
} from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import classNames from 'classnames';

import { ButtonProps } from '../Button';
import { GridInitialState, GridRowHeightSize, TestMetadata } from '../types';
import { DataGridSelectAllHeader } from './Overrides/DataGridSelectAllHeader';
import { GroupRowInnerRenderer } from './Overrides/GroupRowInnerRenderer';

import './DataGrid.theme.scss';
import './DataGrid.scss';

import {
  dataGridContextInitialValue,
  getChildCount,
  getDataGridContext,
  getDefaultRowClass,
  getNodeChildren,
  gridStateToColumnState,
  isColGroupDef,
  isGrouping,
  selectAllRows,
  selectAndCountGroupNodes,
} from './utils';

export interface DataGridProps {
  /** Column Definitions */
  columnDefs: (ColDef | ColGroupDef)[];
  /** Default Col Definitions */
  defaultColumnDef?: ColDef;
  /* Ag-grid properties */
  agGrid?: AgGridReactProps;
  /* Container class name */
  className?: string;
  /* Grid class name */
  gridClassName?: string;
  /* Spellbook theme */
  theme?: string;
  /** Data Grid API ref */
  dataGridApiRef?: MutableRefObject<GridApi | null>;
  /** Data Grid API ref */
  dataGridColumnApiRef?: MutableRefObject<ColumnApi | null>;
  /** Table Borders */
  bordered?: 'horizontal' | 'vertical' | 'rows' | 'all' | 'none';
  /** Striped Rows */
  striped?: boolean;
  /**
   * Whether to use ag-grids server row model.
   */
  dataMode?: 'client' | 'server';
  /**
   * Server row model.
   */
  serverDatasource?: IServerSideDatasource;
  /**
   * Set filtering to on by default (uses agTextColumnFilter unless changed on a per column basis).
   */
  filterable?: boolean;
  /** set the selected rows list */
  onSelectedRowsChanged?: (selectedRows: unknown[]) => void;
  /**
   * Set sorting to on by default (uses a text comparator by default unless changed on a per column basis).
   */
  sortable?: boolean;
  /**
   * Rows are selectable with checkboxes
   */
  selectable?: boolean;
  /**
   * Defines if the row selection is multiple or single.
   */
  selectMode?: 'multiple' | 'single';
  /**
   * Fields are editable
   */
  editable?: boolean;
  /**
   * Cells can be pasted into
   */
  pasteable?: boolean;
  /**
   * Allow columns can be re-arranged via drag & drop (defaults to false)
   */
  moveableColumns?: boolean;
  /**
   * Size of rows
   */
  size?: GridRowHeightSize;
  /**
   * Autoheight using DomLayout property - allows grid to auto-size it's height to fit rows
   */
  autoHeight?: boolean;
  /**
   * Test metadata
   */
  testMetadata?: {
    container?: TestMetadata;
  };
  /**
   * Text to display if no results are found
   */
  noResultsMessage?: string;
  /**
   * If needed, this will provide additional clarification when no results are found
   */
  noResultsDescription?: string;
  /**
   * Button with action to take when no results
   */
  noResultsButtonProps?: ButtonProps;
  /**
   * total row count (server-side)
   */
  totalCount?: number;
  /**
   * is data fetching (server-side)
   */
  isFetching?: boolean;
  /**
   * Auto size columns with flex when width not provided
   */
  autoSizing?: boolean;
  /**
   * Hide child count badge
   */
  hideChildCountBadge?: boolean;
  /**
   * Instance ID of grid
   */
  instanceId?: string;
  /**
   * Set the initial state of the grid. Useful to initialize sorting, grouping. filters or column visibility state.
   */
  initialState?: GridInitialState;
}

const DataGrid: FC<DataGridProps> = ({
  agGrid,
  columnDefs,
  className,
  gridClassName,
  dataGridApiRef,
  dataGridColumnApiRef,
  serverDatasource,
  filterable,
  sortable,
  selectable,
  selectMode = 'multiple',
  editable,
  pasteable,
  moveableColumns = false,
  defaultColumnDef,
  testMetadata,
  dataMode = 'client',
  bordered = 'all',
  striped = false,
  theme = 'ag-theme-sb-data-grid-theme',
  size = 'default',
  autoHeight,
  noResultsMessage = 'No results found',
  noResultsDescription,
  noResultsButtonProps,
  totalCount,
  isFetching,
  autoSizing = true,
  hideChildCountBadge = false,
  instanceId,
  initialState,
}) => {
  const gridApiRef = useRef<GridApi | null>();
  const columnApiRef = useRef<ColumnApi>();
  const isGrouped = useMemo(() => {
    const columnState = initialState
      ? gridStateToColumnState(initialState)
      : [];

    return isGrouping(columnState, columnDefs);
  }, [columnDefs, initialState]);
  const isTree = agGrid?.treeData;
  const [rowDataCount, setRowDataCount] = useState<number | undefined>(
    totalCount,
  );
  const [gridApi, setGridApi] = useState<GridApi>();

  const groupSelectionRef = useRef<{
    nodeCount: number;
    expandedNodes: IRowNode[];
  }>({ nodeCount: 0, expandedNodes: [] });

  const onModelUpdated = useCallback(() => {
    if (!gridApiRef.current) {
      return;
    }

    const context = getDataGridContext(gridApiRef.current);

    if (context.shouldSelectAll) {
      selectAllRows(gridApiRef.current);
    } else {
      const groupedNodeToUpdate = groupSelectionRef.current.expandedNodes.find(
        (groupNode) =>
          groupNode.isSelected() &&
          getNodeChildren(groupNode).every((node) => node.id),
      );
      // Select group node children when more data is fetched and the group node is selected.
      if (groupedNodeToUpdate) {
        const nodeCount = selectAndCountGroupNodes(
          groupedNodeToUpdate,
          true,
          gridApiRef.current,
        );
        const expandedNodes = groupSelectionRef.current.expandedNodes.filter(
          (node) => node.id !== groupedNodeToUpdate.id,
        );
        groupSelectionRef.current = { expandedNodes, nodeCount: nodeCount - 1 };
      }
    }
  }, []);

  // Pre-parse intelligent defaults on columns
  const parsedColumns = useMemo(() => {
    const newColumnDefs: (ColDef | ColGroupDef)[] = [];

    if (selectable) {
      // Check if there is a column pinned to the left
      const hasPinnedLeftColumn = columnDefs.some(
        (column) => 'pinned' in column && column?.pinned === 'left',
      );
      if (dataMode === 'server') {
        newColumnDefs.push({
          cellClass: 'sb-checkbox-grid',
          checkboxSelection: true,
          field: 'checkbox',
          // If its not multi select we need to hide the header checkbox
          headerCheckboxSelection: selectMode === 'multiple',
          headerComponentFramework:
            selectMode === 'multiple' && DataGridSelectAllHeader,
          headerComponentParams: {
            instanceId: `DataGridSelectAllCheckbox-${instanceId}`,
          },
          suppressSizeToFit: true,
          suppressAutoSize: true,
          suppressMovable: true,
          type: 'component',
          width: 57,
          flex: 0,
          resizable: false,
          // Pinned checkboxes to the left if there is a column pinned to the left
          ...(hasPinnedLeftColumn && {
            lockPinned: true,
            pinned: 'left',
          }),
        });
      } else {
        newColumnDefs.push({
          headerClass: 'sb-checkbox-header ag-cell-flex',
          cellClass: 'sb-checkbox-cell ag-cell-no-border ag-cell-flex',
          checkboxSelection: true,
          field: 'checkbox',
          headerCheckboxSelection: selectMode === 'multiple',
          headerName: '',
          suppressSizeToFit: true,
          suppressAutoSize: true,
          type: 'component',
          width: 48,
          flex: 0,
          sortable: false,
          filter: false,
          menuTabs: [],
          lockPosition: true,
          resizable: false,
          suppressMovable: true,
          // Pinned checkboxes to the left if there is a column pinned to the left
          ...(hasPinnedLeftColumn && {
            lockPinned: true,
            pinned: 'left',
          }),
        });
      }
    }

    newColumnDefs.push(
      ...columnDefs.map((column) => {
        if (
          isColGroupDef(column) ||
          column?.width ||
          column?.flex ||
          !autoSizing
        ) {
          return column;
        }

        // Flex setting on fields without explicit sizing to fill remaining space
        return {
          ...column,
          flex: 1,
        };
      }),
    );

    return newColumnDefs;
  }, [selectable, columnDefs, dataMode, selectMode, autoSizing, instanceId]);

  const onRowSelectedChangedHandler = useCallback(
    (e: RowSelectedEvent): void => {
      if (dataMode === 'server' && e.node.id) {
        if (groupSelectionRef.current.nodeCount === 0) {
          groupSelectionRef.current.nodeCount = selectAndCountGroupNodes(
            e.node,
            e.node.isSelected() || false,
            e.api,
          );
        }
        groupSelectionRef.current.nodeCount -= 1;
      }
    },
    [dataMode],
  );

  const getRowHeight = useCallback((): number | undefined => {
    if (size === 'compact') {
      return 32;
    } else if (size === 'large') {
      return 64;
    } else {
      return 48;
    }
  }, [size]);

  // Default options for the data grid
  const gridOptions: AgGridReactProps = useMemo(() => {
    return {
      defaultColDef: {
        // Only show the filter menu by default
        menuTabs: filterable ? ['filterMenuTab'] : [],
        // Don't allow visibility to be updated
        lockVisible: true,
        // Don't allow pinned status to be updated
        lockPinned: true,
        // Allow columns to be resized
        resizable: true,
        // If sortable passed, all columns defaulted to sortable
        sortable: typeof sortable === 'undefined' ? false : sortable,
        // If filterable passed, all columns defaulted to filterable with agTextColumnFilter
        filter:
          typeof filterable === 'undefined' ? false : 'agTextColumnFilter',
        // If editable passed in, all columns are editable by default
        editable: editable || false,
        ...defaultColumnDef,
      },
      context: dataGridContextInitialValue,
      // Lets you copy text from cells
      enableCellTextSelection: true,
      // Ensures the order of the DOM elements matches the order of the rows
      ensureDomOrder: true,
      // Prevents dragging columns within the table
      suppressMovableColumns: !moveableColumns,
      // Prevents hiding columns when dragging them off-screen
      suppressDragLeaveHidesColumns: true,
      // Header cell height
      headerHeight: 48,
      rowHeight: getRowHeight(),
      // Grouped
      ...(isGrouped &&
        !selectable && {
          groupRowRendererParams: {
            // Show the count badge in the grouping row
            innerRenderer: GroupRowInnerRenderer,
            showCountBadge: true,
            // Suppress the built in count, which will be represented in the renderer
            suppressCount: true,
          },
        }),
      ...(isGrouped &&
        selectable && {
          groupRowRendererParams: {
            // Show the count badge in the grouping row
            innerRenderer: GroupRowInnerRenderer,
            showCountBadge: true,
            // Suppress the built in count, which will be represented in the renderer
            suppressCount: true,
            checkbox: selectMode === 'multiple',
          },
          groupSelectsChildren: true,
        }),
      // Server Mode
      ...(dataMode === 'server' && {
        rowModelType: 'serverSide',
        // Loads server side data in blocks (set to true for a complete level fetch)
        suppressServerSideInfiniteScroll: false,
        serverSideInfiniteScroll: true,
      }),
      // Grouped Server
      ...(isGrouped &&
        dataMode === 'server' && {
          getChildCount: getChildCount,
        }),
      ...(selectable && {
        rowSelection: selectMode,
      }),
      // No Results message
      ...(noResultsMessage && {
        overlayNoRowsTemplate: noResultsMessage,
      }),
      // Editable
      ...(editable && {
        singleClickEdit: editable,
      }),
      // Pasteable
      ...(pasteable && {
        enableRangeSelection: true,
        rowSelection: 'multiple',
        singleClickEdit: false,
        enableCellTextSelection: false,
      }),
      rowMultiSelectWithClick: selectMode === 'multiple',
      // Extra rows stored in memory (fetches one block ahead)
      rowBuffer: agGrid?.rowBuffer ?? 50,
      // Block of data
      cacheBlockSize: agGrid?.cacheBlockSize ?? 50,
      // -1 prevents it from tossing old blocks as you scroll (can scroll back to previous data without reloading)
      maxBlocksInCache: -1,
      // Allows skipping ahead in infinite loading
      blockLoadDebounceMillis: agGrid?.blockLoadDebounceMillis ?? 1000,
      // Use rows for groups instead of a grouping column if not in tree mode
      ...(isTree && { groupDisplayType: 'singleColumn' }),
      ...(!isTree && { groupDisplayType: 'groupRows' }),
      // Not clickable
      ...(selectMode !== 'single' && {
        suppressRowClickSelection: true,
      }),
      // No highlight on Master/Detail
      suppressRowHoverHighlight: agGrid?.masterDetail,
      // Always show menu button if available
      suppressMenuHide: true,
      // Set domlayout to auto height
      ...(autoHeight && { domLayout: 'autoHeight' }),
      // Pass undefined for getChildCount if we want to hide it
      ...(isGrouped &&
        hideChildCountBadge && {
          groupRowRendererParams: {
            innerRenderer: undefined,
            suppressCount: true,
          },
          getChildCount: undefined,
        }),
      ...agGrid,
      // We need to have some logic to select the last child presented in a tree for styling of the border
      // This validates that the row is the last child and that the parent is also the last child so we don't get rounded
      // Corners inside the middle of the expansion on some previous child row
      ...(isTree && {
        gridOptions: {
          ...agGrid?.gridOptions,
          getRowClass: (e) => {
            let newValue: string | string[] | undefined = '';
            if (agGrid?.gridOptions?.getRowClass) {
              newValue = agGrid?.gridOptions?.getRowClass(e);
            }

            return getDefaultRowClass(e, newValue);
          },
        },
      }),
    };
  }, [
    filterable,
    sortable,
    editable,
    defaultColumnDef,
    moveableColumns,
    isGrouped,
    selectable,
    selectMode,
    dataMode,
    noResultsMessage,
    pasteable,
    agGrid,
    isTree,
    autoHeight,
    getRowHeight,
    hideChildCountBadge,
  ]);

  // Expose grid API to parent component
  const setDataGridApi = useCallback(
    (api: GridApi, columnApi: ColumnApi): void => {
      if (dataGridApiRef) {
        dataGridApiRef.current = api;
      }

      if (dataGridColumnApiRef) {
        dataGridColumnApiRef.current = columnApi;
      }
    },
    [dataGridApiRef, dataGridColumnApiRef],
  );

  // Hookup API to component
  const setGridReady = useCallback(
    (params: GridReadyEvent): void => {
      gridApiRef.current = params.api;
      columnApiRef.current = params.columnApi;
      setGridApi(params.api);
      setDataGridApi(params.api, params.columnApi);

      /*
      When initialState prop is set, we update the grid column state with the provided ColumnState[]
      and also reset any sort or group at column level that could be present in the current columnDefs prop.
      Once AG Grid version is updated to v31, this implementation will be replaced with AG Grid native prop.
      More Info: https://www.ag-grid.com/react-data-grid/grid-options/#reference-miscellaneous-initialState
     */
      if (
        initialState?.sort ||
        initialState?.columnVisibility ||
        initialState?.rowGroup
      ) {
        params.columnApi.applyColumnState({
          state: gridStateToColumnState(initialState),
          defaultState: {
            sort: null,
            rowGroup: null,
          },
        });
      }
      // This will cause an extra call to serverDatasource.getRows method. Once we upgrade to v31 and use `initialState` prop from
      // AG Grid we can get rid of all this code to initialize grid state.
      if (initialState?.filter) {
        params.api.setFilterModel(initialState.filter.filterModel);
      }

      gridOptions?.onGridReady && gridOptions?.onGridReady(params);

      if (dataMode === 'server' && serverDatasource) {
        const getRows = async (
          params: IServerSideGetRowsParams,
        ): Promise<void> => {
          serverDatasource.getRows(params);
          const { parentNode } = params;
          const { groupKeys, rowGroupCols } = params.request;
          // When grouping and fetching leaf nodes we track this flow by setting
          // groupSelectionRef so after new data is render We update selected rows
          // in onModelUpdated event.
          if (
            rowGroupCols.length > 0 &&
            groupKeys.length > 0 &&
            parentNode.id &&
            parentNode.isSelected()
          ) {
            groupSelectionRef.current.expandedNodes.push(parentNode);
          }
        };
        params.api.setServerSideDatasource({ ...serverDatasource, getRows });
      }
    },
    [dataMode, gridOptions, initialState, serverDatasource, setDataGridApi],
  );

  useEffect(() => {
    if (gridApi) {
      if (dataMode === 'client') {
        setRowDataCount(gridApi.getDisplayedRowCount());
      } else {
        setRowDataCount(totalCount);
      }

      if (
        (dataMode === 'server' && isFetching === false && rowDataCount === 0) ||
        (dataMode === 'client' && rowDataCount === 0)
      ) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  }, [gridApi, isFetching, totalCount, dataMode, gridOptions, rowDataCount]);

  // Class interface
  const containerClassNames = useMemo(
    () =>
      classNames(
        'sb-data-grid',
        {
          'sb-data-grid_stripeless': !striped,
          'sb-data-grid_borderless': bordered === 'none',
          'sb-data-grid_bordered': bordered === 'all',
          ['sb-data-grid_bordered-' + bordered]:
            bordered !== 'all' && !isGrouped && !isTree,
          'sb-data-grid_bordered-horizontal':
            isGrouped || isTree || !!agGrid?.masterDetail,
          'sb-data-grid-grouped': isGrouped || isTree,
          'sb-data-grid-master-detail': !!agGrid?.masterDetail,
          'sb-data-grid-tree': isTree,
          [`sb-data-grid-size_${size}`]: size,
          [`sb-data-grid_selectable-${selectMode}`]: selectMode,
          [`sb-data-grid_data-${dataMode}`]: dataMode,
          'sb-data-grid_pasteable': pasteable,
        },
        theme,
        className,
      ),
    [
      theme,
      className,
      striped,
      bordered,
      isGrouped,
      isTree,
      size,
      agGrid?.masterDetail,
      selectMode,
      dataMode,
      pasteable,
    ],
  );

  return (
    <div className={containerClassNames} {...testMetadata?.container}>
      <AgGridReact
        className={gridClassName}
        {...gridOptions}
        onGridReady={setGridReady}
        columnDefs={parsedColumns}
        noRowsOverlayComponentParams={{
          noRowsMessage: noResultsMessage,
          noRowsDescription: noResultsDescription,
          noRowsButtonProps: noResultsButtonProps,
        }}
        getContextMenuItems={agGrid?.getContextMenuItems}
        onModelUpdated={onModelUpdated}
        onRowSelected={onRowSelectedChangedHandler}
        pinnedTopRowData={agGrid?.pinnedTopRowData}
        pinnedBottomRowData={agGrid?.pinnedBottomRowData}
      />
    </div>
  );
};

export default DataGrid;
