import React, {Fragment} from "react";
import MUIDataTable from "mui-datatables";
import myDataProvider from "../../TAPDataProvider";
import { Link } from "react-router-dom";
import Moment from "moment-timezone";
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { Button, Loading } from 'react-admin';
import IconEdit from '@material-ui/icons/Edit'
import HistoryIcon from '@material-ui/icons/History'
import IconUpload from '@mui/icons-material/FileUpload';
import IconSave from '@material-ui/icons/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import PushOffer from './PushBulkButton'
import DeleteOffer from './DeleteBulkButton'
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import * as Config from '../../Config'
import parse from 'html-react-parser';
import { confirmAlert } from 'react-confirm-alert';
import Chip from '@mui/material/Chip';
import FileUploadView from '../../components/FileUploadPanel'
import * as FileSaver from 'file-saver'
import AuthProvider from '../../TAPAuthProvider';
import FormControl from "@material-ui/core/FormControl";
import { Checkbox, InputLabel, Select } from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import * as ExcelJS from "exceljs";

const OfferTableListBreadcrumb = ({ record }) => {
    return <Fragment>
        <div className="breadcrumbs"><Link to={"/licensing/list"}>Licensing Job </Link> &nbsp;> {record} &nbsp;> Offer
            Table Preview
        </div>
    </Fragment>
};

const localFormat = 'MM/DD/YYYY';

const FileUploadButton = props => {
    return <Fragment>
        <FileUploadView type={'OfferUpdates'} title={'Upload Offer Updates'} />
    </Fragment>
};

class OfferTable extends React.Component {

    state = {
        isAdmin: false,
        medias: [],
        territories: [],
        recordId: 0,
        page: 0,
        count: 0,
        rowsPerPage: localStorage.getItem('offerTableRowsPerPage') ? localStorage.getItem('offerTableRowsPerPage') : 10,
        editRow: {},
        errorMessages: [],
        isLoading: true,
        isProcessingPushDelete: true,
        isEpisodeFeatureFlagEnabled: false,
        subDubLanguages: [],
        data: [],
        columns: [
            {
                name: "id",
                label: " ",
                options: {
                    filter: false,
                    sort: true,
                    download: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.isAdmin) {
                            return <Fragment>
                                <Button style={{ float: 'center', marginLeft: 'center', color: '#F44336', }} label=""
                                    onClick={() => this.deleteTableOfferRL(value, tableMeta, updateValue)}>
                                    <DeleteIcon /></Button></Fragment>
                        } else {
                            return <Fragment></Fragment>
                        }
                    }
                }
            },
            {
                name: "id",
                label: " ",
                options: {
                    filter: false,
                    sort: true,
                    download: false,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '130px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.evaluatePushToRl(tableMeta)) {
                            return <Fragment>
                                <Button label="Push to RL"
                                    onClick={() => this.pushTableOffer(value, tableMeta, updateValue)}>
                                    <IconUpload /></Button></Fragment>
                        } else if (this.evaluateUpdatePushToRl(tableMeta)) {
                            return <Fragment>
                                <Button label="Update Offer"
                                    onClick={() => this.pushTableOffer(value, tableMeta, updateValue)}>
                                    <IconUpload /></Button></Fragment>
                        }
                    }
                }
            },
            {
                name: "createdAt",
                label: "Created",
                options: {
                    filter: false,
                    sort: true,
                    display: false,
                    download: false,
                }
            },
            {
                name: "modifiedAt",
                label: " ",
                options: {
                    filter: false,
                    sort: true,
                    download: false,
                    setCellProps: value => {
                        return {
                            style: {
                                maxWidth: '10px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var offerTableId = tableMeta.currentTableData[tableMeta.rowIndex].data[1]
                        var createdAt = new Date(tableMeta.currentTableData[tableMeta.rowIndex].data[this.returnIndexColumn("createdAt")])
                        var modifiedAt = new Date(tableMeta.currentTableData[tableMeta.rowIndex].data[this.returnIndexColumn("modifiedAt")])
                        var isModifiedAtGTCreatedAt = modifiedAt > createdAt
                        if (isModifiedAtGTCreatedAt) {
                            return <a
                                href={`${'/#/licensing/offerTableHistory/'}${offerTableId}/${this.state.recordId}`}><Button>
                                    <HistoryIcon /></Button></a>
                        } else {
                            return <span></span>
                        }
                    }
                }
            },
            {
                name: "id",
                label: "ID",
                options: {
                    filter: false,
                    sort: true,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '25px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (value === "") {
                            return <span></span>
                        } else {
                            if (this.evaluateIsEditable(tableMeta)) {
                                if (!this.state.editRow[tableMeta.rowIndex]) {
                                    return <Button label={value}
                                        onClick={() => this.setEditMode(value, tableMeta, updateValue)}>
                                        <IconEdit /></Button>
                                } else {
                                    return <Button label={value}
                                        onClick={() => this.hasMultipleEditedRows(tableMeta) ? this.saveTableOffers(value, tableMeta, updateValue)
                                            : this.saveTableSingleOffer(value, tableMeta, updateValue)}>
                                        <IconSave /></Button>
                                }
                            } else {
                                return <span>{value}</span>
                            }
                        }
                    }
                }
            },
            {
                name: "tapOfferStatus",
                label: "TAP Offer Status",
                options: {
                    filter: true,
                    filterOptions: {
                        renderValue: val => {
                            return this.formatTapOfferStatus(val)

                        }
                    },
                    customFilterListOptions: {
                        render: v => {
                            return this.formatTapOfferStatus(v)
                        }
                    },
                    sort: true,
                    display: true,
                    filterType: 'checkbox',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '50px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var display = this.formatTapOfferStatus(value)
                        return <span>{display && typeof display == 'object' ? display.label : display}</span>
                    }
                }
            },
            {
                name: "offerTableRowRlId",
                label: "RL Id",
                options: {
                    filter: false,
                    sort: true,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '25px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && typeof value == 'object' ? value.title : value}</span>
                    }
                }
            },
            {
                name: "licensingJobId",
                label: "Licensing Job Id",
                options: {
                    filter: false,
                    sort: true,
                    display: false
                }
            },
            {
                name: "dealId",
                label: "Deal Id",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '90px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (id, filters, row) => {
                            if (filters.length) return !filters.includes(id);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('dealId');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Deal Id
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedDealIds = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedDealIds, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var url = ""
                        if (process.env.REACT_APP_ENV === '')
                            url = "https://app.rightsline.com/mod4/"
                        if (process.env.REACT_APP_ENV === 'QA')
                            url = "https://app-staging.rightsline.com/mod4/"
                        if (process.env.REACT_APP_ENV === 'QAT')
                            url = "https://app-pm.rightsline.com/mod4/"
                        if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'LOCAL')
                            url = "https://app-int.rightsline.com/mod4/"
                        return <span>{value != null ? <a target="_blank" rel="noopener noreferrer"
                            href={`${url}${value}`}>{value}</a> : ""}</span>
                    }
                }
            },
            {
                name: "dealName",
                label: "Deal Name",
                sort: true,
                required: true,
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '400px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (name, filters, row) => {
                            if (filters.length) return !filters.includes(name);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('dealName');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Deal Name
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedDealNames = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedDealNames, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        /*var handleDealChange = (event) => {
                    this.handleMTLChangeDeal('dealName', event, updateValue, tableMeta)
                  }
                  if (this.state.editRow[tableMeta.rowIndex]) {
                    return <Autocomplete
                        autoHighlight={true}
                        autoSelect={false}
                        clearOnBlur={false}
                        value={typeof value == 'object' && value !== undefined && this.state.dealOptions.filter((option) => option === value)[0] !== undefined ? this.state.dealOptions.filter((option) => option === value)[0] : value !== null && typeof value == 'object' ? value.title : value}
                        onChange={event => handleDealChange(event)}
                        noOptionsText={"No Matching Deal"}
                        getOptionLabel={(option) => typeof option == 'object' && option !== undefined ? option.searchableTitle : typeof value == 'object' ? value.title : value}
                        options={this.state.dealOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Deal *"
                            onChange={event => this.handleDealInputTextChange(value, event, updateValue)}
                            variant="outlined"
                          />
                        )}
                      />
                  } else {*/
                        return <span>{value && typeof value == 'object' ? value.title : value}</span>
                        //}
                    }
                }
            },
            {
                name: "catalogId",
                label: "Catalog Id",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '125px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (id, filters, row) => {
                            if (filters.length) return !filters.includes(id);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('catalogId');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Catalog Id
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedCatalogIds = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedCatalogIds, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var url = ""
                        if (process.env.REACT_APP_ENV === '')
                            url = "https://app.rightsline.com/mod1/"
                        if (process.env.REACT_APP_ENV === 'QA')
                            url = "https://app-staging.rightsline.com/mod1/"
                        if (process.env.REACT_APP_ENV === 'QAT')
                            url = "https://app-pm.rightsline.com/mod1/"
                        if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'LOCAL')
                            url = "https://app-int.rightsline.com/mod1/"
                        return <span>{value != null ? <a target="_blank" rel="noopener noreferrer"
                            href={`${url}${value}`}>{value}</a> : ""}</span>
                    }
                }
            },
            {
                name: "catalogName",
                label: "Catalog Name",
                required: true,
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '400px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (name, filters, row) => {
                            if (filters.length) return !filters.includes(name);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('catalogName');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Catalog Name
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedCatalogNames = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedCatalogNames, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        /*var handleCatalogChange = (event) => {
                    this.handleMTLChangeCatalog('catalog', event, updateValue, tableMeta)
                  }
                  if (this.state.editRow[tableMeta.rowIndex]) {
                    return <Autocomplete
                        autoHighlight={true}
                        autoSelect={false}
                        clearOnBlur={false}
                        value={typeof value == 'object' && value !== undefined && this.state.catalogOptions.filter((option) => option === value)[0] !== undefined ? this.state.catalogOptions.filter((option) => option === value)[0] : value !== null && typeof value == 'object' ? value.title : value}
                        onChange={event => handleCatalogChange(event)}
                        noOptionsText={"No Matching Catalog"}
                        getOptionLabel={(option) => typeof option == 'object' && option !== undefined ? option.searchableTitle : typeof value == 'object' ? value.title : value}
                        options={this.state.catalogOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Catalog *"
                            onChange={event => this.handleCatalogInputTextChange(value, event, updateValue)}
                            variant="outlined"
                          />
                        )}
                      />
                  } else {*/
                        return <span>{value && typeof value == 'object' ? value.title : value}</span>
                        //}
                    }
                }
            },
            {
                name: "readyToPublish",
                label: "Ready to Publish",
                required: true,
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (status, filters, row) => {
                            if (filters.length) return !filters.includes(status);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('readyToPublish');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Ready to Publish
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedPublishStatus = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedPublishStatus, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var handlePublishStatusChange = (event) => {
                            this.handleMTLChange('readyToPublish', event, updateValue)
                        }
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <Autocomplete
                                value={typeof value == 'object' ? value : this.state.publishStatusOptions.filter((option) => option === value)[0]}
                                onChange={event => handlePublishStatusChange(event)}
                                options={this.state.publishStatusOptions}
                                getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        label="Ready to Publish" name="readyToPublish" margin="normal"
                                        variant="outlined" />
                                )}
                            />
                        } else {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "offerStatus",
                label: "RL Offer Status",
                required: true,
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'checkbox',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var handleOfferStatusChange = (event) => {
                            this.handleMTLChange('offerStatus', event, updateValue)
                        }
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <Autocomplete
                                value={typeof value == 'object' ? value : this.state.offerStatusOptions.filter((option) => option === value)[0]}
                                onChange={event => handleOfferStatusChange(event)}
                                options={this.state.offerStatusOptions}
                                getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        label="Offer Status" name="offerStatus" margin="normal"
                                        variant="outlined" />
                                )}
                            />
                        } else {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "dateTaken",
                label: "Date Taken",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '125px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (date, filters, row) => {
                            if (filters.length) return !filters.includes(date);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('dateTaken');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Date Taken
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedDateTaken = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedDateTaken, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                control={<TextField InputLabelProps={{ shrink: true }}
                                    defaultValue={Moment(Moment(value).local().format(localFormat)).format('YYYY-MM-DD')}
                                    type="date" />}
                                onChange={event => updateValue(event.target.value)}
                            />
                        } else {
                            return <span>{value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        }
                    }
                }
            },
            {
                name: "media",
                label: "Media",
                required: true,
                options: {
                    filter: true,
                    filterType: 'checkbox',
                    sort: true,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var handleMediaChange = (event) => {
                            this.handleMTLChange('media', event, updateValue)
                        }
                        // skip format as modifiable field
                        if (false && this.state.editRow[tableMeta.rowIndex]) {
                            const formattedMediaOptions = this.formatOptionsFromServer(this.state.medias);
                            return <Autocomplete
                                value={typeof value == 'object' ? value.label : formattedMediaOptions.filter((option) => option === value)[0]}
                                onChange={event => handleMediaChange(event)}
                                options={formattedMediaOptions}
                                getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        label="Media" name="media" margin="normal" variant="outlined" /> // != null
                                )}
                            />
                        } else {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "territory",
                label: "Territory",
                required: true,
                options: {
                    filter: true,
                    filterType: 'checkbox',
                    sort: true,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var handleTerritoryChange = (event) => {
                            this.handleMTLChange('territory', event, updateValue)
                        }
                        // skip format as modifiable field
                        if (false && this.state.editRow[tableMeta.rowIndex]) {
                            const formattedTerritoryOptions = this.formatOptionsFromServer(this.state.territories);
                            return <Autocomplete
                                value={typeof value == 'object' ? value.label : formattedTerritoryOptions.filter((option) => option === value)[0]}
                                onChange={event => handleTerritoryChange(event)}
                                options={formattedTerritoryOptions}
                                getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        label="Territory" name="territory" margin="normal" variant="outlined" />
                                )}
                            />
                        } else {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "languages",
                label: "Languages",
                required: true,
                options: {
                    filter: true,
                    filterType: 'checkbox',
                    sort: true,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var handleLanguageChange = (event) => {
                            this.handleMTLChange('languages', event, updateValue, value)
                        }
                        var removeOption = (valueToRemove) => {
                            console.log("valueToRemove ", valueToRemove)
                            this.handleLanguageChipRemoval(valueToRemove, updateValue, value)
                        }
                        // skip format as modifiable field
                        if (false && this.state.editRow[tableMeta.rowIndex]) {
                            console.log("language body renderv value ", value)
                            var languageList = []
                            if (typeof value !== 'object') {
                                var allLanguages = this.formatOptionsFromServer(this.state.languages)
                                languageList = value.split("|").map(function (language) {
                                    return allLanguages.filter((option) => option === language)[0]
                                })
                                console.log("languageList ", languageList)
                            } else {
                                languageList = value
                            }


                            return <Autocomplete
                                multiple
                                disableClearable
                                value={languageList}
                                onChange={event => handleLanguageChange(event)}
                                options={this.formatOptionsFromServer(this.state.languages)}
                                getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        label="Language" name="language" margin="normal" variant="outlined" />
                                )}
                                renderTags={(values) =>
                                    values.map((value, index) => (
                                        <Chip
                                            key={index}
                                            label={value && typeof value == 'object' ? value.label : value}
                                            onDelete={() => {
                                                removeOption(value);
                                            }}
                                        />
                                    ))
                                }
                            />
                        } else {
                            return <span>{value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        }
                    }
                }
            },
            {
                name: "format",
                label: "Format",
                options: {
                    filter: true,
                    sort: true,
                    filterType: "checkbox",
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var handleFormatChange = (event) => {
                            this.handleMTLChange('format', event, updateValue)
                        }
                        // skip format as modifiable field
                        if (false && this.state.editRow[tableMeta.rowIndex]) {
                            const formattedFormatOptions = this.formatOptionsFromServer(this.state.formats);
                            return <Autocomplete
                                value={typeof value == 'object' ? value.label : formattedFormatOptions.filter((option) => option === value)[0]}
                                onChange={event => handleFormatChange(event)}
                                options={formattedFormatOptions}
                                getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        label="Format" name="format" margin="normal" variant="outlined" /> // != null
                                )}
                            />
                        } else {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "termStart",
                label: "Term Start",
                required: true,
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '150px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (start, filters, row) => {
                            if (filters.length) return !filters.includes(start);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('termStart');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Term Start
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedTermStart = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedTermStart, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                control={<TextField InputLabelProps={{ shrink: true, required: true }}
                                    defaultValue={Moment(Moment(value).local().format(localFormat)).format('YYYY-MM-DD')}
                                    type="date" />}
                                onChange={event => updateValue(event.target.value)}
                            />
                        } else {
                            return <span>{Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY')}</span>
                        }
                    }
                }
            },
            {
                name: "termEnd",
                label: "Term End",
                required: true,
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '150px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (end, filters, row) => {
                            if (filters.length) return !filters.includes(end);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('termEnd');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Term End
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedTermEnd = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedTermEnd, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                //value={Moment(Moment(value).local().format(localFormat)).format('MM/DD/YYYY')}
                                control={<TextField InputLabelProps={{ shrink: true, required: true }}
                                    defaultValue={Moment(Moment(value).local().format(localFormat)).format('YYYY-MM-DD')}
                                    type="date" />}
                                onChange={event => updateValue(event.target.value)}
                            />
                        } else {
                            return <span>{Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY')}</span>
                        }
                    }
                }
            },
            {
                name: "startDateStatus",
                label: "Start Date Status",
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'checkbox',
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                value={value ? value : ''}
                                control={<TextField type="text" value={value ? value : ''}
                                    onChange={event => updateValue(event.target.value)} />}
                            />
                        } else {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "endDateStatus",
                label: "End Date Status",
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'checkbox',
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                value={value ? value : ''}
                                control={<TextField type="text" value={value ? value : ''}
                                    onChange={event => updateValue(event.target.value)} />}
                            />
                        } else {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "rating",
                label: "Rating",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '160px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (rating, filters, row) => {
                            if (filters.length) return !filters.includes(rating);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('rating');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Rating
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedRatings = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedRatings, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                value={value ? value : ''}
                                control={<TextField type="text" value={value ? value : ''}
                                    onChange={event => updateValue(event.target.value)} />}
                            />
                        } else {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "rateCardType",
                label: "Rate Card Type",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '180px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (type, filters, row) => {
                            if (filters.length) return !filters.includes(type);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('rateCardType');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Rate Card Type
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedRateCardTypes = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedRateCardTypes, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        /*var handleRateCardTypeChange = (event) => {
                    this.handleMTLChange('rateCardType', event, updateValue)
                  }
                  if (this.state.editRow[tableMeta.rowIndex]) {
                    return <Autocomplete
                          value={typeof value == 'object' ? value : this.state.rateCardTypeOptions.filter((option) => option === value)[0] }
                          onChange={event => handleRateCardTypeChange(event)}
                          options={this.state.rateCardTypeOptions}
                          getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                          renderInput={(params) => (
                            <TextField {...params} error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]} helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]} label="Rate Card Type" name="rateCardType" margin="normal" variant="outlined" />
                          )}
                        />
                  } else {*/
                        return <span>{value && value ? value : ''}</span>
                        //}
                    }
                }
            },
            {
                name: "rateCardId",
                label: "Rate Card Id",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '100px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (id, filters, row) => {
                            if (filters.length) return !filters.includes(id);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('rateCardId');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Rate Card Id
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedRateCardIds = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedRateCardIds, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && value ? value : ''}</span>
                    }
                }
            },
            {
                name: "rateCardName",
                label: "Rate Card Name",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '180px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (name, filters, row) => {
                            if (filters.length) return !filters.includes(name);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('rateCardName');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Rate Card Name
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedRateCardNames = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedRateCardNames, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && value ? value : ''}</span>
                    }
                }
            },
            {
                name: "windows",
                label: "Window",
                required: true,
                options: {
                    filter: true,
                    sort: true,
                    filterType: 'checkbox',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var handleWindowChange = (event) => {
                            this.handleMTLChange('window', event, updateValue)
                        }
                        if (false && this.state.editRow[tableMeta.rowIndex]) {
                            const windowsOptions = this.formatOptionsFromServer(this.state.windows);
                            return <Autocomplete
                                value={typeof value == 'object' ? value.label : windowsOptions.filter((option) => option === value)[0]}
                                onChange={event => handleWindowChange(event)}
                                options={windowsOptions}
                                getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        label="Window" name="window" margin="normal" variant="outlined" />
                                )}
                            />
                        } else {
                            return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        }
                    }
                }
            },
            {
                name: "windowNum",
                label: "Window Number",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    filterOptions: {
                        logic: (num, filters, row) => {
                            if (filters.length) return !filters.includes(num);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('windowNum');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Window Number
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedWindowNums = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedWindowNums, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value ? value : ''}</span>
                    }
                }
            },
            {
                name: "tier",
                label: "Tier",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '125px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (tier, filters, row) => {
                            if (filters.length) return !filters.includes(tier);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('tier');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Tier
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedTiers = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedTiers, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            const specialChars = /[^A-Za-z\d\s]/
                            return <FormControlLabel
                                label=""
                                value={value ? value : ''}
                                control={<TextField type="text" value={value ? value : ''}
                                    onChange={event =>{
                                        let maxLength = 20
                                        let changeValue = event.target.value
                                        if (changeValue.length > maxLength) {
                                            changeValue = changeValue.slice(0, maxLength)
                                        }
                                        return (!specialChars.test(changeValue) ? updateValue(changeValue) : '')
                                    } } />}
                            />
                        } else {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "licenseFee",
                label: "License Fee",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '125px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (fee, filters, row) => {
                            if (filters.length) return !filters.includes(fee);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('licenseFee');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        License Fee
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedLicenseFees = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedLicenseFees, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                value={value ? value : ''}
                                control={<NumberFormat decimalScale={2} value={value ? value : ''}
                                    className={`NumberFormat`}
                                    onChange={event => updateValue(event.target.value)} />}
                            />
                        } else {
                            return <span><NumberFormat decimalScale={2} value={value ? value : ''}
                                displayType={'text'} /></span>
                        }
                    }
                }
            },
            {
                name: "minDeemedPrice",
                label: "Min Deemed Price",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '125px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (min, filters, row) => {
                            if (filters.length) return !filters.includes(min);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('minDeemedPrice');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Min Deemed Price
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedMinDeemedPrice = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedMinDeemedPrice, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                value={value ? value : ''}
                                control={<NumberFormat decimalScale={2} value={value ? value : ''}
                                    className={`NumberFormat`}
                                    onChange={event => updateValue(event.target.value)} />}
                            />
                        } else {
                            return <span><NumberFormat decimalScale={2} value={value ? value : ''}
                                displayType={'text'} /></span>
                        }
                    }
                }
            },
            // {
            //     name: "minAdvGuarantee",
            //     label: "Min Adv Guarantee",
            //     options: {
            //         filter: true,
            //         display: 'true',
            //         filterType: 'custom',
            //         setCellProps: value => {
            //             return {
            //                 style: {
            //                     minWidth: '125px',
            //                 },
            //             };
            //         },
            //         filterOptions: {
            //             logic: (min, filters, row) => {
            //                 if (filters.length) return !filters.includes(min);
            //                 return false;
            //             },
            //             display: (filterList, onChange, index, column) => {
            //                 const optionValues = this.formatCustomFilterOptions('minAdvGuarantee');
            //"
            //                 return (
            //                     <FormControl>
            //                         <InputLabel htmlFor='select-multiple-chip'>
            //                             Min Adv Guarantee
            //                         </InputLabel>
            //                         <Select
            //                             multiple
            //                             variant={'standard'}
            //                             value={filterList[index]}
            //                             renderValue={selected => selected.join(', ')}
            //                             onChange={event => {
            //                                 const selectedMinAdvGuar = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
            //                                 onChange(selectedMinAdvGuar, index, column);
            //                             }}
            //                         >
            //                             <MenuItem
            //                                 value="all"
            //                             >
            //                                 <Button
            //                                     label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
            //                                 />
            //                             </MenuItem>
            //                             {optionValues.map(item => (
            //                                 <MenuItem key={item} value={item}>
            //                                     <Checkbox
            //                                         color='primary'
            //                                         checked={filterList[index].indexOf(item) > -1}
            //                                     />
            //                                     <ListItemText primary={item}/>
            //                                 </MenuItem>
            //                             ))}
            //                         </Select>
            //                     </FormControl>
            //                 );
            //             }
            //         },
            //         customBodyRender: (value, tableMeta, updateValue) => {
            //             if (this.state.editRow[tableMeta.rowIndex]) {
            //                 return <FormControlLabel
            //                     label=""
            //                     value={value ? value : ''}
            //                     control={<NumberFormat decimalScale={2} value={value ? value : ''}
            //                                            className={`NumberFormat`}
            //                                            onChange={event => updateValue(event.target.value)}/>}
            //                 />
            //             } else {
            //                 return <span><NumberFormat decimalScale={2} value={value ? value : ''}
            //                                            displayType={'text'}/></span>
            //             }
            //         }
            //     }
            // },
            {
                name: "seasonPassAmt",
                label: "Season Pass Amt",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '125px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (amt, filters, row) => {
                            if (filters.length) return !filters.includes(amt);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('seasonPassAmt');

                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Season Pass Amt
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedSeasonPassAmt = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedSeasonPassAmt, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                value={value ? value : ''}
                                control={<NumberFormat decimalScale={2} value={value ? value : ''}
                                    className={`NumberFormat`}
                                    onChange={event => updateValue(event.target.value)} />}
                            />
                        } else {
                            return <span><NumberFormat decimalScale={2} value={value ? value : ''}
                                displayType={'text'} /></span>
                        }
                    }
                }
            },
            {
                name: "episodeTier",
                label: "Episode Tier",
                require: false,
                options: {
                    filter: true,
                    display: 'false',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '125px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (episodeTier, filters, row) => {
                            if (filters.length) return !filters.includes(episodeTier);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('episodeTier');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Episode Tier
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedTiers = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedTiers, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            const specialChars = /[^A-Za-z\d\s]/
                            return <FormControlLabel
                                label=""
                                value={value ? value : ''}
                                control={<TextField type="text" value={value ? value : ''}
                                    onChange={event => {
                                        let maxLength = 20
                                        let changeValue = event.target.value
                                        if (changeValue.length > maxLength) {
                                            changeValue = changeValue.slice(0, maxLength)
                                        }
                                        return (!specialChars.test(changeValue) ? updateValue(changeValue) : '')
                                    } } />}
                            />
                        } else {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "pmxPlatform",
                label: "Pmx Platform",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '160px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (platform, filters, row) => {
                            if (filters.length) return !filters.includes(platform);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('pmxPlatform');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Pmx Platform
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedPMXPlatforms = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedPMXPlatforms, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value ? value : ''}</span>
                    }
                }
            },
            {
                name: "currency",
                label: "Currency",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (curr, filters, row) => {
                            if (filters.length) return !filters.includes(curr);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('currency');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Currency
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedCurrencies = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedCurrencies, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        // var handleCurrencyChange = (event) => {
                        //   this.handleMTLChange('currency', event, updateValue)
                        // }
                        // if (this.state.editRow[tableMeta.rowIndex]) {
                        //   const formattedCurrencyOptions = this.formatOptionsFromServer(this.state.currencies);
                        //   return <Autocomplete
                        //       value={typeof value == 'object' ? value.label : formattedCurrencyOptions.filter((option) => option === value)[0]}
                        //       onChange={event => handleCurrencyChange(event)}
                        //       options={formattedCurrencyOptions}
                        //       getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                        //       renderInput={(params) => (
                        //           <TextField {...params}
                        //                      error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                        //                      helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                        //                      label="Currency" name="currency" margin="normal" variant="outlined"/>
                        //       )}
                        //   />
                        // } else {
                        return <span>{value && typeof value == 'object' ? value.label : value}</span>
                        //}
                    }
                }
            },
            {
                name: "percentageSplit",
                label: "Percentage split",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    filterOptions: {
                        logic: (perc, filters, row) => {
                            if (filters.length) return !filters.includes(perc);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('percentageSplit');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Percentage split
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedPercentageSplit = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedPercentageSplit, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                value={value ? value : ''}
                                control={<NumberFormat decimalScale={2} value={value ? value : ''} suffix={'%'}
                                    className={`NumberFormat`}
                                    onChange={event => updateValue(event.target.value)} />}
                            />
                        } else {
                            return <span><NumberFormat decimalScale={2} value={value ? value : ''} displayType={'text'}
                                suffix={'%'} /></span>
                        }
                    }
                }
            },
            {
                name: "emaPriceType",
                label: "EMA Price Type",
                required: true,
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (type, filters, row) => {
                            if (filters.length) return !filters.includes(type);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('emaPriceType');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        EMA Price Type
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedPriceTypes = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedPriceTypes, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        // var handleEmaPriceTypeChange = (event) => {
                        //   this.handleMTLChange('emaPriceType', event, updateValue)
                        // }
                        // if (this.state.editRow[tableMeta.rowIndex]) {
                        //   return <Autocomplete
                        //         value={typeof value == 'object' ? value : this.state.emaPriceTypeOptions.filter((option) => option === value)[0] }
                        //         onChange={event => handleEmaPriceTypeChange(event)}
                        //         options={this.state.emaPriceTypeOptions}
                        //         getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                        //         renderInput={(params) => (
                        //           <TextField {...params} error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]} helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]} label="Ema Price Type" name="emaPriceType" margin="normal" variant="outlined" />
                        //         )}
                        //       />
                        // } else {
                        return <span>{value && value ? value : ''}</span>
                        //}
                    }
                }
            },
            {
                name: "episodeEmaPriceType",
                label: "Episode EMA Price Type",
                required: false,
                options: {
                    filter: true,
                    display: 'false',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (type, filters, row) => {
                            if (filters.length) return !filters.includes(type);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('episodeEmaPriceType');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Episode EMA Price Type
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedPriceTypes = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedPriceTypes, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && value ? value : ''}</span>
                    }
                }
            },
            {
                name: "viewingPeriod",
                label: "Viewing Period",
                required: true,
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '160px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (per, filters, row) => {
                            if (filters.length) return !filters.includes(per);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('viewingPeriod');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Viewing Period
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedViewingPeriods = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedViewingPeriods, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        // var handleViewingPeriodChange = (event) => {
                        //   this.handleMTLChange('viewingPeriod', event, updateValue)
                        // }
                        // if (this.state.editRow[tableMeta.rowIndex]) {
                        //   return <Autocomplete
                        //         value={typeof value == 'object' ? value : this.state.viewingPeriodOptions.filter((option) => option === value)[0] }
                        //         onChange={event => handleViewingPeriodChange(event)}
                        //         options={this.state.viewingPeriodOptions}
                        //         getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                        //         renderInput={(params) => (
                        //           <TextField {...params} error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]} helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]} label="Viewing Period" name="viewingPeriod" margin="normal" variant="outlined" />
                        //         )}
                        //       />
                        // } else {
                        return <span>{value && value ? value : ''}</span>
                        //}
                    }
                }
            },
            {
                name: "preSell",
                label: "Pre Sell",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '125px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (pre, filters, row) => {
                            if (filters.length) return !filters.includes(pre);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('preSell');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Pre Sell
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedPreSell = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedPreSell, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                control={<TextField InputLabelProps={{ shrink: true }}
                                    defaultValue={Moment(Moment(value).local().format(localFormat)).format('YYYY-MM-DD')}
                                    type="date" />}
                                onChange={event => updateValue(event.target.value)}
                            />
                        } else {
                            return <span>{value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                        }
                    }
                }
            },
            {
                name: "subLanguage",
                label: "Sub Language",
                options: {
                    filter: true,
                    filterType: 'checkbox',
                    sort: true,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateSubDubValue) => {
                        var handleSubLanguageChange = (event) => {
                            this.handleMTLChange('subLanguage', event, updateSubDubValue, value)
                        }
                        var removeOption = (valueToRemove) => {
                            this.handleSubDubLanguageChipRemoval(valueToRemove, updateSubDubValue, value)
                        }
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            let subLanguageList = [];
                            if(value === "") {
                                value = []
                            }
                            if (typeof value !== 'object') {
                                value = reArrangeSubLanguage(value,tableMeta)
                                const allSubLanguages = this.formatOptionsFromServer(this.state.subDubLanguages);
                                subLanguageList = value.split("|").map(function (sLanguage) {
                                    return allSubLanguages.filter((option) => option === sLanguage)[0]
                                })
                            } else {
                                subLanguageList = value
                            }
                            return <Stack spacing={2} sx={{ width: 400 }}>
                                        <Autocomplete
                                            multiple
                                            disableClearable
                                            value={subLanguageList}
                                            onChange={event => handleSubLanguageChange(event)}
                                            options={this.formatOptionsFromServer(this.state.subDubLanguages)}
                                            getOptionLabel={(option) => option !== undefined ? option : ''}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                           error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                                           helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                                           label="Sub Language" name="subLanguage" margin="normal" variant="outlined"/>
                                            )}
                                            renderTags={(values) =>
                                                values.map((value, index) => {
                                                    const offerTableId = tableMeta.currentTableData[tableMeta.rowIndex].data[1];
                                                    let subLanguageLastSelected = validateParsedValue(JSON.parse(localStorage.getItem('subDubLanguageLastSelected'))) ?
                                                        JSON.parse(localStorage.getItem('subDubLanguageLastSelected')) : []
                                                    let type = false
                                                    if(subLanguageLastSelected.length > 0) {
                                                        const sLanguageLastSelected = subLanguageLastSelected
                                                            .find(item => item && parseInt(item.id) === parseInt(offerTableId))
                                                        const slValue = value && typeof value == 'object' ? value.label : value;
                                                        if(sLanguageLastSelected) {
                                                            type = slValue === sLanguageLastSelected.subLanguage
                                                        } else {
                                                            setLocalStorageSubDubLanguagesLastSelected(subLanguageLastSelected, offerTableId,
                                                                getLastSelectedValue(subLanguageList), "")
                                                        }
                                                    }
                                                    return <Chip
                                                        style={{
                                                            backgroundColor: type?'yellow':''
                                                        }}
                                                        key = {index}
                                                        label = {value && typeof value == 'object' ? value.label : value}
                                                        variant = "outlined"
                                                        onDelete = {() => {
                                                            removeOption(value);
                                                        }}
                                                    />
                                                })
                                            }
                                        />
                                </Stack>
                        } else {
                            return <span>{value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        }
                    }
                }
            },
            {
                name: "dubLanguage",
                label: "Dub Language",
                options: {
                    filter: true,
                    filterType: 'checkbox',
                    sort: true,
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                            },
                        };
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var handleDubLanguageChange = (event) => {
                            this.handleMTLChange('dubLanguage', event, updateValue, value)
                        }
                        var removeOption = (valueToRemove) => {
                            this.handleSubDubLanguageChipRemoval(valueToRemove, updateValue, value)
                        }
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            var dubLanguageList = []
                            if(value === "") {
                                value = []
                            }
                            if (typeof value !== 'object') {
                                value = reArrangeDubLanguage(value,tableMeta)
                                var allDubLanguages = this.formatOptionsFromServer(this.state.subDubLanguages)
                                dubLanguageList = value.split("|").map(function (dLanguage) {
                                    return allDubLanguages.filter((option) => option === dLanguage)[0]
                                })
                            } else {
                                dubLanguageList = value
                            }

                            return <Stack spacing={2} sx={{ width: 400 }}>
                                <Autocomplete
                                    multiple
                                    disableClearable
                                    value={dubLanguageList}
                                    onChange={event => handleDubLanguageChange(event)}
                                    options={this.formatOptionsFromServer(this.state.subDubLanguages)}
                                    getOptionLabel={(option) => option !== undefined ? option : ''}
                                    renderInput={(params) => {
                                        return <TextField {...params}
                                                          error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                                          helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                                          label="Dub Language" name="dublanguage" margin="normal" variant="outlined"
                                        />
                                    }}
                                    renderTags={(values) =>
                                            values.map((value, index) => {
                                                const offerTableId = tableMeta.currentTableData[tableMeta.rowIndex].data[1];
                                                let dubLanguageLastSelected = validateParsedValue(JSON.parse(localStorage.getItem('subDubLanguageLastSelected'))) ?
                                                    JSON.parse(localStorage.getItem('subDubLanguageLastSelected')) : []
                                                let type = false
                                                if (dubLanguageLastSelected.length > 0) {
                                                    const dLanguageLastSelected = dubLanguageLastSelected
                                                        .find(item => item && parseInt(item.id) === parseInt(offerTableId))
                                                    const slValue = value && typeof value == 'object' ? value.label : value;
                                                    if(dLanguageLastSelected) {
                                                        type = slValue === dLanguageLastSelected.dubLanguage
                                                    } else {
                                                        setLocalStorageSubDubLanguagesLastSelected(dubLanguageLastSelected,
                                                            offerTableId,"", getLastSelectedValue(dubLanguageList))
                                                    }
                                                }
                                                return <Chip
                                                    style={{
                                                        backgroundColor: type?'yellow':''
                                                    }}
                                                    key={index}
                                                    label={value && typeof value == 'object' ? value.label : value}
                                                    variant="outlined"
                                                    onDelete={() => {
                                                        removeOption(value);
                                                    }}
                                                />
                                            })
                                    }
                                />
                            </Stack>
                        } else {
                            return <span>{value && typeof value == 'object' ? value.label : value.replaceAll("|", " | ")}</span>
                        }
                    }
                }
            },
            {
                name: "tprProgramName",
                label: "TPR Program Name",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '160px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (name, filters, row) => {
                            if (filters.length) return !filters.includes(name);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('tprProgramName');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        TPR Program Name
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedProgramNames = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedProgramNames, index, column);
                                        }}
                                    >
                                        <MenuItem value="all">
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <FormControlLabel
                                label=""
                                value={value ? value : ''}
                                control={<TextField type="text" value={value ? value : ''}
                                    onChange={event => updateValue(event.target.value)} />}
                            />
                        } else {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "ocad",
                label: "Consumer Announcement Date",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '125px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (date, filters, row) => {
                            if (filters.length) return !filters.includes(date);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('ocad');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Consumer Announcement Date (OCAD)
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedLiftDate = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedLiftDate, index, column);
                                        }}
                                    >
                                        <MenuItem value="all">
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                    }
                }
            },
            {
                name: "suppressionLiftDate",
                label: "Suppression Lift Date",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '125px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (date, filters, row) => {
                            if (filters.length) return !filters.includes(date);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('suppressionLiftDate');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Suppression Lift Date
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedLiftDate = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedLiftDate, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value ? Moment(Moment(value).local().format(localFormat)).format('DD-MMM-YYYY') : ''}</span>
                    }
                }
            },
            {
                name: "exclusivity",
                label: "Exclusivity",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    filterOptions: {
                        logic: (ex, filters, row) => {
                            if (filters.length) return !filters.includes(ex);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('exclusivity');

                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Exclusivity
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedExclusivity = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedExclusivity, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && value ? value : ''}</span>
                    }
                }
            },
            {
                name: "holdbackLanguages",
                label: "Holdback Languages",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '200px',
                                maxWidth: '350px',
                                whiteSpace: 'pre-line'
                            },
                        };
                    },
                    filterOptions: {
                        logic: (date, filters, row) => {
                            if (filters.length) return !filters.includes(date);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('holdbackLanguages');
                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Holdback Languages
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedLiftDate = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedLiftDate, index, column);
                                        }}
                                    >
                                        <MenuItem value="all">
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && value ? value.replaceAll("|", " | ") : ''}</span>
                    }
                }
            },
            {
                name: "languageAvailsDisplay",
                label: "Language Avails Display",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '128px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (value, filters, row) => {
                            if (filters.length) return !filters.includes(value);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('languageAvailsDisplay');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Language Avails Display
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedLanguageAvailsDisplay = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedLanguageAvailsDisplay, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value && value ? value : ''}</span>
                    }
                }
            },
            {
                name: "availsTypeOverride",
                label: "Avails Type Override",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '140px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (type, filters, row) => {
                            if (filters.length) return !filters.includes(type);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('availsTypeOverride');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Avails Type Override
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedAvailTypeOverride = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedAvailTypeOverride, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        var handleAvailsTypeOverrideChange = (event) => {
                            this.handleMTLChange('availsTypeOverride', event, updateValue)
                        }
                        if (this.state.editRow[tableMeta.rowIndex]) {
                            return <Autocomplete
                                value={typeof value == 'object' ? value : this.state.availsTypeOverrideOptions.filter((option) => option === value)[0]}
                                onChange={event => handleAvailsTypeOverrideChange(event)}
                                options={this.state.availsTypeOverrideOptions}
                                getOptionLabel={(option) => option !== undefined && option !== undefined ? option : ''}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        error={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        helperText={tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] && tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][tableMeta.columnData.name]}
                                        label="Avails Type Override" name="availsTypeOverride" margin="normal"
                                        variant="outlined" />
                                )}
                            />
                        } else {
                            return <span>{value && value ? value : ''}</span>
                        }
                    }
                }
            },
            {
                name: "informationalRestrictions",
                label: "Informational Restrictions",
                options: {
                    filter: true,
                    display: 'true',
                    filterType: 'custom',
                    setCellProps: value => {
                        return {
                            style: {
                                minWidth: '160px',
                            },
                        };
                    },
                    filterOptions: {
                        logic: (res, filters, row) => {
                            if (filters.length) return !filters.includes(res);
                            return false;
                        },
                        display: (filterList, onChange, index, column) => {
                            const optionValues = this.formatCustomFilterOptions('informationalRestrictions');


                            return (
                                <FormControl>
                                    <InputLabel htmlFor='select-multiple-chip'>
                                        Informational Restrictions
                                    </InputLabel>
                                    <Select
                                        multiple
                                        variant={'standard'}
                                        value={filterList[index]}
                                        renderValue={selected => selected.join(', ')}
                                        onChange={event => {
                                            const selectedInfoRest = this.handleCustomFilter(event.target.value, optionValues, filterList[index])
                                            onChange(selectedInfoRest, index, column);
                                        }}
                                    >
                                        <MenuItem
                                            value="all"
                                        >
                                            <Button
                                                label={(filterList[index].length === optionValues.length && optionValues.length > 0) ? 'Unselect All' : 'Select All'}
                                            />
                                        </MenuItem>
                                        {optionValues.map(item => (
                                            <MenuItem key={item} value={item}>
                                                <Checkbox
                                                    color='primary'
                                                    checked={filterList[index].indexOf(item) > -1}
                                                />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return <span>{value ? value : ''}</span>
                    }
                }
            },
            {
                name: "message",
                label: "Message",
                options: {
                    filter: false,
                    sort: false,
                    display: false
                }
            }
        ],
    };

    handleMTLChangeCatalog(type, event, updateValue, tableMeta) {
        var selectedValue = ""
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0) {
            selectedValue = ""
        } else {
            if (type === 'catalog') {
                var catalogSelected = this.state.catalogOptions.filter((option) => option.searchableTitle === event.target.innerText)
                selectedValue = catalogSelected[0]
                tableMeta.currentTableData[tableMeta.rowIndex].data[this.returnIndexColumn("catalogId")] = selectedValue.id
            }
        }
        console.log("selectedValue ", selectedValue)
        updateValue(selectedValue)
    }

    handleMTLChangeDeal(type, event, updateValue, tableMeta) {
        var selectedValue = ""
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0) {
            selectedValue = ""
        } else {
            if (type === 'dealName') {
                var dealSelected = this.state.dealOptions.filter((option) => option.searchableTitle === event.target.innerText)
                selectedValue = dealSelected[0]
                tableMeta.currentTableData[tableMeta.rowIndex].data[this.returnIndexColumn("dealId")] = selectedValue.id
            }
        }
        console.log("selectedValue ", selectedValue)
        updateValue(selectedValue)
    }

    handleLanguageChipRemoval(itemToRemove, updateValue, currentValue) {
        console.log("itemToRemove ", itemToRemove)
        console.log("currentValue ", currentValue)
        var languages = []
        if (currentValue && typeof currentValue !== 'object') {
            var allLanguages = this.state.languages
            languages = currentValue.split("|").filter((language) => language !== itemToRemove).map(function (language) {
                return allLanguages.filter((option) => option.label === language)[0]
            })
        } else if (currentValue) {
            languages = currentValue.filter((option) => option.label !== itemToRemove)
        }
        console.log("languages ", languages)
        updateValue(languages)
    }

    handleSubDubLanguageChipRemoval(itemToRemove, updateSubDubValue, currentValue) {
        var subDubLanguages = []
        if (currentValue && typeof currentValue !== 'object') {
            var allSubDubLanguages = this.state.subDubLanguages
            subDubLanguages = currentValue.split("|")
                .filter((sdLanguage) => sdLanguage !== itemToRemove)
                .map(function (sdLanguage) {
                    return allSubDubLanguages.filter((option) => option.label === sdLanguage)[0]
                })
        } else if (currentValue) {
            subDubLanguages = currentValue.filter((option) => option.label !== itemToRemove.label)
        }
        updateSubDubValue(subDubLanguages)
    }

    handleMTLChange(type, event, updateValue, currentValue) {
        var selectedValue = ""
        console.log("handleMTLChange " + type + " event " + event + " updateValue " + updateValue + " currentValue " + currentValue)
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0) {
            selectedValue = ""
        } else {
            if (type === 'media') {
                var mediaSelected = this.state.medias.filter((option) => option.label === event.target.innerHTML)
                selectedValue = mediaSelected[0]
            }
            if (type === 'languages') {
                var languages = []
                if (currentValue && typeof currentValue !== 'object') {
                    var allLanguages = this.state.languages
                    languages = currentValue.split("|").map(function (language) {
                        return allLanguages.filter((option) => option.label === language)[0]
                    })
                } else if (currentValue) {
                    languages = currentValue
                }
                var newLanguageSelected = this.state.languages.filter((option) => option.label === event.target.innerHTML)[0]
                if (newLanguageSelected)
                    languages.push(newLanguageSelected)
                console.log("languages 2 ", languages)
                selectedValue = languages
            }

            if (type === 'subLanguage' || type === 'dubLanguage') {
                var subDubLanguage = []
                if (currentValue && typeof currentValue !== 'object') {
                    var allSubDubLanguages = this.state.subDubLanguages
                    subDubLanguage = currentValue.split("|").map(function (sdLanguage) {
                        return allSubDubLanguages.filter((option) => option.label === sdLanguage)[0]
                    })
                } else if (currentValue) {
                    subDubLanguage = currentValue
                }
                var newSubDubLanguageSelected = this.state.subDubLanguages.filter((option) => option.label === event.target.innerHTML)[0]
                if (newSubDubLanguageSelected)
                    subDubLanguage.push(newSubDubLanguageSelected)
                const uniqueLanguages = Array.from(new Set(subDubLanguage.map(language => language.id)))
                    .map(id => {
                        return subDubLanguage.find(language => language.id === id);
                    });
                const hasAllLanguages = uniqueLanguages.some(language => parseInt(language.id) === 4);
                const filteredLanguages = hasAllLanguages ?
                    uniqueLanguages.filter(language => language.id === 4) : uniqueLanguages;
                selectedValue = filteredLanguages
            }
            if (type === 'territory') {
                console.log(event.target.innerHTML)
                var territorySelected = this.state.territories.filter((option) => option.label === event.target.innerHTML.replace('&amp;', '&'))
                selectedValue = territorySelected[0]
            }
            if (type === 'format') {
                var formatSelected = this.state.formats.filter((option) => option.label === event.target.innerHTML)
                selectedValue = formatSelected[0]
            }
            if (type === 'currency') {
                console.log(event.target.innerHTML)
                var currencySelected = this.state.currencies.filter((option) => option.label === event.target.innerHTML)
                selectedValue = currencySelected[0]
            }
            if (type === 'window') {
                var windowSelected = this.state.windows.filter((option) => option.label === event.target.innerHTML)
                selectedValue = windowSelected[0]
            }
            if (type === 'offerStatus') {
                var offerStatusSelected = this.state.offerStatusOptions.filter((option) => option === event.target.innerHTML)
                selectedValue = offerStatusSelected[0]
            }

            if (type === 'emaPriceType') {
                var emaPriceTypeSelected = this.state.emaPriceTypeOptions.filter((option) => option === event.target.innerHTML)
                selectedValue = emaPriceTypeSelected[0]
            }
            if (type === 'episodeEmaPriceType') {
                var episodeEmaPriceTypeSelected = this.state.episodeEmaPriceTypeOptions.filter((option) => option === event.target.innerHTML)
                selectedValue = episodeEmaPriceTypeSelected[0]
            }
            if (type === 'viewingPeriod') {
                var viewingPeriodSelected = this.state.viewingPeriodOptions.filter((option) => option === event.target.innerHTML)
                selectedValue = viewingPeriodSelected[0]
            }
            if (type === 'readyToPublish') {
                var publishStatusSelected = this.state.publishStatusOptions.filter((option) => option === event.target.innerHTML)
                selectedValue = publishStatusSelected[0]
            }
            if (type === 'rateCardType') {
                var rateCardTypeSelected = this.state.rateCardTypeOptions.filter((option) => option === event.target.innerHTML)
                selectedValue = rateCardTypeSelected[0]
            }
            if (type === 'availsTypeOverride') {
                var availsTypeOverrideSelected = this.state.availsTypeOverrideOptions.filter((option) => option === event.target.innerHTML)
                selectedValue = availsTypeOverrideSelected[0]
            }
        }
        console.log("selectedValue ", selectedValue)
        updateValue(selectedValue)
    }

    formatOptionsFromServer(options) {
        const formattedOptions = [];
        for (let i = 0; i < options.length; i++) {
            formattedOptions.push(options[i].label)
        }
        return formattedOptions
    }

    formatCustomFilterOptions(filter) {
        const options = [];

        if (filter === 'dealId') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].dealId === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].dealId]) {
                    options.push(this.state.data[x].dealId)
                }
            }

            options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'dealName') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].dealName === null) {
                    options.push()
                }
                if (!options.includes[this.state.data[x].dealName]) {
                    options.push(this.state.data[x].dealName)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'catalogId') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].catalogId === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].catalogId]) {
                    options.push(this.state.data[x].catalogId)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'catalogName') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].catalogName === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].catalogName]) {
                    options.push(this.state.data[x].catalogName)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'readyToPublish') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].readyToPublish === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].readyToPublish]) {
                    options.push(this.state.data[x].readyToPublish)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'dateTaken') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].dateTaken === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].dateTaken]) {
                    options.push(this.state.data[x].dateTaken)
                }
            }
            options.length > 0 && options.length > 0 && options.sort((a, b) =>
                Date.parse(new Date(a.split("/").reverse().join("-"))) - Date.parse(new Date(b.split("/").reverse().join("-"))));
        }

        if (filter === 'termStart') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].termStart === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].termStart]) {
                    options.push(this.state.data[x].termStart)
                }
            }
            options.length > 0 && options.length > 0 && options.sort((a, b) =>
                Date.parse(new Date(a.split("/").reverse().join("-"))) - Date.parse(new Date(b.split("/").reverse().join("-"))));
        }

        if (filter === 'termEnd') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].termEnd === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].termEnd]) {
                    options.push(this.state.data[x].termEnd)
                }
            }
            options.length > 0 && options.length > 0 && options.sort((a, b) =>
                Date.parse(new Date(a.split("/").reverse().join("-"))) - Date.parse(new Date(b.split("/").reverse().join("-"))));
        }

        if (filter === 'rating') {
            console.log(this.state.data)
            for (let x = 0; x < this.state.data.length; x++) {

                if (this.state.data[x].rating === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].rating]) {
                    options.push(this.state.data[x].rating)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'rateCardType') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].rateCardType === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].rateCardType]) {
                    options.push(this.state.data[x].rateCardType)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'rateCardId') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].rateCardId === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].rateCardId]) {
                    options.push(this.state.data[x].rateCardId)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'rateCardName') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].rateCardName === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].rateCardName]) {
                    options.push(this.state.data[x].rateCardName)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'windowNum') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].windowNum === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].windowNum]) {
                    options.push(this.state.data[x].windowNum)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'tier') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].tier === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].tier]) {
                    options.push(this.state.data[x].tier)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'episodeTier') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].epidoseTier === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].epidoseTier]) {
                    options.push(this.state.data[x].epidoseTier)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'licenseFee') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].licenseFee === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].licenseFee]) {
                    options.push(this.state.data[x].licenseFee)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'minDeemedPrice') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].minDeemedPrice === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].minDeemedPrice]) {
                    options.push(this.state.data[x].minDeemedPrice)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'minAdvGuarantee') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].minAdvGuarantee === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].minAdvGuarantee]) {
                    options.push(this.state.data[x].minAdvGuarantee)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'seasonPassAmt') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].seasonPassAmt === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].seasonPassAmt]) {
                    options.push(this.state.data[x].seasonPassAmt)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'pmxPlatform') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].pmxPlatform === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].pmxPlatform]) {
                    options.push(this.state.data[x].pmxPlatform)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'currency') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].currency === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].currency]) {
                    options.push(this.state.data[x].currency)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'percentageSplit') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].percentageSplit === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].percentageSplit]) {
                    options.push(this.state.data[x].percentageSplit)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'emaPriceType') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].emaPriceType === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].emaPriceType]) {
                    options.push(this.state.data[x].emaPriceType)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'episodeEmaPriceType') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].episodeEmaPriceType === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].episodeEmaPriceType]) {
                    options.push(this.state.data[x].episodeEmaPriceType)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'viewingPeriod') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].viewingPeriod === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].viewingPeriod]) {
                    options.push(this.state.data[x].viewingPeriod)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'preSell') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].preSell === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].preSell]) {
                    options.push(this.state.data[x].preSell)
                }
            }
            options.length > 0 && options.length > 0 && options.sort(function (a, b) {
                return a - b
            });
        }

        if (filter === 'tprProgramName') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].tprProgramName === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].tprProgramName]) {
                    options.push(this.state.data[x].tprProgramName)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'ocad') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].ocad === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].ocad]) {
                    options.push(this.state.data[x].ocad)
                }
            }
            options.length > 0 && options.length > 0 && options.sort((a, b) =>
                Date.parse(new Date(a.split("/").reverse().join("-"))) - Date.parse(new Date(b.split("/").reverse().join("-"))));
        }

        if (filter === 'suppressionLiftDate') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].suppressionLiftDate === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].suppressionLiftDate]) {
                    options.push(this.state.data[x].suppressionLiftDate)
                }
            }
            options.length > 0 && options.length > 0 && options.sort((a, b) =>
                Date.parse(new Date(a.split("/").reverse().join("-"))) - Date.parse(new Date(b.split("/").reverse().join("-"))));
        }

        if (filter === 'exclusivity') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].exclusivity === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].exclusivity]) {
                    options.push(this.state.data[x].exclusivity)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'holdbackLanguages') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].holdbackLanguages === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].holdbackLanguages]) {
                    options.push(this.state.data[x].holdbackLanguages)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'languageAvailsDisplay') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].languageAvailsDisplay === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].languageAvailsDisplay]) {
                    options.push(this.state.data[x].languageAvailsDisplay)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'availsTypeOverride') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].availsTypeOverride === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].availsTypeOverride]) {
                    options.push(this.state.data[x].availsTypeOverride)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        if (filter === 'informationalRestrictions') {
            for (let x = 0; x < this.state.data.length; x++) {
                if (this.state.data[x].informationalRestrictions === null) {
                    options.push()
                } else if (!options.includes[this.state.data[x].informationalRestrictions]) {
                    options.push(this.state.data[x].informationalRestrictions)
                }
            }
            options.length > 0 && options.sort((a, b) => a.localeCompare(b));
        }

        return options.filter((value, index, array) => array.indexOf(value) === index);
    }

    handleCustomFilter(value, optionValues, selected) {
        let selectedValues = [];
        if (value.includes('all') && optionValues.length !== selected.length) {
            for (let x = 0; x < optionValues.length; x++) {
                selectedValues.push(optionValues[x]);
            }
        } else if (!value.includes('all')) {
            selectedValues = value;
        }

        return selectedValues
    }

    formatTapOfferStatus(status) {
        switch (status) {
            case 'PENDING':
                return 'Pending';
            case 'PUSHED':
                return 'Pushed';
            case 'OFFER_IN_PAST_SYSTEM_UPDATED':
                return 'Pushed';
            case 'DUPLICATE':
                return 'Duplicate';
            case 'DUPLICATE_DIFFJOB':
                return 'Duplicate';
            case 'SYSTEM_UPDATED':
                return 'System Updated';
            case 'USER_MODIFIED':
                return 'User Modified';
            case 'SYSTEM_UPDATED_OFFER_ACTIVE':
                return 'Offer Active - System Updated';
            case 'USER_MODIFIED_OFFER_ACTIVE':
                return 'Offer Active - User Modified';
            case 'INACTIVE_DEAL':
                return 'Inactive Deal';
            case 'BLANK_DEAL_FIELDS':
                return 'Blank Deal Fields';
            case 'RIGHTS_ISSUE':
                return 'Rights Issue';
            case 'MISSING_PRICING':
                return 'Missing Pricing';
            case 'TO_DELETE':
                return 'To Delete';
            case 'SYSTEM_CREATED':
                return 'System Created';
            case 'INFO_RESTRICTIONS':
                return 'Info Restrictions';
            default:
                return status;
        }
    }

    setEditMode(value, tableMeta, updateValue) {
        console.log("Inside setEditMode tableMeta ", tableMeta)
        var editRow = this.state.editRow
        editRow[tableMeta.rowIndex] = !editRow[tableMeta.rowIndex]
        tableMeta.currentTableData[tableMeta.rowIndex]['editRow'] = !tableMeta.currentTableData[tableMeta.rowIndex]['editRow']
        updateValue(value)
    }

    isValidDate(d) {
        return (d !== 'Invalid dateZ')
        //return d instanceof Date && !isNaN(d);
    }

    hasMultipleEditedRows(data) {
        let rowCount = 0;
        for (let i = 0; i < data.currentTableData.length; i++) {
            if (data.currentTableData[i].editRow) {
                rowCount++
            }
        }

        if (rowCount > 1) {
            return true;
        }
    }

    saveTableOffers(value, tableMeta, updateValue) {
        confirmAlert({
            title: 'Save Offer(s)',
            message: 'Are you sure you want to save changes in TAP2? Unsaved changes will be lost.',
            buttons: [
                {
                    label: 'Save All Offers',
                    onClick: () => {

                        for (let i = 0; i < tableMeta.currentTableData.length; i++) {

                            if (tableMeta.currentTableData[i].editRow) {

                                let payload = {};
                                let index = 0;
                                let errors = [];
                                this.state.columns.forEach(column => {
                                    const localFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
                                    if ((column.name === 'termEnd' || column.name === 'termStart' || column.name === 'dateTaken' || column.name === 'suppressionLiftDate' ||
                                            column.name === 'preSell' || column.name === 'ocad')
                                        && (tableMeta.currentTableData[i].data[index] !== "" &&
                                            tableMeta.currentTableData[i].data[index] !== null)) {
                                        payload[column.name] = Moment(Moment(tableMeta.currentTableData[i].data[index]).local().format(localFormat)).format(localFormat) + 'Z'
                                        if (!this.isValidDate(payload[column.name])) {
                                            errors.push(column.label + ' is an invalid date with value ' + payload[column.name])
                                        }

                                        if (column.name === 'termEnd') {
                                            if (Moment(payload[column.name]).isBefore(Moment(payload['termStart']))) {
                                                errors.push(column.label + ' falls before Term Start Date');
                                            }
                                        }

                                    } else {
                                        payload[column.name] = tableMeta.currentTableData[i].data[index]
                                        if (column.name === 'percentageSplit') {
                                            payload[column.name] = payload[column.name] ? payload[column.name].replace('%', '') : payload[column.name]
                                        }
                                        if (column.name === 'offerStatus' || column.name === 'emaPriceType' || column.name === 'episodeEmaPriceType' || column.name === 'viewingPeriod' || column.name === 'readyToPublish' ||
                                            column.name === 'minAdvGuarantee' || column.name === 'licenseFee' || column.name === 'seasonPassAmt' || column.name === 'minDeemedPrice'
                                            || column.name === 'rateCardType' || column.name === 'tier' || column.name === 'availsTypeOverride' || column.name === 'episodeTier') {
                                            payload[column.name] = tableMeta.currentTableData[i].data[index]
                                        }
                                        if (column.required && (!payload[column.name] || payload[column.name] === '')) {
                                            errors.push(column.label + ' is required')
                                            if (!tableMeta.currentTableData[i]['errorMessages']) {
                                                tableMeta.currentTableData[i]['errorMessages'] = {}
                                            }
                                            tableMeta.currentTableData[i]['errorMessages'][column.name] = column.label + ' is required'
                                        } else {
                                            if (!tableMeta.currentTableData[i]['errorMessages']) {
                                                tableMeta.currentTableData[i]['errorMessages'] = {}
                                            }
                                            tableMeta.currentTableData[i]['errorMessages'][column.name] = null
                                        }
                                        if (column.name === 'languages') {
                                            if (typeof tableMeta.currentTableData[i].data[index] == 'object') {
                                                let payloadLanguageList = tableMeta.currentTableData[i].data[index].map(function (element) {
                                                    return `${element.label}`;
                                                })
                                                payload[column.name] = payloadLanguageList.join("|")
                                            } else {
                                                payload[column.name] = tableMeta.currentTableData[i].data[index]
                                            }
                                        }

                                        if (column.name === 'subLanguage' || column.name === 'dubLanguage') {
                                            if (typeof tableMeta.currentTableData[i].data[index] == 'object') {
                                                payload[column.name] = this.reArrangeSubDubLanguage(tableMeta, column, i)
                                            } else {
                                                payload[column.name] = tableMeta.currentTableData[i].data[index]
                                            }
                                        }

                                        if (column.name === 'holdbackLanguages') {
                                            if (typeof tableMeta.currentTableData[i].data[index] == 'object') {
                                                let payloadLanguageList = tableMeta.currentTableData[i].data[index].map(function (element) {
                                                    return `${element.label}`;
                                                })
                                                payload[column.name] = payloadLanguageList.join("|")
                                            } else {
                                                payload[column.name] = tableMeta.currentTableData[i].data[index]
                                            }
                                        }
                                    }
                                    if (payload[column.name] && typeof payload[column.name] == 'object') {
                                        payload[column.name] = payload[column.name].label
                                    }
                                    if (column.name === 'catalogName') {
                                        if (typeof tableMeta.currentTableData[i].data[index] == 'object') {
                                            payload["catalogId"] = tableMeta.currentTableData[i].data[index].id
                                            payload["catalogName"] = tableMeta.currentTableData[i].data[index].title
                                        } else {
                                            payload["catalogName"] = tableMeta.currentTableData[i].data[index]
                                        }
                                    }
                                    if (column.name === 'dealName') {
                                        if (typeof tableMeta.currentTableData[i].data[index] == 'object') {
                                            payload["dealId"] = tableMeta.currentTableData[i].data[index].id
                                            payload["dealName"] = tableMeta.currentTableData[i].data[index].title
                                        } else {
                                            payload["dealName"] = tableMeta.currentTableData[i].data[index]
                                        }
                                    }
                                    index++;
                                })


                                if (errors.length > 0) {
                                    var errorMessage = <div>
                                        <div className="toastTitle">Error</div>
                                        <div className="toastMainMessage">{errors.join(',')}</div>
                                    </div>
                                    toast.error(errorMessage, {
                                        position: toast.POSITION.BOTTOM_CENTER,
                                        closeButton: true,
                                        autoClose: false
                                    });
                                    updateValue(value)
                                    return
                                }
                                var editRow = this.state.editRow
                                editRow[i] = !editRow[i]
                                tableMeta.currentTableData[i]['editRow'] = !tableMeta.currentTableData[i]['editRow']
                                updateValue(value)
                                this.updateOfferTable(payload)
                            }
                        }
                    }
                },
                {
                    label: 'Save This Offer',
                    onClick: () => {
                        this.saveTableSingleOffer(value, tableMeta, updateValue)
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {
                        toast.dismiss()
                    }
                }
            ]
        });
    }

    saveTableSingleOffer(value, tableMeta, updateValue) {
        var payload = {}
        var index = 0;
        var errors = []
        this.state.columns.forEach(column => {
            const localFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
            if ((column.name === 'termEnd' || column.name === 'termStart' || column.name === 'dateTaken' ||
                    column.name === 'suppressionLiftDate' || column.name === 'preSell' || column.name === 'ocad')
                && (tableMeta.currentTableData[tableMeta.rowIndex].data[index] !== "" &&
                    tableMeta.currentTableData[tableMeta.rowIndex].data[index] !== null)) {
                payload[column.name] = Moment(Moment(tableMeta.currentTableData[tableMeta.rowIndex].data[index]).local().format(localFormat)).format(localFormat) + 'Z'
                if (!this.isValidDate(payload[column.name])) {
                    errors.push(column.label + ' is an invalid date with value ' + payload[column.name])
                }

                if (column.name === 'termEnd') {
                    if (Moment(payload[column.name]).isBefore(Moment(payload['termStart']))) {
                        errors.push(column.label + ' falls before Term Start Date');
                    }
                }

            } else {
                payload[column.name] = tableMeta.currentTableData[tableMeta.rowIndex].data[index]
                if (column.name === 'percentageSplit') {
                    payload[column.name] = payload[column.name] ? payload[column.name].replace('%', '') : payload[column.name]
                }
                if (column.name === 'offerStatus' || column.name === 'emaPriceType' || column.name === 'episodeEmaPriceType' || column.name === 'viewingPeriod' || column.name === 'readyToPublish' ||
                    column.name === 'minAdvGuarantee' || column.name === 'licenseFee' || column.name === 'seasonPassAmt' || column.name === 'minDeemedPrice'
                    || column.name === 'rateCardType' || column.name === 'tier' || column.name === 'availsTypeOverride' || column.name === 'episodeTier') {
                    payload[column.name] = tableMeta.currentTableData[tableMeta.rowIndex].data[index]
                }
                if (column.required && (!payload[column.name] || payload[column.name] === '')) {
                    errors.push(column.label + ' is required')
                    if (!tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages']) {
                        tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] = {}
                    }
                    tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][column.name] = column.label + ' is required'
                } else {
                    if (!tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages']) {
                        tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'] = {}
                    }
                    tableMeta.currentTableData[tableMeta.rowIndex]['errorMessages'][column.name] = null
                }
                if (column.name === 'languages') {
                    if (typeof tableMeta.currentTableData[tableMeta.rowIndex].data[index] == 'object') {
                        let payloadLanguageList = tableMeta.currentTableData[tableMeta.rowIndex].data[index].map(function (element) {
                            return `${element.label}`;
                        })
                        payload[column.name] = payloadLanguageList.join("|")
                    } else {
                        payload[column.name] = tableMeta.currentTableData[tableMeta.rowIndex].data[index]
                    }
                }

                if (column.name === 'subLanguage' || column.name === 'dubLanguage') {
                    if (typeof tableMeta.currentTableData[tableMeta.rowIndex].data[index] == 'object') {
                        payload[column.name] = this.reArrangeSubDubLanguage(tableMeta, column, -1)
                    } else {
                        payload[column.name] = tableMeta.currentTableData[tableMeta.rowIndex].data[index]
                    }
                }

                if (column.name === 'holdbackLanguages') {
                    if (typeof tableMeta.currentTableData[tableMeta.rowIndex].data[index] == 'object') {
                        let payloadLanguageList = tableMeta.currentTableData[tableMeta.rowIndex].data[index].map(function (element) {
                            return `${element.label}`;
                        })
                        payload[column.name] = payloadLanguageList.join("|")
                    } else {
                        payload[column.name] = tableMeta.currentTableData[tableMeta.rowIndex].data[index]
                    }
                }
            }

            if (payload[column.name] && typeof payload[column.name] == 'object') {
                payload[column.name] = payload[column.name].label
            }
            if (column.name === 'catalogName') {
                if (typeof tableMeta.currentTableData[tableMeta.rowIndex].data[index] == 'object') {
                    payload["catalogId"] = tableMeta.currentTableData[tableMeta.rowIndex].data[index].id
                    payload["catalogName"] = tableMeta.currentTableData[tableMeta.rowIndex].data[index].title
                } else {
                    payload["catalogName"] = tableMeta.currentTableData[tableMeta.rowIndex].data[index]
                }
            }
            if (column.name === 'dealName') {
                if (typeof tableMeta.currentTableData[tableMeta.rowIndex].data[index] == 'object') {
                    payload["dealId"] = tableMeta.currentTableData[tableMeta.rowIndex].data[index].id
                    payload["dealName"] = tableMeta.currentTableData[tableMeta.rowIndex].data[index].title
                } else {
                    payload["dealName"] = tableMeta.currentTableData[tableMeta.rowIndex].data[index]
                }
            }
            index++;
        })
        if (errors.length > 0) {
            var errorMessage = <div>
                <div className="toastTitle">Error</div>
                <div className="toastMainMessage">{errors.join(',')}</div>
            </div>
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_CENTER,
                closeButton: true,
                autoClose: false
            });
            updateValue(value)
            return
        }
        var editRow = this.state.editRow
        for (let i = 0; i < tableMeta.currentTableData.length; i++) {
            if (tableMeta.currentTableData[i].editRow) {
                editRow[i] = !editRow[i]
                tableMeta.currentTableData[i]['editRow'] = !tableMeta.currentTableData[i]['editRow']
                updateValue(value)
            }
        }
        this.updateOfferTable(payload)
    }

    componentDidMount() {
        const recordId = this.props.location.pathname.split("/")[3]
        this.getData("licensing/" + recordId + "/offerTable", 0, recordId);
        var permissions;
        AuthProvider.getPermissions().then(retPermissions => {
            //console.log("retPermissions ", retPermissions)
            permissions = retPermissions;
            //console.log("permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) ", permissions?.split(',').includes(Config.ROLE_TAP_ADMIN))
            if (permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN)) {
                //console.log("SEt Admin ")
                this.setState({ isAdmin: true });
            } else {
                this.setState({ isAdmin: false });
            }
        }).catch(function (error) {
            console.log("permissions error ", error);
            //location.reload();
        });
    }

    evaluateIsEditable(tableMeta) {
        var tapOfferStatus = tableMeta.currentTableData[tableMeta.rowIndex].data[this.returnIndexColumn("tapOfferStatus")];
        //if (!this.state.isAdmin)
        //return false
        if (tapOfferStatus === "TO_DELETE" || tapOfferStatus === "INACTIVE_DEAL" || tapOfferStatus === "BLANK_DEAL_FIELDS") {
            return false;
        } else {
            return true;
        }
    }

    evaluatePushToRl(tableMeta) {
        var tapOfferStatus = tableMeta.currentTableData[tableMeta.rowIndex].data[this.returnIndexColumn("tapOfferStatus")];
        var offerTableRowRlId = tableMeta.currentTableData[tableMeta.rowIndex].data[this.returnIndexColumn("offerTableRowRlId")];
        //if (!this.state.isAdmin)
        //return false
        if ((tapOfferStatus === "PENDING" || tapOfferStatus === "SYSTEM_UPDATED" || tapOfferStatus === "SYSTEM_CREATED" || tapOfferStatus === "USER_MODIFIED" || tapOfferStatus === "SYSTEM_UPDATED_OFFER_ACTIVE" || tapOfferStatus === "USER_MODIFIED_OFFER_ACTIVE" || tapOfferStatus === "RIGHTS_ISSUE" || tapOfferStatus === "INFO_RESTRICTIONS") &&
            offerTableRowRlId === null && !this.state.editRow[tableMeta.rowIndex]) {
            return true;
        }
    }

    evaluateUpdatePushToRl(tableMeta) {
        var tapOfferStatus = tableMeta.currentTableData[tableMeta.rowIndex].data[this.returnIndexColumn("tapOfferStatus")];
        var offerTableRowRlId = tableMeta.currentTableData[tableMeta.rowIndex].data[this.returnIndexColumn("offerTableRowRlId")];
        //if (!this.state.isAdmin)
        //return false
        if ((tapOfferStatus === "PENDING" || tapOfferStatus === "SYSTEM_UPDATED" || tapOfferStatus === "SYSTEM_CREATED" || tapOfferStatus === "USER_MODIFIED" || tapOfferStatus === "SYSTEM_UPDATED_OFFER_ACTIVE" || tapOfferStatus === "USER_MODIFIED_OFFER_ACTIVE" || tapOfferStatus === "RIGHTS_ISSUE" || tapOfferStatus === "INFO_RESTRICTIONS") &&
            offerTableRowRlId !== null && !this.state.editRow[tableMeta.rowIndex]) {
            return true;
        }
    }

    returnIndexColumn(columnName) {
        var index = 0;
        var indexColumn = 0;
        this.state.columns.forEach(column => {
            if (column.name === columnName) {
                indexColumn = index;
            }
            index++;
        });
        return indexColumn;
    }

    pushTableOffer(value, tableMeta, updateValue) {
        toast.dismiss();
        var payload = {}
        payload["id"] = value
        this.setState({ isProcessingPushDelete: true })
        myDataProvider.update("licensing/pushOfferTable",
            { id: payload.id }
        ).then((response) => {
            this.setState({ isProcessingPushDelete: false })
            console.log("response ", response)
            var successMessage = <div>
                <div className="toastTitle">Success</div>
                <div className="toastMainMessage">Offer Pushed to RL</div>
            </div>
            toast.success(successMessage, {
                position: toast.POSITION.BOTTOM_CENTER,
                closeButton: true,
                autoClose: 5000
            });
            this.componentDidMount()
        }).catch(({ status, statusText, headers, body }) => {
            this.setState({ isProcessingPushDelete: false })
            console.log("Caught status status ", status);
            console.log("Caught status statusText ", statusText);
            console.log("Caught status body ", body);
            var errorMessageString = ""
            if (body && body.message) {
                if (body.message.toString().indexOf("; nested") > 0) {
                    errorMessageString = body.message.toString().substring(0, body.message.toString().indexOf("; nested"))
                    if (errorMessageString.indexOf(" at ") > 0) {
                        errorMessageString = errorMessageString.substring(0, errorMessageString.indexOf("at"))
                    }
                } else {
                    errorMessageString = body.message.toString()
                }
            }
            var errorMessage = <div>
                <div className="toastTitle">Error</div>
                <div className="toastMainMessage">{errorMessageString}</div>
            </div>
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_CENTER,
                closeButton: true,
                autoClose: false
            });
        });
    }

    deleteTableOfferRL(value, tableMeta, updateValue) {
        confirmAlert({
            title: 'Delete Offer(s)',
            message: 'Are you sure you want to delete the offer(s) from TAP2 and Rightsline? This action cannot be undone.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({ isProcessingPushDelete: true })
                        toast.dismiss();
                        var payload = {}
                        payload["id"] = value
                        myDataProvider.delete("licensing/deleteOfferTable",
                            { id: payload.id }
                        ).then((response) => {
                            this.setState({ isProcessingPushDelete: false })
                            if (response.data.status === 500) {
                                var message = <div>
                                    <div className="toastTitle">Success</div>
                                    <div className="toastMainMessage">Error trying to delete the
                                        offer: {response.data.message}</div>
                                </div>
                                toast.error(message, {
                                    position: toast.POSITION.BOTTOM_CENTER,
                                    closeButton: true,
                                    autoClose: 5000
                                });
                            } else {
                                var successMessage = <div>
                                    <div className="toastTitle">Success</div>
                                    <div className="toastMainMessage">Offer Deleted</div>
                                </div>
                                toast.success(successMessage, {
                                    position: toast.POSITION.BOTTOM_CENTER,
                                    closeButton: true,
                                    autoClose: 5000
                                });
                            }
                            this.componentDidMount()
                        }).catch(({ status, statusText, headers, body }) => {
                            this.setState({ isProcessingPushDelete: false })
                            console.log("Caught status status ", status);
                            console.log("Caught status statusText ", statusText);
                            console.log("Caught status body ", body);
                            var errorMessageString = ""
                            if (body && body.message) {
                                if (body.message.toString().indexOf("; nested") > 0) {
                                    errorMessageString = body.message.toString().substring(0, body.message.toString().indexOf("; nested"))
                                    if (errorMessageString.indexOf(" at ") > 0) {
                                        errorMessageString = errorMessageString.substring(0, errorMessageString.indexOf("at"))
                                    }
                                } else {
                                    errorMessageString = body.message.toString()
                                }
                            }
                            var errorMessage = <div>
                                <div className="toastTitle">Error</div>
                                <div className="toastMainMessage">{errorMessageString}</div>
                            </div>
                            toast.error(errorMessage, {
                                position: toast.POSITION.BOTTOM_CENTER,
                                closeButton: true,
                                autoClose: false
                            });
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log("User cancelled ")
                }
            ]
        });
    }

    updateOfferTable(offerTable) {
        const featureFlagEnabledColumns = this.state.isEpisodeFeatureFlagEnabled ? [] : ["episodeTier", "episodeEmaPriceType"]
        const featureFlagOfferTable = Array.isArray(offerTable) ? offerDataFeatureFlags(offerTable, featureFlagEnabledColumns) :
            offerDataObjectFeatureFlags(offerTable, featureFlagEnabledColumns)
        myDataProvider.update("licensing/offerTable",
            { id: offerTable.id, data: featureFlagOfferTable }
        ).then((response) => {
            if(typeof response !== "undefined") {
                var successMessage = <div>
                    <div className="toastTitle">Success</div>
                    <div className="toastMainMessage">Offer Saved</div>
                </div>
                toast.success(successMessage, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    closeButton: true,
                    autoClose: 5000
                });
            }
            this.componentDidMount()
        }).catch(({ status, statusText, headers, body }) => {
            console.log("Caught status status ", status);
            console.log("Caught status statusText ", statusText);
            console.log("Caught status body ", body);
            var errorMessageString = ""
            if (body.error && body.error.message) {
                if (body.error.message.toString().indexOf("; nested") > 0) {
                    errorMessageString = body.error.message.toString().substring(0, body.error.message.toString().indexOf("; nested"))
                    if (errorMessageString.indexOf(" at ") > 0) {
                        errorMessageString = errorMessageString.substring(0, errorMessageString.indexOf("at"))
                    }
                } else {
                    errorMessageString = body.error.message.toString()
                }
            }
            var errorMessage = <div>
                <div className="toastTitle">Error</div>
                <div className="toastMainMessage">`${errorMessageString}`</div>
            </div>
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_CENTER,
                closeButton: true,
                autoClose: false
            });
        });
    }

    // async fetchCatalogSearchResults(value, searchTerm, urlParams, updateValue) {
    //     const token = localStorage.getItem('tap-token');
    //     const requestOptions = {
    //         method: 'GET',
    //         headers: { 'Content-Type': 'application/json', Authorization: token },
    //     };
    //     await fetch(process.env.REACT_APP_API_URL + '/licensing/searchCatalogItemsAPI?' + urlParams, requestOptions)
    //         .then(response => response.json())
    //         .then(searchResults => {
    //             var catalogOptions = []
    //             if (searchResults && searchResults.status === 404) {
    //                 catalogOptions = []
    //             } else {
    //                 catalogOptions = searchResults
    //             }
    //             this.setState({catalogOptions: catalogOptions})
    //             updateValue(value)
    //         }).catch(ex => {
    //             console.log("Handled exception loading results for " + searchTerm, ex)
    //         });
    // }

    // handleCatalogInputTextChange = (value, event, updateValue) => {
    //     var urlParams = "filter=" + encodeURIComponent(JSON.stringify({ "q": event.target.value }))
    //     this.fetchCatalogSearchResults(value, event.target.value, urlParams, updateValue)
    // }

    // async fetchDealSearchResults(value, searchTerm, urlParams, updateValue) {
    //     const token = localStorage.getItem('tap-token');
    //     const requestOptions = {
    //         method: 'GET',
    //         headers: { 'Content-Type': 'application/json', Authorization: token },
    //     };
    //     await fetch(process.env.REACT_APP_API_URL + '/licensing/searchDealsAPI?' + urlParams, requestOptions)
    //         .then(response => response.json())
    //         .then(searchResults => {
    //             var dealOptions = []
    //             if (searchResults && searchResults.status === 404) {
    //                 dealOptions = []
    //             } else {
    //                 dealOptions = searchResults
    //             }
    //             this.setState({dealOptions: dealOptions})
    //             updateValue(value)
    //         }).catch(ex => {
    //             console.log("Handled exception loading results for " + searchTerm, ex)
    //         });
    // }

    // handleDealInputTextChange = (value, event, updateValue) => {
    //     var urlParams = "filter=" + encodeURIComponent(JSON.stringify({ "q": event.target.value }))
    //     this.fetchDealSearchResults(value, event.target.value, urlParams, updateValue)
    // }

    async fetchList(url, page, perPage) {
        return myDataProvider.getList(url, {
            filter: {},
            sort: { field: 'id', order: 'DESC' },
            pagination: { page: page, perPage: perPage },
        }).then(response => {
            return response
        })
            .catch(({ status, statusText, headers, body }) => {
                return []
            });
    }

    getData = async (url, page, recordId) => {
        const isEpisodeFeatureFlagEnabled = AuthProvider.isFlagEnabled('TAP_OFFER_EPISODE_TIER_AND_EPISODE_EMA_PRICE_TYPE');
        const mediaRes = await this.licencingMTLRequest("licensing/getMediaTerritoryList/media");
        const territoryRes = await this.licencingMTLRequest("licensing/getMediaTerritoryList/territory");
        const languageRes = await this.licencingMTLRequest("licensing/getMediaTerritoryList/language");
        const subDubLanguageRes = await this.licencingMTLRequest("licensing/getMediaTerritoryList/subDublanguage");
        const formatRes = await this.licencingMTLRequest("lists/format");
        const currencyRes = await this.licencingMTLRequest("lists/currency");
        const windowRes = await this.licencingMTLRequest("lists/transactionalWindows");
        const res = await this.xhrRequest(url, page);
        loadDataSubDubLanguagesLastSelected(res.data)
        this.setState({
            data: res.data ? res.data : [],
            count: res.data ? res.data.length : [],
            recordId: recordId,
            medias: mediaRes.data ? mediaRes.data : [],
            territories: territoryRes.data ? territoryRes.data : [],
            languages: languageRes.data ? languageRes.data : [],
            subDubLanguages: subDubLanguageRes.data ? allLanguagesItemFirstPosition(subDubLanguageRes.data) : [],
            formats: formatRes.data ? formatRes.data : [],
            currencies: currencyRes.data ? currencyRes.data : [],
            windows: windowRes.data ? windowRes.data : [],
            catalogOptions: [],
            dealOptions: [],
            isLoading: false,
            isProcessingPushDelete: false,
            isEpisodeFeatureFlagEnabled: isEpisodeFeatureFlagEnabled ? isEpisodeFeatureFlagEnabled : false,
            publishStatusOptions: ["Yes", "No"],
            offerStatusOptions: ["Offered", "Taken", "Pass", "Put", "Cancelled"],
            rateCardTypeOptions: ["Rev Share", "Rev Share %", "Rev Share %+", "Non-Rev Share", "VOD PPV", "VOD PPV %", "Hotel", "Rev Share % B", "Rev Share +"],
            emaPriceTypeOptions: ["Category", "License Fee", "TPR-License Fee", "WSP", "Min Deemed Price", "Tier", "Season Only"],
            episodeEmaPriceTypeOptions : ["Min Deemed Price", "Category", "Tier", "Season Only"],
            viewingPeriodOptions: ["24 hours", "24,48 hours", "24,48,72 hours", "Per Week", "Unlimited"],
            availsTypeOverrideOptions: ["Feature", "TV"],
        });
    };

    licencingMTLRequest = async (url) => {
        return await this.fetchList(url, 0, 10000)
    };

    sort = (page, sortOrder, recordId) => {
        this.xhrRequest("licensing/" + recordId + "/offerTable", page, sortOrder).then(res => {
            this.setState({
                data: res.data,
                page: res.page,
                sortOrder,
                count: res.total,
            });
        });
    };

    xhrRequest = async (url, page, sortOrder = {}) => {
        return await this.fetchList(url, page, 10000)
    };

    changePage = (page, sortOrder, recordId) => {
        this.xhrRequest("licensing/" + recordId + "/offerTable", page, sortOrder).then(res => {
            this.setState({
                page: res.page,
                sortOrder,
                data: res.data,
                count: res.total,
            });
        });
    };

    childCallback = (value) => {
        this.setState({ isProcessingPushDelete: value })
    };

    reArrangeSubDubLanguage = (tableMeta, column, i) => {
        let index = i === -1? tableMeta.rowIndex : i
        const subLanguageFromTableMeta = tableMeta.currentTableData[index]
            .data[this.returnIndexColumn("subLanguage")]
        const dubLanguageFromTableMeta = tableMeta.currentTableData[index]
            .data[this.returnIndexColumn("dubLanguage")]

        let payloadSubLanguagesList = typeof subLanguageFromTableMeta !== "string" ?
            subLanguageFromTableMeta.map(element => { return `${element.label}`; }) : []

        let payloadDubLanguagesList = typeof dubLanguageFromTableMeta !== "string" ?
            dubLanguageFromTableMeta.map(element => { return `${element.label}`; }) : []

        let dataTotalFrontend = sdLanguagesLastSelected(tableMeta.tableData)
        const dataSubDubLanguagesLocalStorage = validateParsedValue(JSON.parse(localStorage.getItem('subDubLanguageLastSelected'))) ?
            JSON.parse(localStorage.getItem('subDubLanguageLastSelected')) : dataTotalFrontend
        const offerTableId = tableMeta.currentTableData[index].data[1];

        const subLanguageNoUpdate = dataSubDubLanguagesLocalStorage
            .map(item => {
                if(item && parseInt(item.id) === parseInt(offerTableId)) { return item.subLanguage }
                return null;
            })
        const subLanguageNoUpdateLabel = subLanguageNoUpdate
            .filter(value => value !== null && value !== undefined)

        let subLanguageLabel;
        if (typeof subLanguageFromTableMeta !== "string" && Array.isArray(subLanguageFromTableMeta) &&
            subLanguageFromTableMeta.length > 0) {
            subLanguageLabel = subLanguageFromTableMeta[subLanguageFromTableMeta.length - 1].label;
        } else {
            subLanguageLabel = subLanguageNoUpdateLabel[0];
        }

        const dubLanguageNoUpdate = dataSubDubLanguagesLocalStorage
            .map(item => {
                if(item && parseInt(item.id) === parseInt(offerTableId)) { return item.dubLanguage }
                return null;
            })
        const dubLanguageNoUpdateLabel = dubLanguageNoUpdate
            .filter(value => value !== null && value !== undefined)

        let dubLanguageLabel;
        if (typeof dubLanguageFromTableMeta !== "string"  && Array.isArray(dubLanguageFromTableMeta) &&
            dubLanguageFromTableMeta.length > 0) {
            dubLanguageLabel = dubLanguageFromTableMeta[dubLanguageFromTableMeta.length - 1].label
        } else {
            dubLanguageLabel = dubLanguageNoUpdateLabel[0];
        }

        const sLanguageSelected = {}
        sLanguageSelected.id = offerTableId;
        sLanguageSelected.subLanguage = subLanguageLabel
        sLanguageSelected.dubLanguage = dubLanguageLabel

        const updatedDataFrontend = dataSubDubLanguagesLocalStorage.map(item => {
            if (parseInt(item.id) === parseInt(sLanguageSelected.id) &&
                sLanguageSelected.subLanguage && sLanguageSelected.dubLanguage) {
                return { ...item, subLanguage: sLanguageSelected.subLanguage, dubLanguage: sLanguageSelected.dubLanguage };
            }
            return item;
        });
        localStorage.setItem('subDubLanguageLastSelected', JSON.stringify(updatedDataFrontend))

        const filteredArray = updatedDataFrontend.filter(item => item.id === offerTableId.toString());
        const isPresentInArray = filteredArray
            .some(item => payloadSubLanguagesList.includes(item.subLanguage) ||
                payloadDubLanguagesList.includes(item.dubLanguage));

        if (isPresentInArray) {
            const subLanguageFilter = filteredArray.map(item => item.subLanguage)
            const indexSubLanguageFilter = payloadSubLanguagesList.indexOf(subLanguageFilter[0]);
            if (indexSubLanguageFilter !== -1) {
                payloadSubLanguagesList.splice(indexSubLanguageFilter, 1);
            }
            payloadSubLanguagesList.push(subLanguageFilter[0]);

            const dubLanguageFilter = filteredArray.map(item => item.dubLanguage)
            const indexDubLanguageFilter = payloadDubLanguagesList.indexOf(dubLanguageFilter[0]);
            if (indexDubLanguageFilter !== -1) {
                payloadDubLanguagesList.splice(indexDubLanguageFilter, 1);
            }
            payloadDubLanguagesList.push(dubLanguageFilter[0]);
        }

        let payloadSubDubLanguagesList = ""
        if(column.name.toString() === "subLanguage") {
            payloadSubDubLanguagesList = payloadSubLanguagesList
        }

        if(column.name.toString() === "dubLanguage") {
            payloadSubDubLanguagesList = payloadDubLanguagesList
        }

        return payloadSubDubLanguagesList.join("|")
    }

    render() {
        const {data, columns, rowsPerPage, sortOrder, isLoading, isProcessingPushDelete, isEpisodeFeatureFlagEnabled} = this.state;
        const validTapOfferEpisodes = isEpisodeFeatureFlagEnabled ? ["episodeTier", "episodeEmaPriceType"] : []
        const updatedColumns = featureFlagsDataColumn(data, columns, validTapOfferEpisodes,
            validTapOfferEpisodes);
        const offerTableRowsPerPage = localStorage.getItem('offerTableRowsPerPage');
        const options = {
            filter: true,
            onFilterChange: (changedColumn, filterList) => {
                console.log(changedColumn, filterList);
            },
            storageKey: `LicensingJob-${this.state.recordId?this.state.recordId:0}`,
            filterType: 'dropdown',
            responsive: 'vertical',
            serverSide: false,
            rowsPerPage: offerTableRowsPerPage ? offerTableRowsPerPage : rowsPerPage,
            rowsPerPageOptions: [5, 10, 25, 50, 100, 500],
            onChangeRowsPerPage: function (numberOfRows) {
                localStorage.setItem('offerTableRowsPerPage', numberOfRows);
            },
            sort: true,
            sortOrder: sortOrder,
            customToolbar: () => {
                return (<Fragment>
                    <div><FileUploadButton /></div>
                </Fragment>);
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
                return (<Fragment>
                    <div>
                        <PushOffer isLoading={this.childCallback} selectedrows={selectedRows}
                            dataTable={this.state.data} location={this.props.location}> </PushOffer>
                        <DeleteOffer isLoading={this.childCallback} selectedrows={selectedRows}
                            dataTable={this.state.data} location={this.props.location}> </DeleteOffer>
                    </div>
                </Fragment>
                );
            },
            expandableRows: true,
            renderExpandableRow: (rowData, rowMeta) => {
                const colSpan = rowData.length + 1;
                return (
                    <TableRow>
                        <TableCell
                            colSpan={colSpan}>{parse(rowData[rowData.length - 1].replaceAll('\n', '<br/>'))}</TableCell>
                    </TableRow>
                );
            },
            isRowExpandable: (dataIndex, expandedRows, rowData) => {
                if (data && data[dataIndex].message)
                    return true
                return false
            },
            expandableRowsHeader: false,
            expandableRowsOnClick: false,
            fixedHeader: true,
            textLabels: {
                body: {
                    noMatch: "Sorry, no matching available offers found",
                    toolTip: "Sort",
                    columnHeaderTooltip: column => `Sort by ${column.label}`
                },
                toolbar: {
                    downloadCsv: "Download XLSX"
                }
            },
            downloadOptions: {
                filename: 'Offers.csv', separator: ',',
                filterOptions: {
                    useDisplayedRowsOnly: true
                }
            },
            onDownload: (buildHead, buildBody, columns, values) => {
                const json = values.reduce((result, val) => {
                    const temp = {};
                    val.data.forEach((v, idx) => {
                        if (columns[idx].download) {
                            temp[columns[idx].label] = v;
                        }
                    });
                    result.push(temp);
                    return result;
                }, []);
                exportAsExcelFile(json, "Offers", isEpisodeFeatureFlagEnabled)
                return false;
            }
        };
        return (
            <Fragment>
                <div>
                    <OfferTableListBreadcrumb record={this.state.recordId} />
                    {isLoading ? <Loading /> :
                        isProcessingPushDelete ?
                            <Loading loadingPrimary={"Please Wait..."} loadingSecondary={"Processing Offer Tables"} /> :
                            <MUIDataTable
                                data={data}
                                columns={updatedColumns}
                                options={options} />
                    }
                </div>
            </Fragment>
        );
    }
}

const featureFlagsDataColumn = (data, columns, columnIndexes, columnNames) => {
    const hasValidColumnNames = Array.isArray(columnNames) && columnNames.length > 0;
    const updatedColumns = hasValidColumnNames
        ? offerColumnsFeatureFlags(columns, columnNames)
        : columns;
    return updatedColumns;
};

const offerColumnsFeatureFlags = (data, columnNames) => {
    return data.map(item => {
        const shouldHideColumn = columnNames.includes(item.name);
        return shouldHideColumn
            ? {
                ...item,
                options: {
                    ...item.options,
                    display: true,
                },
            }
            : item;
    });
};

const offerDataFeatureFlags = (data, columnIndexes) => {
    const hasValidColumnIndexes = Array.isArray(columnIndexes) && columnIndexes.length > 0;
    const updatedData = data.map(obj => {
        if (hasValidColumnIndexes) {
            return offerDataObjectFeatureFlags(obj, columnIndexes);
        }
        return obj;
    });
    return updatedData;
};

const offerDataObjectFeatureFlags = (data, columnIndexes) => {
    const updatedObj = { ...data };
    columnIndexes.forEach(index => {
        if (index in updatedObj) {
            updatedObj[index] = null;
        }
    });
    return updatedObj;
}

const exportAsExcelFile = async (data, excelFileName, isEpisodeFeatureFlagEnabled) => {
    const episodeFeatureFlag = ["Episode Tier", "Episode EMA Price Type"]
    const getFlagNullData = getFeatureFlagNullData(data, episodeFeatureFlag);
    const header = createHeaders(data);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("data");

    if(true) {
        sheet.columns = header
        for (let i = 0; i < getFlagNullData.length; i++) {
            const dataRow = getFlagNullData[i];
            sheet.addRow(dataRow);
        }
    } else {
        sheet.columns = header
        for (let i = 0; i < data.length; i++) {
            const dataRow = data[i];
            sheet.addRow(dataRow);
        }
    }

    try {
        const buffer = await workbook.xlsx.writeBuffer();
        saveAsExcelFile(buffer, excelFileName);
    } catch (error) {
        console.error("Error writing Excel buffer:", error);
    }
}

const saveAsExcelFile = (buffer, fileName) => {
    const EXCEL_TYPE: String = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION: String = ".xlsx";
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
}

const createHeaders = (data, ) => {
    const keys = Object.keys(data[0]);
    return keys.map(key => {
        return {
            header: key,
            key: key
        }
    });
};

const getFeatureFlagNullData = (data, episodeFeatureFlag) => {
    return data
}

const allLanguagesItemFirstPosition = (data) => {
    if (data && Array.isArray(data) && data.length > 0) {
        const index = data.findIndex(item => item.label === "All Languages");
        if (index !== -1) {
            const [allLanguagesItem] = data.splice(index, 1);
            data.unshift(allLanguagesItem);
        }
    }
    return data
}

const sdLanguagesLastSelected = (data) => {
    return Array.isArray(data) ? data.map((offer, index) => {
        return objSubDubLanguageLastSelected(offer);
    }) : objSubDubLanguageLastSelected(data)
}

const objSubDubLanguageLastSelected = (offer) => {
    const offersdlLastSelected = {}
    offersdlLastSelected.id = offer.id;
    offersdlLastSelected.subLanguage = offer.subLanguage?offer.subLanguage.split("|").slice(-1)[0]:"";
    offersdlLastSelected.dubLanguage = offer.dubLanguage?offer.dubLanguage.split("|").slice(-1)[0]:"";
    return offersdlLastSelected
}

function validateParsedValue(value) {
    if (value === null) { return false; }
    if (!Array.isArray(value)) { return false; }
    if (value.length === 0) { return false; }
    return true;
}

const reArrangeSubLanguage = (value, tableMeta) => {
    const offerTableId = tableMeta.currentTableData[tableMeta.rowIndex].data[1];
    const dataSubDubLanguagesLocalStorage = validateParsedValue(JSON.parse(localStorage.getItem('subDubLanguageLastSelected'))) ?
        JSON.parse(localStorage.getItem('subDubLanguageLastSelected')) : []
    const filteredArray = dataSubDubLanguagesLocalStorage.filter(item => item.id === offerTableId.toString());
    const payloadLanguageList = value.split("|")
    const isPresentInArray = filteredArray
        .some(item => payloadLanguageList.includes(item.subLanguage));
    if (isPresentInArray) {
        const subLanguageFilter = filteredArray.map(item => item.subLanguage)
        const indexSubLanguageFilter = payloadLanguageList.indexOf(subLanguageFilter[0]);
        if (indexSubLanguageFilter !== -1) {
            payloadLanguageList.splice(indexSubLanguageFilter, 1);
        }
        payloadLanguageList.push(subLanguageFilter[0]);
    }
    return payloadLanguageList.join("|")
}

const getLastSelectedValue = (value) => {
    let lastElement = ""
    if(isArrayOfPrimitives(value)) {
        lastElement = value[value.length - 1]
    }

    if(isArrayOfObjects(value)) {
        lastElement = value[value.length - 1].label
    }
    return lastElement
}

function isArrayOfObjects(array) {
    if (Array.isArray(array) && array.length > 0) {
        return typeof array[0] === 'object' && array[0] !== null && !Array.isArray(array[0]);
    }
    return false;
}

function isArrayOfPrimitives(array) {
    if (Array.isArray(array) && array.length > 0) {
        return typeof array[0] !== 'object' || array[0] === null;
    }
    return false;
}

const reArrangeDubLanguage = (value, tableMeta) => {
    const offerTableId = tableMeta.currentTableData[tableMeta.rowIndex].data[1];
    const dataSubDubLanguagesLocalStorage = validateParsedValue(JSON.parse(localStorage.getItem('subDubLanguageLastSelected'))) ?
        JSON.parse(localStorage.getItem('subDubLanguageLastSelected')) : []
    const filteredArray = dataSubDubLanguagesLocalStorage.filter(item => item.id === offerTableId.toString());
    const payloadLanguageList = value.split("|")
    const isPresentInArray = filteredArray
        .some(item => payloadLanguageList.includes(item.dubLanguage));
    if (isPresentInArray) {
        const dubLanguageFilter = filteredArray.map(item => item.dubLanguage)
        const indexDubLanguageFilter = payloadLanguageList.indexOf(dubLanguageFilter[0]);
        if (indexDubLanguageFilter !== -1) {
            payloadLanguageList.splice(indexDubLanguageFilter, 1);
        }
        payloadLanguageList.push(dubLanguageFilter[0]);
    }
    return payloadLanguageList.join("|")
}
 const loadDataSubDubLanguagesLastSelected = (res) => {
     let dataDubSubLanguage = sdLanguagesLastSelected(res)
     const localStorageItem = localStorage.getItem('subDubLanguageLastSelected');
     let parsedLocalStorageItem = null;
     if (localStorageItem) {
         try {
             parsedLocalStorageItem = JSON.parse(localStorageItem);
         } catch (error) {
             console.error("Failed to parse JSON from localStorage:", error);
         }
     }
     const dataSubDubLanguagesLocalStorage = validateParsedValue(parsedLocalStorageItem) ? parsedLocalStorageItem : dataDubSubLanguage
     localStorage.setItem('subDubLanguageLastSelected', JSON.stringify(dataSubDubLanguagesLocalStorage))
}

const setLocalStorageSubDubLanguagesLastSelected = (dataSubDubLanguagesLocalStorage, offerTableId, subLanguage, dubLanguage) => {
    if (!Array.isArray(dataSubDubLanguagesLocalStorage)) {
        console.error("dataSubDubLanguagesLocalStorage is not an array or is undefined.");
        return;
    }
    const subDubLanguageSelected = {
        id: offerTableId,
        subLanguage: subLanguage,
        dubLanguage: dubLanguage
    }

    const updatedDataFrontend = []
    const idExists = dataSubDubLanguagesLocalStorage.some(item => parseInt(item.id) === parseInt(subDubLanguageSelected.id));
    if (!idExists) {
        updatedDataFrontend.push(subDubLanguageSelected);
    }
    localStorage.setItem('subDubLanguageLastSelected', JSON.stringify(updatedDataFrontend))
}

export default OfferTable;