import React from 'react';
import Typography from '@dtci/spellbook/Typography';
import styles from './ag-table.module.scss';


const item = (data, key) => ({
    value: data[key] ?? '--',
});

const content = (data, dataType) => {

    if (dataType === 'offers')
        return {
            default: [item(data, 'message')],
        };
};

export const DetailsRow = (dataType) => {
    return ({data}) => (
        <div className={styles['details-row']}>
            {content(data, dataType)['default'].map(
                ({value}) => (
                    <div className={styles['row-item']} key={window.crypto.randomUUID()}>
                        <Typography variant="T20">{value}</Typography>
                    </div>
                ),
            )}
        </div>
    );
};
