import axios from 'axios';

/**
 * @returns {import('axios').AxiosInstance}
 */
export const apiClient = () =>
    axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            Authorization: localStorage.getItem('tap-token'),
            'Content-Type': 'application/json',
        },
    });

async function offers(id) {
    try {
        const { data } = await apiClient().get(`/licensing/${id}/offerTable?filter=%7B%7D&range=%5B-10000%2C-1%5D&sort=%5B%22id%22%2C%22DESC%22%5D`);
        return data;

    } catch (error) {
        console.error(error);
        return undefined;
    }
}


export const tapApi = {
    offers,
};
